import React, { Component } from "react";
import { Container } from "reactstrap";
import { requestDomain } from "../../services/SeekerServices";

import { ThemeContext } from "../../../employer/context/ThemeContext";

import BG1 from "../../../../assets/img/non-upward-logos/bg_2.png";

class FindBetter extends Component{


    render(){

        return(
            <section className={"upward_find_better "+ (this.context ? "upward_find_better_" + this.context : "" ) } style={window.location.host.indexOf("jobtorch") > -1 ? {"background" : "none"} : {"background" : `url(${BG1}) no-repeat center center fixed`,"backgroundSize": "cover"}}>
                <Container style={{"height":"984px","marginTop":"10%"}}>
                    <div className="clearfix text-center">
                        <h3 style={{"fontSize":"3.5em","color": "#0076a3"}}>Millions of career <br /> options at your fingertips.</h3>
                        <h3 style={{"fontSize":"24px","color": "#666666","paddingTop":"10px"}}>Gain access to over 1,300,000 jobs from thousands of job<br/>boards, newspapers, and company career pages.</h3>
                        <a href={requestDomain + `/results?reg=y`} className="btn btn-sm mt-3" style={{"borderRadius": "6px","fontSize":"18px","backgroundColor": "#ff9800","borderColor": "#ff9800","padding": "0 60px","lineHeight": "2.4","textShadow": "none !important","color":"white"}}><b>Join</b></a>
                    </div>
                </Container>
            </section>
        )
    }

}

FindBetter.contextType = ThemeContext;

export default FindBetter;