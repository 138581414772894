/*
   This file is for APIs that are used to autofill other form fields
 */

import {from, of} from "rxjs";
import axios from "axios";
import {catchError, map} from "rxjs/operators";
import { toTitleCase } from "ui/formatter/stringFormatter";


/*
      IF zip is not complete, the api returns {found: false}
 */

export const getCityStateFromZip = (zip) => (from(
      axios.get(global.API_URL + `/includes/zipSearch.jsp?zip=${zip}`)
   ).pipe(
      map((res) => {
            const {status, data} = res;
            if (status !== 204 && data) {
               return {
                  ...data,
                  city: data.city ? toTitleCase(data.city) : ""
               };
            } else {
               return {found: false};
            }
         }
      ), catchError((err) => {
         console.error(err);
         return of({found: false});
      }))
);