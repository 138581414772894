/*

    File that contains list of all verticals and 
    Links to their pages.

|  1 | Accounting & Finance               |
|  2 | Administrative & Office            |
|  3 | Advertising & Marketing            |
|  4 | Animal Care                        |
|  5 | Art, Fashion & Design              |
|  6 | Business Operations                |
|  7 | Cleaning & Facilities              |
|  8 | Computer & IT                      |
|  9 | Construction                       |
| 10 | Customer Service                   |
| 11 | Education                          |
| 12 | Energy, Mining & Natural Resources |
| 13 | Entertainment & Travel             |
| 14 | Farming & Outdoor                  |
| 15 | Government                         |
| 16 | Healthcare                         |
| 17 | Hospitality                        |
| 18 | Human Resources                    |
| 19 | Installation, Maintenance & Repair |
| 20 | Legal                              |
| 21 | Manufacturing & Production         |
| 22 | Media, Communications & Writing    |
| 23 | Operators                          |
| 24 | Personal Care & Services           |
| 25 | Protective Services & Security     |
| 26 | Real Estate                        |
| 27 | Restaurant & Food Services         |
| 28 | Retail                             |
| 29 | Sales                              |
| 30 | Science                            |
| 31 | Social Services & Nonprofit        |
| 32 | Sports, Fitness & Recreation       |
| 33 | Transportation & Logistics         |
| 34 | Warehouse                          |

*/
import accountingThumb from "assets/img/verticals/accounting/thumb.jpg";
// import constructionThumb from "assets/img/verticals/construction/thumb.jpg";
import customerSupportThumb from "assets/img/verticals/customer_support/thumb.jpg";
// import gigThumb from "assets/img/verticals/gig_economy/thumb.jpg";
import healthcareThumb from "assets/img/verticals/healthcare/thumb.jpg";
import hospitalityThumb from "assets/img/verticals/hospitality/thumb.jpg";
import manufacturingThumb from "assets/img/verticals/manufacturing/thumb.jpg";
import restaurantThumb from "assets/img/verticals/restaurant/thumb.jpg";
import retailThumb from "assets/img/verticals/retail/thumb.jpg";
import salesThumb from "assets/img/verticals/sales_marketing/thumb.jpg";
import securityThumb from "assets/img/verticals/security/thumb.jpg";
import transportationThumb from "assets/img/verticals/transportation/thumb.jpg";
import warehouseThumb from "assets/img/verticals/warehouse/thumb.jpg";

const VerticalList = [
    {"title":"Accounting & Finance","link":"/accounting","thumb":accountingThumb },
    // {"title":"Construction","link":"/construction","thumb":constructionThumb },
    {"title":"Customer & Office Support","link":"/customer_support","thumb":customerSupportThumb },
    // {"title":"Gig Economy","link":"/gig","thumb":gigThumb },
    {"title":"Healthcare","link":"/healthcare","thumb":healthcareThumb },
    {"title":"Hospitality","link":"/hospitality","thumb":hospitalityThumb },
    {"title":"Manufacturing","link":"/manufacturing","thumb":manufacturingThumb },
    {"title":"Restaurant & Food Service","link":"/restaurant_food_service","thumb":restaurantThumb },
    {"title":"Retail","link":"/retail","thumb":retailThumb },
    {"title":"Sales & Marketing","link":"/sales_marketing","thumb":salesThumb },
    {"title":"Security","link":"/security","thumb":securityThumb },
    {"title":"Transportation","link":"/transportation","thumb":transportationThumb },
    {"title":"Warehouse","link":"/warehouse","thumb":warehouseThumb },
];


export default VerticalList;