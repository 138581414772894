import {BehaviorSubject} from "rxjs";
import {autoSuggest} from "./customOperators";
import {map} from "rxjs/operators";

/*
   ARGUMENT:
      getSuggestions( <input> )
         <input> is a string

         RETURNS a list of options of type Object
            with **required** properties:
               label
               value


               EX:
                  getSuggestions( ... ) returns the following
                     [
                        {
                           label: "cow",
                           value: "moo",
                        }, {
                           label: "lion",
                           value: "rawr",
                        }
                     ]
 */
export const getSuggestionObservablePair = (getSuggestions) => {
   const suggestionInput$ = new BehaviorSubject("");
   const suggestionObservable$ = suggestionInput$.pipe(autoSuggest(getSuggestions));
   return {
      suggestionInput$,
      suggestionObservable$
   }
};

export const addressInput$ = new BehaviorSubject("");
export const addressObservable$ = addressInput$.pipe(map( (address) => {
   return address;
}));

export const cityInput$ = new BehaviorSubject("");
export const cityObservable$ = cityInput$.pipe(map( (city) => {
   return city;
}));

export const stateInput$ = new BehaviorSubject("");
export const stateObservable$ = stateInput$.pipe(map( (state) => {
   return state;
}));

export const zipInput$ = new BehaviorSubject("");
export const zipObservable$ = zipInput$.pipe(map( (zip) => {
   return zip;
}));


