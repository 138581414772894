import React, {useState, useCallback, useEffect} from "react";
import Autosuggest from 'react-autosuggest';
import {getSuggestionObservablePair} from "ui/util/rxjs/observables";

/*
   PROPS:

      getSuggestions(strInput)                                   required
         ARGUMENT:
            string value

         RETURNS a list of options of type Object
            with **required** properties:
               label
               value


               EX:
                  getSuggestions( ... ) returns the following
                     [
                        {
                           label: "cow",
                           value: "moo",
                        }, {
                           label: "lion",
                           value: "rawr",
                        }
                     ]

      value                                                       required
      handleChange(strInput)                                      required
           Gets called when user typed or click on suggestion
      handleSelect(suggestion)                                    required
           Gets called when user clicks on suggestion
           Where suggestion is an object with required attrs:
            "lio
      placeholder                                                 Optional


      observable = { suggestionInput$, suggestionObservable$ }    Optional
            If undefined, this component generates its own stream

 */

// Use your imagination to render suggestions.
const renderSuggestion = suggestion => (
   <div>
      {suggestion.label}
   </div>
);

const InputAutosuggest = ({
                                 value,
                                 getSuggestions,
                                 handleChange,
                                 handleSelect,
                                 observable,
                                 ...props
                              }) => {

   const [suggestions, setSuggestions] = useState([]);
   const [suggestionInput$, setSuggestionInput$] = useState(null);

   useEffect(() => {
      const {suggestionInput$, suggestionObservable$} = observable
         ? observable : getSuggestionObservablePair(getSuggestions);

      setSuggestionInput$(suggestionInput$);

      const suggestionSubscription = suggestionObservable$.subscribe((newSuggestions) => {
         setSuggestions(newSuggestions);
      });

      return () => {
         suggestionSubscription.unsubscribe();
      }

   }, []);


   // Autosuggest will call this function every time you need to update suggestions.
   // You already implemented this logic above, so just use it.
   const onSuggestionsFetchRequested = useCallback(({value}) => {
      //
      // console.log("onSuggestionsFetchRequested");
      const inputValue = value.trim().toLowerCase();
      // console.log("inputValue=", inputValue);
      const inputLength = inputValue.length;
      // console.log("inputLength=", inputLength);

      if (inputLength === 0) {
         setSuggestions([]);
      } else {
         suggestionInput$.next(inputValue);
      }
   }, [setSuggestions, suggestionInput$]);

   // Autosuggest will call this function every time you need to clear suggestions.
   const onSuggestionsClearRequested = useCallback(() => {
      setSuggestions([]);
   }, [setSuggestions]);

   // When suggestion is clicked, Autosuggest needs to populate the input
   // based on the clicked suggestion. Teach Autosuggest how to calculate the
   // input value for every given suggestion.
   const getSuggestionValue = useCallback((suggestion) => {
      if (handleSelect) {
         handleSelect(suggestion);
      }
      return suggestion.value;
   }, [handleSelect]);

   return (
      <Autosuggest
         suggestions={suggestions}
         onSuggestionsFetchRequested={onSuggestionsFetchRequested}
         onSuggestionsClearRequested={onSuggestionsClearRequested}
         getSuggestionValue={getSuggestionValue}
         renderSuggestion={renderSuggestion}
         inputProps={
            {
               id: props.id || "",
               placeholder: props.placeholder || "Type something...",
               value,
               onChange: handleChange,
               className: `${props.className}`
            }
         }
         {...props}
      />
   );
};

export default InputAutosuggest;