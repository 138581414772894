import { combineEpics } from 'redux-observable';


import { fetchJobSeekerAlertsFromServerEpic, addNewJobSeekerAlertEpic, deleteJobSeekerAlertEpic, updateJobSeekerAlertEpic } from 'corp-site/job-seeker/job-alerts/redux/actions';
import { fetchJobSeekerProfileFromServerEpic, fetchJobSeekerResumeFromServerEpic, fetchSiteValuesFromServerEpic } from 'corp-site/job-seeker/profile/redux/actions';

export const rootEpic = combineEpics(
    fetchJobSeekerAlertsFromServerEpic,
    addNewJobSeekerAlertEpic,
    deleteJobSeekerAlertEpic,
    updateJobSeekerAlertEpic,
    fetchJobSeekerProfileFromServerEpic,
    fetchJobSeekerResumeFromServerEpic,
    fetchSiteValuesFromServerEpic,
);
