/*


  Implementation by Amit Sharma
  Dated : 11/5/2019

  Global Number Formatter for the app

  Outputs in thousand separated commas  6789054.88 -> 6,789,054.88

  Props:
  value (Input value, 0 default)
  decimals (Number of decimals in the output, 0 default)

  Usage: <NumberFormatter value={company.numberApplicants} decimals="2"/>

  returns in format :  x,xxx.xx
*/


import React, { Component, Fragment } from "react";


class NumberFormatter extends Component{
    render(){
        const value = this.props.value || 0;
        const decimals = this.props.decimals || 0;
        let formatted = Number.parseFloat(Math.round((value ? value : 0) * 100) / 100).toFixed(decimals).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return (
            <Fragment>
                {formatted}
            </Fragment>
        )
    }
}


export default NumberFormatter;