import React, { Component } from "react";
import { Container } from "reactstrap";

import { ThemeContext } from "../../../employer/context/ThemeContext";

import BG1 from "../../../../assets/img/non-upward-logos/bg_4.png";

class FindBetter extends Component{


    render(){

        return(
            <section className={"upward_find_better "+ (this.context ? "upward_find_better_" + this.context : "" ) } style={window.location.host.indexOf("jobtorch") > -1 ? {"background" : "none"} : {"background" : `url(${BG1}) no-repeat 100% 100% fixed`}}>
                <Container style={{"height":"984px","marginTop":"15%"}}>
                    <div className="clearfix col-lg-5 col-md-5 offset-lg-2 offset-md-2 col-sm-5">
                        <h3 style={{"fontSize":"3.5em","color": "#0076a3"}}>Reach millions of candidates.</h3>
                        <h3 style={{"fontSize":"24px","color": "#666666","paddingTop":"10px"}}>Our job search engine includes millions of jobs from thousands of sources. When you post jobs with Jobtomic™ you receive prominent placement in relevant search results across the web. That means your jobs get noticed by the right candidates.</h3>
                        <a href="https://www.upward.net/employer/registration/username?plan=basic&src=JT&sjso=yes" className="btn btn-sm mt-3" style={{"borderRadius": "6px","fontSize":"18px","backgroundColor": "#ff9800","borderColor": "#ff9800","padding": "0 60px","lineHeight": "2.4","textShadow": "none !important","color":"white"}}><b>Post a Job</b></a>
                    </div>
                </Container>
            </section>
        )
    }

}

FindBetter.contextType = ThemeContext;

export default FindBetter;