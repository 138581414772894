// all the job seeker actions
export const JOB_SEEKER_FETCH_ALERTS = "[Job Seeker Profile] FETCH_ALERTS";
export const JOB_SEEKER_UPDATE_ALERTS = "[Job Seeker Profile] UPDATE_ALERTS";
export const JOB_SEEKER_FETCH_ALERTS_BEGIN = "[Job Seeker Profile] FETCH_ALERTS_BEGIN";
export const JOB_SEEKER_FETCH_ALERTS_ERROR = "[Job Seeker Profile] FETCH_ALERTS_ERROR";


export const JOB_SEEKER_ADD_NEW_ALERT_BEGIN = "[Job Seeker Profile] JOB_SEEKER_ADD_NEW_ALERT_BEGIN";
export const JOB_SEEKER_ADD_NEW_ALERT = "[Job Seeker Profile] JOB_SEEKER_ADD_NEW_ALERT";
export const JOB_SEEKER_ADD_NEW_ALERT_FAILURE = "[Job Seeker Profile] JOB_SEEKER_ADD_NEW_ALERT_FAILURE";


export const JOB_SEEKER_DELETE_ALERT_BEGIN = "[Job Seeker Profile] JOB_SEEKER_DELETE_ALERT_BEGIN";
export const JOB_SEEKER_DELETE_ALERT = "[Job Seeker Profile] JOB_SEEKER_DELETE_ALERT";
export const JOB_SEEKER_DELETE_ALERT_FAILURE = "[Job Seeker Profile] JOB_SEEKER_DELETE_ALERT_FAILURE";


export const JOB_SEEKER_UPDATE_ALERT_BEGIN = "[Job Seeker Profile] JOB_SEEKER_UPDATE_ALERT_BEGIN";
export const JOB_SEEKER_UPDATE_ALERT = "[Job Seeker Profile] JOB_SEEKER_UPDATE_ALERT";
export const JOB_SEEKER_UPDATE_ALERT_FAILURE = "[Job Seeker Profile] JOB_SEEKER_UPDATE_ALERT_FAILURE";
