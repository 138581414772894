export const JOB_SEEKER_FETCH_RESULTS = "[Job Seeker Results] JOB_SEEKER_FETCH_RESULTS";
export const JOB_SEEKER_FETCH_RESULTS_BEGIN = "[Job Seeker Results] JOB_SEEKER_FETCH_RESULTS_BEGIN";
export const JOB_SEEKER_FETCH_RESULTS_ERROR = "[Job Seeker Results] JOB_SEEKER_FETCH_RESULTS_ERROR";
export const JOB_SEEKER_UPDATE_RESULTS = "[Job Seeker Results] JOB_SEEKER_UPDATE_RESULTS"; // update results list with details from server


export const JOB_SEEKER_UPDATE_RESULTS_SELECTED_JOB = "[Job Seeker Results] JOB_SEEKER_UPDATE_RESULTS_SELECTED_JOB"; // update selected job with details from server


export const JOB_SEEKER_UPDATE_SEARCH_PARAMS = "[Job Seeker Results] JOB_SEEKER_UPDATE_SEARCH_PARAMS";
export const JOB_SEEKER_FETCH_RESULTS_UPDATE_START = "[Job Seeker Results] JOB_SEEKER_FETCH_RESULTS_UPDATE_START";


export const JOB_SEEKER_UPDATE_IP_LOCATION = "[Job Seeker Results] JOB_SEEKER_UPDATE_IP_LOCATION";