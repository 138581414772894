/*
   RETURNS
      {
         region,
         zip,
         area_code,
         radius,
         country_code,
         city,
         country
      }

      EX:
      {
         region: "CA",
         zip: "94536",
         area_code: "",
         radius: "1000",
         country_code: "US",
         city: "Fremont",
         country: "United States",
      }
 */

import {from} from "rxjs";
import axios from "axios";
import {first, map} from "rxjs/operators";
import '../constants';

export const getCurrentLocation = () => (from(
      axios.get(global.API_URL + `/search/getLocation.fsn?`)
   ).pipe(
      first(),
      map((res) => {
            const {status, data} = res;
            if (status !== 204 && data && data.length > 13) {
               let morphedData = data.trim();
               morphedData = morphedData.slice(0, morphedData.length - 1).slice(13);
               return JSON.parse(morphedData);
            } else {
               return {};
            }
         }
      ))
);