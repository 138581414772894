import React, { Component } from "react";
import { Container } from "reactstrap";

import { ThemeContext } from "../../../employer/context/ThemeContext";

class FindBetter extends Component{


    render(){

        return(
            <section className={"upward_find_better "+ (this.context ? "upward_find_better_" + this.context : "" ) } >
                <Container>
                    <div className="clearfix text-center">
                        <h3>Find a better job. <br /> We will show you how.</h3>
                    </div>
                </Container>
            </section>
        )
    }

}

FindBetter.contextType = ThemeContext;

export default FindBetter;