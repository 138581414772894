import axios from "axios";
import queryString from "query-string";
import { of, from } from "rxjs";
import { first, map, catchError } from "rxjs/operators";
import '../../../../constants';

const BASE_URL = "/search/";

export const getCandidateCount = ( catIds )=>{
    return from( 
        axios.get(global.API_URL + BASE_URL + "getDisplayApp.fsn?type=count&" + queryString.stringify( {"catid" : catIds}))
    ).pipe(
        first(),
        map((response) => {
            if (response && response.data) {
                return response.data
            } else {
                return { "applicantCount" : 0 }
            }
        }),
        catchError((err) => {
            console.log(err);
            return of({ "applicantCount" : 0 })
        })
    )
}

export const getRecentCandidates = ( catIds, keywords, count ) => {
    return from( 
        axios.get(global.API_URL + BASE_URL + "getDisplayApp.fsn?type=app&size="+( count ? count : 60 )+"&" + queryString.stringify( {"catid" : catIds, "keyword": keywords }))
    ).pipe(
        first(),
        map((response) => {
            if (response && response.data) {
                return { candidates : [...response.data.displayApps.reverse()] }
            } else {
                return { "candidates" : [] }
            }
        }),
        catchError((err) => {
            console.log(err);
            return of({ "candidates" : [] })
        })
    )
}

export const getJobCount = (catIds) => {
    return from(
        axios.get(global.API_URL + BASE_URL + "getDisplayJob.fsn?type=count&" + queryString.stringify({ "catid": catIds }))
    ).pipe(
        first(),
        map((response) => {
            if (response && response.data) {
                return response.data
            } else {
                return { "recentJobCount": 0 }
            }
        }),
        catchError((err) => {
            console.log(err);
            return of({ "recentJobCount": 0 })
        })
    )
}

export const getRecentJobs = (catIds, count) => {
    return from(
        axios.get(global.API_URL + BASE_URL + "getDisplayJob.fsn?type=job&size="+( count ? count : 10)+"&" + queryString.stringify({ "catid": catIds }))
    ).pipe(
        first(),
        map((response) => {
            if (response && response.data) {
                return { "jobs": [...response.data.displayJobs.reverse()] }
            } else {
                return { "jobs": [] }
            }
        }),
        catchError((err) => {
            console.log(err);
            return of({ "jobs": [] })
        })
    )
}