/*


  Implementation by Bryan Perez
  Dated : 9/23/2019

  Global Currency Formatter for the app


  returns in format : $ x.xx
*/


import React, { Component, Fragment } from "react";


class CurrencyFormatter extends Component{
    render(){
        const value = this.props.value;
        let formatted = (value ? value : 0).toFixed(2);

        return (
            <Fragment>
                ${formatted}
            </Fragment>
        )
    }
}


export default CurrencyFormatter;