/*


  Implementation by Taran Sahota
  Dated : 9/19/2019



    Implementation to use Redux form with checkboxes.


*/
import React from "react";
import {
    CustomInput
} from "reactstrap";

const UpwardReduxCheckbox = ({isChecked, input, ...custom })=> {
    if( isChecked ){
        delete input.checked;
    }
    return (
        <CustomInput {...(isChecked ? { defaultChecked : true } : {} )} {...input} {...custom} />
    )
}

export default UpwardReduxCheckbox;