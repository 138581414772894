import React from "react";
import { ModalHeader, ModalBody } from 'reactstrap';
import { requestDomain } from "../homepage/services/SeekerServices";

const TC = (props) => {
  return (
    <React.Fragment>
      <ModalHeader style={{ paddingBottom: 0 }}>
        <h3 className="center">Upward.Net Terms of Use</h3>
      </ModalHeader>
      <ModalBody>
        <React.Fragment>
          <div className="clearfix mainSection">
            <div className="container">
              <div className="clearfix contentWrapper">
                <div className="mainContent" id="mainContentHolder">
                  <div className="row">
                    <div className="col-md-12 col-lg-12">
                      <p align="center">
                        <strong></strong>
                      </p>
                      <p id="section-1">
                        <strong>1. Introduction</strong>
                      </p>
                      <p>
                        Welcome to the websites and online services of
                        Upward.net LLC ("Upward.net," "we," or "us"). These
                        Terms of Use (the "Terms") explain the terms and
                        conditions by which you may use our online services,
                        mobile services, websites, and Upward.net related sites
                        and applications (collectively the "Service"). You can
                        click the links below to navigate to specific sections
                        of these Terms, but we recommend that you read these
                        Terms completely before using the Service.
                      </p>
                      <p>
                        <strong>
                          PLEASE REVIEW SECTION 16, TITLED THE “MANDATORY
                          ARBITRATION AND CLASS ACTION WAIVER” CAREFULLY. UNLESS
                          YOU OPT OUT OF ARBITRATION AS PROVIDED BELOW, THESE
                          TERMS WILL REQUIRE YOU TO RESOLVE DISPUTES WITH
                          UPWARD.NET, NO MATTER WHEN ARISING OR ASSERTED,
                          THROUGH BINDING INDIVIDUAL ARBITRATION (WITH LIMITED
                          EXCEPTIONS). YOU ACKNOWLEDGE AND AGREE THAT YOU WAIVE
                          THE RIGHT TO A TRIAL BY JURY AND THE RIGHT TO
                          PARTICIPATE AS A PLAINTIFF OR CLASS MEMBER IN ANY
                          PURPORTED CLASS ACTION OR REPRESENTATIVE PROCEEDING.
                        </strong>
                      </p>
                      <ol>
                        <li>
                          <a href="#section-1">Introduction</a>
                        </li>
                        <li>
                          <a href="#section-2">Changes to these Terms</a>
                        </li>
                        <li>
                          <a href="#section-3">General Service Information</a>
                        </li>
                        <li>
                          <a href="#section-4">User Accounts</a>
                        </li>
                        <li>
                          <a href="#section-5">Prohibited Conduct</a>
                        </li>
                        <li>
                          <a href="#section-6">
                            User Content and Feedback; License to User Content
                            and Feedback
                          </a>
                        </li>
                        <li>
                          <a href="#section-7">
                            End User License Grant and Upward.Net Intellectual
                            Property Rights
                          </a>
                        </li>
                        <li>
                          <a href="#section-8">Features</a>
                        </li>
                        <li>
                          <a href="#section-9">Security</a>
                        </li>
                        <li>
                          <a href="#section-10">
                            Copyright Infringement Claims (DMCA Notice)
                          </a>
                        </li>
                        <li>
                          <a href="#section-11">
                            Third-Party Links and Services
                          </a>
                        </li>
                        <li>
                          <a href="#section-12">
                            Representations, Warranties, and Covenants
                          </a>
                        </li>
                        <li>
                          <a href="#section-13">Indemnity</a>
                        </li>
                        <li>
                          <a href="#section-14">Disclaimer of Warranty</a>
                        </li>
                        <li>
                          <a href="#section-15">
                            Consequential Damages Waiver; Limitation of
                            Liability.{" "}
                          </a>
                        </li>
                        <li>
                          <a href="#section-16">
                            MANDATORY ARBITRATION AND CLASS ACTION WAIVER
                          </a>
                        </li>
                        <li>
                          <a href="#section-17">Term and Termination</a>
                        </li>
                        <li>
                          <a href="#section-18">General Provisions</a>
                        </li>
                        <li>
                          <a href="#section-19">Notice to California Users</a>
                        </li>
                        <li>
                          <a href="#section-20">Contact Us</a>
                        </li>
                      </ol>
                      <p>
                        If you are accessing the websites or using the Service
                        on behalf of an organization that you represent, then
                        you agree to bind both yourself as an individual, and
                        your organization, to all of the Terms, and all
                        references to “you” shall also include such
                        organization.
                      </p>
                      <p>
                        No agency, partnership, joint venture, employment, or
                        franchise relationship is intended or created by these
                        Terms.
                      </p>
                      <p>
                        These Terms include the Upward.net{" "}
                        <a href="https://www.upward.careers/?privacy-policy">
                          Privacy Policy
                        </a>
                        , which is incorporated by reference herein. By
                        accepting these Terms, you acknowledge that you have
                        read and understand the Privacy Policy.
                      </p>
                      <p>
                        Upward.net is powered by Jobcase.com - an online
                        community where workers come together to help each other
                        get ahead.
                        <strong>
                          When you register with Upward.net, you are
                          automatically registered with Jobcase and are also
                          bound by the Jobcase{" "}
                        </strong>
                        <a href="https://www.jobcase.com/terms-of-use">
                          <strong>terms of use</strong>
                        </a>
                        <strong> and subject to the Jobcase </strong>
                        <a href="https://www.jobcase.com/privacy-policy">
                          <strong>privacy policy</strong>
                        </a>
                        <strong>. </strong>
                        After you register with Upward.net, click{" "}
                        <a href="https://www.jobcase.com/">here</a> to access
                        full functionality from your Jobcase account.
                      </p>
                      <p>
                        The details below describe your rights and
                        responsibilities when you use the Service. By accessing
                        or using our websites and the Service in any way, you
                        are agreeing to be bound by these Terms.
                      </p>
                      <p id="section-2">
                        <strong>2. Changes to these Terms</strong>
                      </p>
                      <p>
                        We may revise these Terms at any time by posting a
                        revised version on our website or otherwise by providing
                        notice as provided herein or in accordance with
                        applicable law. The revised Terms will be effective when
                        posted on our websites unless otherwise specified. Your
                        continued use of the Service after any such change
                        constitutes your acceptance of the new Terms. If you do
                        not agree to any of these terms or any future Terms or
                        our Privacy Policy, you must not use (or continue to
                        use) or access the Service.
                      </p>
                      <p id="section-3">
                        <strong>3. General Service Information</strong>
                      </p>
                      <p>
                        <u>Minimum Age</u>. You agree that you must be 16 years
                        or older to use the Service, or the minimum age set by
                        applicable law (if older). For users who are under the
                        age of 18 but over the age of 16, the parent or guardian
                        of the user declares that the parent/guardian has read
                        and understood our{" "}
                        <a href={`${requestDomain}/?privacy-policy`}>
                          Privacy Policy
                        </a>
                        , agrees to these Terms on behalf of the user, and
                        agrees to the use of the Service by the user.
                      </p>
                      <p>
                        <u>Compliance</u>. Any use of the Service must comply
                        with the Terms and all applicable laws, rules and
                        regulations. Users who violate these Terms may have
                        their access and use of the Service suspended or
                        terminated in our sole discretion.
                      </p>
                      <p>
                        <u>Email Communications</u>. By providing us with your
                        email address, you consent to our using that email
                        address to send you Service-related notices, including
                        any notices required by law, in lieu of communication by
                        postal mail. We may also use your email address to send
                        you other messages, such as changes to features of the
                        Service and special offers; however, if you do not want
                        to receive email messages, you can opt out or change
                        your email preferences on your account page or by
                        following the opt-out instructions in the email you
                        received at any time. By submitting a job application
                        via the Service, you give us permission to store your
                        information and to share your information with the
                        applicable Employer.
                      </p>
                      <p>
                        <u>Phone and SMS Communications</u>. You can manually
                        sign up to receive job alerts by email, browser
                        notification, and/or SMS. In addition to a manual sign
                        up, if you apply to job advertisements on an Upward.net
                        website and/or create and account, you also consent to
                        receiving email and/or SMS job alerts based on the type
                        of role you selected. You can sign up for job alerts via
                        SMS in several ways, including by signing up <u>here</u>{" "}
                        or entering your cellphone number in the Alerts section
                        of your user account. Please note, these communications
                        may include those made to a wireless number using an
                        automatic telephone dialing system or an artificial or
                        prerecorded voice, from the Upward.net related sites,
                        and our affiliates and partners. You can edit or change
                        the type of job alerts you want to receive by accessing
                        the Job Alerts tab in your user account. You may opt out
                        of job alerts by clicking on the unsubscribe link in
                        your job alert email or by following the instructions in
                        your SMS alert. You can also contact our Customer
                        Service team at <u>support@Upward.net</u>, if you need
                        assistance. By signing up for SMS alerts you agree to
                        receive SMS notifications from our participating
                        carriers. You may receive up to 3 messages per day
                        (message frequency may vary per user). Reply STOP to
                        cancel, HELP for help to our code 45370. Message and
                        data rates may apply. Upward.net is not liable for
                        delayed or undelivered messages.
                      </p>
                      <p>
                        <u>Acknowledgement</u>. You acknowledge that Upward.net
                        does not have control over the quality, timing, or
                        legality of the information uploaded to the Service by
                        Employers. In addition, you acknowledge that we do not
                        have control over the integrity, responsibility or
                        actions of Employers. We do not refer or recommend
                        candidates or Employers, nor do we make any
                        representations about the integrity, responsibility or
                        actions of candidates or Employers whether in public,
                        private or offline interactions.
                      </p>
                      <p id="section-4">
                        <strong>4. </strong>
                        <strong>User Accounts</strong>
                      </p>
                      <p>
                        <u>Account Creation</u>. You may create an account and
                        control your account information and how you interact
                        with the Service by changing the settings in your
                        account. When creating your account or uploading
                        information to the Service through your account, you
                        represent and warrant that you will provide accurate and
                        complete information. If you create an account, you must
                        be permitted to legally work within your applicable
                        jurisdiction and may use your account only for yourself.
                        In addition to applying for a job posting, creating an
                        account allows you to browse the Service, receive job
                        alerts from the Service regarding jobs that may be of
                        interest to you ("Job Alerts"), save a record of job
                        listings you have found on the Service and save a record
                        of job listings you have applied to one the Service.
                      </p>
                      <p>
                        <u>User Responsibility</u>. You are solely responsible
                        for the activity that occurs on your account and must
                        keep your account password secure. Please use a "strong"
                        password (consisting of a combination of upper and lower
                        case letters, numbers and symbols) with your account and
                        notify us immediately of any breach of security or
                        suspected unauthorized use of your account. We will not
                        be liable for any loss or damage arising from your
                        failure to comply with this Section or any unauthorized
                        activities that occur under your password or account.
                      </p>
                      <p id="section-5">
                        <strong>5. </strong>
                        <strong>Prohibited Conduct</strong>
                      </p>
                      <p>
                        In using the Service, you agree not to: (i) copy,
                        distribute, or disclose any part of the Service in any
                        medium, including without limitation by any automated or
                        non-automated "scraping"; (ii) use any automated system,
                        including without limitation "robots," "spiders,"
                        "offline readers," etc., to access the Service in a
                        manner that sends more request messages to the our
                        servers than a human can reasonably produce in the same
                        period of time by using a conventional on-line web;
                        (iii) transmit spam, chain letters, or other unsolicited
                        email; (iv) attempt to interfere with, compromise the
                        system integrity or security or decipher any
                        transmissions to or from the servers running the
                        Service; (v) take any action that imposes, or may impose
                        an unreasonable or disproportionately large load on our
                        infrastructure; (vi) upload invalid data, viruses,
                        worms, or other software agents through the Service;
                        (vii) collect or harvest any personally identifiable
                        information from the Service, except as expressly
                        permitted by the features of the Service; (viii) use the
                        Service for any commercial solicitation purposes; (ix)
                        impersonate another person or otherwise misrepresent
                        your affiliation with a person or entity, conducting
                        fraud, hiding or attempting to hide your identity; (x)
                        interfere with the proper working of the Service; (xi)
                        access any content on the Service through any technology
                        or means other than those provided or authorized by the
                        Service; or (xii) bypass the measures we may use to
                        prevent or restrict access to the Service, including
                        without limitation features that prevent or restrict use
                        or copying of any content or enforce limitations on use
                        of the Service or the content therein.
                      </p>
                      <p>
                        We may, without prior notice, change the Service or stop
                        providing the Service or features of the Service, to you
                        or to users generally. We may permanently or temporarily
                        terminate or suspend your access to the Service without
                        notice and liability for any reason, including if in our
                        sole determination you violate any provision of the
                        Terms. Upon termination for any reason or no reason, you
                        continue to be bound by these Terms.
                      </p>
                      <p id="section-6">
                        <strong>6. </strong>
                        <strong>
                          User Content and Feedback; License to User Content and
                          Feedback
                        </strong>
                      </p>
                      <p>
                        <u>User Content and Feedback</u>. User Content and User
                        Feedback include (i) any content in whole or in part,
                        and in any form, media or technology, whether now known
                        or hereafter developed that you input or upload (“User
                        Content”) to the Service, and (ii) any suggestions,
                        comments for enhancement or functionality, or other
                        feedback you provide regarding the Service (“User
                        Feedback”).
                      </p>
                      <p>
                        <u>License to User Content</u>. You expressly grant, and
                        represent and warrant that you have a right to grant, to
                        Upward.net a royalty-free, sub-licensable, transferable,
                        irrevocable, non-exclusive, worldwide license to use,
                        reproduce, display, and make derivative works of any
                        User Content. The rights you grant in this license are
                        for the limited purpose of operating, promoting, and
                        improving our Service, and to develop new features. The
                        foregoing license, and our ability to use, transfer or
                        share any personal information contained within any User
                        Content, shall be subject to our{" "}
                        <a href={`${requestDomain}/?privacy-policy`}>
                          Privacy Policy
                        </a>
                        . This license continues even if you stop using our
                        Service, but some portions of our Service may offer you
                        ways to access and remove content that has been provided
                        to that portion of the Service.
                      </p>
                      <p>
                        <u>License to User Feedback</u>. You expressly grant,
                        and represent and warrant that you have a right to
                        grant, to Upward.net a royalty-free, sub-licensable,
                        transferable, irrevocable, non-exclusive, worldwide
                        license to use, reproduce, display, and make derivative
                        works of any User Feedback to (a) copy, distribute,
                        transmit, display, perform, and modify and create
                        derivative works of the User Feedback in whole or in
                        part; and (b) use the User Feedback in whole or in part,
                        including without limitation, the right to make, have
                        made, sell, have sold, offer for sale, have offered for
                        sale, import, have imported and/or otherwise
                        commercialize products or services that incorporate or
                        are configured to use the User Feedback, in whole or in
                        part. We have full discretion to determine whether and
                        how to use or share your User Feedback.
                      </p>
                      <p id="section-7">
                        <strong>7. </strong>
                        <strong>
                          End User License Grant and Upward.Net Intellectual
                          Property Rights
                        </strong>
                      </p>
                      <p>
                        <u>End User License Grant</u>. Subject to the Terms, you
                        are hereby granted a non-exclusive, limited,
                        non-transferable, non-sub-licensable, freely revocable,
                        license to use the Service. We reserve all rights not
                        expressly granted herein to the Service and Upward.net
                        Content, as defined below. We may terminate this license
                        at any time.
                      </p>
                      <p>
                        <u>Upward.Net Intellectual Property Rights</u>. Except
                        for your User Content and User Feedback, the Service and
                        all materials therein or transferred thereby, including,
                        without limitation, software, images, text, graphics,
                        illustrations, logos, patents, trademarks, service
                        marks, copyrights, photographs, audio, videos, and music
                        (the "Upward.net Content"), and all intellectual
                        property rights related thereto, are the exclusive
                        property of Upward.net, its affiliates, and its
                        licensors. Except as explicitly provided herein, nothing
                        in these Terms shall be deemed to create a license in or
                        under any such intellectual property rights, and you
                        agree not to sell, license, rent, modify, distribute,
                        copy, reproduce, transmit, publicly display, publicly
                        perform, publish, adapt, edit or create derivative works
                        from any materials or content accessible on the Service.
                        Use of the Upward.net Content or materials on the
                        Service for any purpose not expressly permitted by the
                        Terms and are strictly prohibited.
                      </p>
                      <p id="section-8">
                        <strong>8. </strong>
                        <strong>Features</strong>
                      </p>
                      <p>
                        <u>Job Applications</u>. As part of the Service, you are
                        able to submit job applications for jobs listings on the
                        Service. You have the ability to choose how to submit
                        your job application via the Service using the different
                        options offered. For example, you may submit your job
                        application through the Service by (A) using the
                        Service's "Apply Now" feature or (B) using the Service's
                        "Instapply" feature. If you use the "Apply Now" feature,
                        you will be required to provide your name, email address
                        and phone number, and attach your resume (and, when you
                        click the "Apply Now" button, this information will then
                        be sent to the Employer that posted the applicable job
                        listing), or the "Apply Now" button will redirect you to
                        an external website where you will be able to apply to
                        the job listing. If you use the "Instapply" feature, the
                        information that you have included as part of your user
                        profile, including your resume and contact information,
                        will be sent directly to the Employer that posted the
                        applicable job listing.
                      </p>
                      <p>
                        Please note that once you have submitted an application
                        through any feature of the Service, your resume and
                        other applicable information will be shared with the
                        applicable Employer. You should therefore ensure that
                        the information in your user profile is complete and
                        accurate, and contains information you are comfortable
                        sharing with Employers, prior to submitting a job
                        application through the Service.
                      </p>
                      <p>
                        If you upload a resume, you agree that we may convert
                        the resume into searchable text for an Employer.
                      </p>
                      <p>
                        <u>Resume Database</u>. You also have the opportunity to
                        include your resume as part of our resume database (the
                        "Resume Database") at the time you register to use the
                        Service. If you do not include your resume in the Resume
                        Database at the time you sign up with the Service, as a
                        registered user you may later include your resume
                        through your profile page. Submitting your resume
                        enables registered Employers to access and view your
                        resume and the contact information you provide on your
                        resume, allowing Employers to contact you directly.
                        Please provide minimal personally identifiable
                        information about yourself.
                      </p>
                      <p>
                        You can opt-out of including your resume in the Resume
                        Database at any time by adjusting the settings in your
                        account and we will remove your resume from the Resume
                        Database in a timely manner. You may also elect not to
                        have specific Employers be able to view you resume;
                        however, you understand and agree if an Employer has
                        accessed your resume through the Resume Database and
                        copied or saved your resume prior to your opt-out
                        request, that Employer will continue to have access to
                        your resume and all information you included in your
                        resume.
                      </p>
                      <p>
                        <u>Interactions with Third Parties</u>. Use caution with
                        regard to the information you share as part of your user
                        profile, a job application or on a resume you make
                        available through the Service.
                        <strong>
                          Only list contact information on your resume or job
                          applications that you are comfortable disclosing.
                        </strong>
                        You are solely responsible for your interactions with
                        Employers that you contact, or that contact you, as a
                        result of your use of the Service. There are risks,
                        including the risk of physical harm that may arise when
                        dealing with strangers, including persons who may be
                        acting under false pretenses. Although we request
                        Employers maintain the confidentiality of the job
                        applications and resumes, we do not guarantee that the
                        information you provide to Employers through the Service
                        will be held in confidence or properly secured by each
                        applicable Employer.
                      </p>
                      <p>
                        Upward.net has no responsibility and assumes no
                        liability for any job listing posted or sent through the
                        Service. You agree that Upward.net is not responsible or
                        liable for the conduct of any Employer or other third
                        party service which you may link to from the Service.
                        You understand that Employers and other third parties
                        are not employees or agents of Upward.net, nor are we an
                        agent of theirs. The terms of your relationship with any
                        Employer or other third party service, including terms,
                        conditions, or representations associated with such
                        dealings, are solely between you and such third party.
                        Should you have a dispute with any such party, you must
                        address the dispute directly with them.
                      </p>
                      <p id="section-9">
                        <strong>9. </strong>
                        <strong>Security</strong>
                      </p>
                      <p>
                        Although the integrity and security of your personal
                        information is important to us, we cannot guarantee that
                        unauthorized third parties will never be able to defeat
                        our security measures or use your personal information
                        for improper purposes. You acknowledge and agree that by
                        providing your personal information you are doing so at
                        your own risk.
                      </p>
                      <p id="section-10">
                        <strong>10. </strong>
                        <strong>
                          Copyright Infringement Claims (DMCA Notice)
                        </strong>
                      </p>
                      <p>
                        Upward.net respects the intellectual property rights of
                        others and it is our policy to respond to alleged
                        infringement notices that comply with the Digital
                        Millennium Copyright Act of 1998 ("DMCA"). If you
                        believe something on this website has infringed your
                        intellectual property rights, please provide the
                        following information in writing:
                      </p>
                      <ol start="1" type="1">
                        <li>
                          An electronic or physical signature of the owner (or a
                          person authorized to act on behalf of the owner) of
                          the copyrighted works;
                        </li>
                        <li>
                          Identification of the work that you claim has been
                          infringed. If the work is registered, please provide a
                          copy of the copyright registration;
                        </li>
                        <li>
                          Identification of the material that is claimed to be
                          infringing and a description of where the infringing
                          material is located. Please provide a link (where
                          available) to where it is located on the Service;
                        </li>
                        <li>
                          Information reasonably sufficient allow us to contact
                          you, such as your address, telephone number, and
                          e-mail address;
                        </li>
                        <li>
                          A statement that you have a good faith belief that use
                          of the material in the manner complained of is not
                          authorized by the copyright owner, its agent, or law;
                          and
                        </li>
                        <li>
                          A statement, made under penalty of perjury, that the
                          above information is accurate, and that you are the
                          owner or are authorized to act on behalf of the owner.
                        </li>
                      </ol>
                      <p>
                        The above information must be submitted to the DMCA
                        Agent at the following:
                      </p>
                      <p>
                        <strong>Email</strong>:{" "}
                        <a href="mailto:dataprivacy@Upward.net">
                          dataprivacy@Upward.net
                        </a>
                      </p>
                      <p>
                        <strong>Phone</strong>: 1.866.422.2048
                      </p>
                      <p>
                        <strong>Mailing Address:</strong>
                      </p>
                      <p>Upward.net</p>
                      <p>
                        ATTN: Data Privacy
                        <br />
                        8000 Jarvis Ave
                      </p>
                      <p>Newark, CA 94560</p>
                      <p>
                        <strong>Counter-Notice:</strong>
                      </p>
                      <p>
                        If you believe that a notice of copyright infringement
                        has been improperly submitted against you, you may
                        submit a Counter-Notice, pursuant to Sections 512(g)(2)
                        and (3) of the Digital Millennium Copyright Act. You may
                        complete the Counter-Notice Regarding Claim of Copyright
                        Infringement form, or otherwise provide a written
                        communication which contains:
                      </p>
                      <p>&#9679; your physical or electronic signature;</p>
                      <p>
                        &#9679; identification of the material removed or to
                        which access has been disabled;
                      </p>
                      <p>
                        &#9679; a statement under penalty of perjury that you
                        have a good faith belief that the removal or disablement
                        of the material was a mistake or that the material was
                        misidentified;
                      </p>
                      <p>
                        &#9679; your full name, email address, and telephone
                        number, and a statement that you consent to the
                        jurisdiction of the Federal district court (i) in the
                        judicial district where your address is located if the
                        address is in the United States, or (ii) located in the
                        Northern District of California, if your address is
                        located outside the United States, and that you will
                        accept service of process from the complainant
                        submitting the notice or their authorized agent.
                      </p>
                      <p>
                        Please submit your Counter-Notice to our Copyright Agent
                        via email to{" "}
                        <a href="mailto:dataprivacy@upward.net">
                          dataprivacy@upward.net
                        </a>{" "}
                        or mail to the address specified above.
                      </p>
                      <p>
                        If a counter-notice is received by the Copyright Agent,
                        we will send a copy of the counter-notice to the
                        original complaining party informing that person that
                        they may replace the removed content or cease disabling
                        it in 10 business days. Unless the copyright owner files
                        an action seeking a court order against the content
                        provider, Member or user, the removed content may be
                        replaced, or access to it restored, in 10 to 14 business
                        days or more after receipt of the counter-notice, at our
                        sole discretion.
                      </p>
                      <p>
                        UNDER FEDERAL LAW, IF YOU KNOWINGLY MISREPRESENT THAT
                        ONLINE MATERIAL IS INFRINGING, YOU MAY BE SUBJECT TO
                        CRIMINAL PROSECUTION FOR PERJURY AND CIVIL PENALTIES,
                        INCLUDING MONETARY DAMAGES, COURT COSTS, AND ATTORNEYS'
                        FEES.
                      </p>
                      <p>
                        <u>Repeat Infringer Policy</u>. In accordance with the
                        DMCA and other applicable law, we have a policy of
                        terminating, if appropriate and at our sole discretion,
                        users, third party posters, and employers who are deemed
                        to be repeat infringers. We may also at our sole
                        discretion limit access to the Service or terminate any
                        users who infringe any intellectual property rights of
                        others, whether or not there is any repeat infringement.
                      </p>
                      <p id="section-11">
                        <strong>11. </strong>
                        <strong>Third-Party Links and Services</strong>
                      </p>
                      <p>
                        The Service contains links to third-party websites,
                        advertisers, services, or other events or activities
                        that are not owned, operated or controlled by
                        Upward.net. We do not endorse or assume any
                        responsibility for any such third-party sites,
                        information, materials, products, or services. If you
                        access a third party website from the Service, you do so
                        at your own risk, and you understand that these Terms
                        and our Privacy Policy do not apply to your use of such
                        sites. You explicitly release Upward.net from any and
                        all liability arising from your use of any third-party
                        website, service, or content. Your dealings with or
                        participation in promotions of advertisers found on the
                        Service, including payment and delivery of goods, and
                        any other terms (such as warranties) are solely between
                        you and such advertisers. We will not be responsible for
                        any loss or damage of any sort relating to your dealings
                        with such advertisers.
                      </p>
                      <p>
                        We may also use adware, pixels, web beacons and other
                        tracking technologies on the Service that contain links
                        to other third-party websites or applications. Our use
                        of these technologies is described in our &nbsp;
                        <a href={`${requestDomain}/results/?pageNum=1&amp;privacy-policy`}>
                          Privacy Policy
                        </a>
                        .
                      </p>
                      <p id="section-12">
                        <strong>12. </strong>
                        <strong>
                          Representations, Warranties, and Covenants
                        </strong>
                      </p>
                      <p>
                        You represent, warrant and covenant for our benefit
                        that:
                      </p>
                      <p>
                        &#9679; you have the legal right and authority to enter
                        into these Terms, and, if you are accepting these Terms
                        on behalf of an organization, to bind the organization
                        to the terms of these Terms;
                      </p>
                      <p>
                        &#9679; you have the legal right and authority to
                        perform your obligations under these Terms and to grant
                        the rights and licenses described in these Terms and any
                        applicable additional terms;
                      </p>
                      <p>
                        &#9679; all User Content and User Feedback you provide
                        to us and your access and use of the Service is lawful
                        and current; and
                      </p>
                      <p>
                        &#9679; you own or control sufficient right, title and
                        interest in and to User Content and User Feedback and
                        that distribution of it, directly and indirectly,
                        throughout the Service does not and will not infringe or
                        conflict with the rights of any third party.
                      </p>
                      <p id="section-13">
                        <strong>13. </strong>
                        <strong>Indemnity</strong>
                      </p>
                      <p>
                        You agree to defend, indemnify, and hold harmless
                        Upward.net LLC and its subsidiaries, agents, licensors,
                        managers, and other affiliated companies, and their
                        employees, contractors, agents, officers, and directors,
                        from and against any and all claims, damages,
                        obligations, losses, liabilities, costs or debt, and
                        expenses (including but not limited to attorney's fees)
                        arising from: (i) your use of and access to the Service,
                        including any data or content transmitted or received by
                        you; (ii) your violation of any section of the Terms,
                        including, without limitation, any of the
                        representations and warranties above; (iii) your
                        violation of any third-party right, including without
                        limitation any right of privacy or intellectual property
                        rights; (iv) your violation of any applicable law, rule
                        or regulation; (v) any claim or damage that arises as a
                        result of User Content or User Feedback submitted via
                        your account; or (vi) any other party's access to and
                        use of the Service via your user account. We shall
                        provide notice to you promptly of any such claim, suit
                        or proceeding, and we will have the right to participate
                        in the defense of any such claim at our expense.
                      </p>
                      <p>
                        IF YOU ARE A CALIFORNIA RESIDENT, YOU WAIVE CALIFORNIA
                        CIVIL CODE SECTION 1542, WHICH SAYS: “A GENERAL RELEASE
                        DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT
                        KNOW OR SUSPECT TO EXIST IN HIS FAVOR AT THE TIME OF
                        EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM MUST HAVE
                        MATERIALLY AFFECTED HIS SETTLEMENT WITH THE DEBTOR.” If
                        you are a resident of another jurisdiction, you waive
                        any comparable statute or doctrine.
                      </p>
                      <p id="section-14">
                        <strong>14. </strong>
                        <strong>Disclaimer of Warranty</strong>
                      </p>
                      <p>
                        THE PARTIES ACKNOWLEDGE THAT THE SERVICE IS PROVIDED TO
                        YOU STRICTLY ON AN “AS IS” AND “AS AVAILABLE” BASIS WITH
                        NO WARRANTIES WHATSOEVER. YOU RECOGNIZE THAT THE CURRENT
                        STATE OF TECHNOLOGY DOES NOT ALLOW FOR ERROR-FREE ACCESS
                        TO THE SITES AND USE OF THE SERVICES, AND INTERRUPTIONS,
                        CRASHES AND DOWNTIME MAY OCCUR FROM TIME TO TIME. TO THE
                        FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW, WE
                        DISCLAIM ANY IMPLIED OR STATUTORY WARRANTY, INCLUDING
                        ANY IMPLIED WARRANTY OF TITLE, ACCURACY OF DATA,
                        NON-INFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A
                        PARTICULAR PURPOSE. UPWARD.NET MAKES NO WARRANTIES ABOUT
                        THE ACCURACY, RELIABILITY, COMPLETENESS, OR TIMELINESS
                        OF THE CONTENT, SERVICES, SOFTWARE, TEXT, GRAPHICS, AND
                        LINKS.
                      </p>
                      <p>
                        TO THE FULLEST EXTENT POSSIBLE BY LAW, UPWARD.NET DOES
                        NOT WARRANT THAT ANY SITE OR THE SERVICE WILL OPERATE
                        ERROR-FREE OR THAT ITS SERVERS ARE FREE OF COMPUTER
                        VIRUSES OR OTHER HARMFUL MECHANISMS. IF YOUR USE OF THE
                        SERVICE RESULTS IN THE NEED FOR SERVICING OR REPLACING
                        EQUIPMENT OR DATA OR ANY OTHER COSTS, UPWARD.NET IS NOT
                        RESPONSIBLE FOR THOSE COSTS.
                      </p>
                      <p>
                        WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME
                        RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR
                        OFFERED BY AN EMPLOYER OR THIRD PARTY THROUGH THE
                        SERVICE OR ANY HYPERLINKED WEBSITE OR SERVICE, AND WE
                        WILL NOT BE A PARTY TO OR IN ANY WAY MONITOR ANY
                        TRANSACTION BETWEEN YOU AND AN EMPLOYER OR THIRD-PARTY
                        PROVIDER OF PRODUCTS OR SERVICES.
                      </p>
                      <p>
                        CERTAIN JURISDICTIONS LIMIT THE ABILITY OF COMPANIES TO
                        MAKE DISCLAIMERS. THE ABOVE DISCLAIMERS AREINTENDED TO
                        BE ONLY AS BROAD AND INCLUSIVE AS IS PERMITTED UNDER
                        SUCH LAWS. IF ANY PORTION OF THIS SECTION IS HELD TO BE
                        INVALID UNDER SUCH LAWS, THE INVALIDITY OF SUCH PORTION
                        SHALL NOT AFFECT THE VALIDITY OF THE REMAINING PORTIONS
                        OF THIS SECTION.
                      </p>
                      <p id="section-15">
                        <strong>15. </strong>
                        <strong>
                          Consequential Damages Waiver; Limitation of Liability.
                        </strong>
                      </p>
                      <p>
                        <u>Consequential Damages Waiver</u>. TO THE MAXIMUM
                        EXTENT PERMITTED BY LAW, IN NO EVENT SHALL UPWARD.NET,
                        ITS AFFILIATES, AGENTS, DIRECTORS, EMPLOYEES, SUPPLIERS
                        OR LICENSORS BE LIABLE FOR ANY DIRECT, INDIRECT,
                        PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR
                        EXEMPLARY DAMAGES, INCLUDING WITHOUT LIMITATION DAMAGES
                        FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER
                        INTANGIBLE LOSSES, THAT RESULT FROM THE USE OF, OR
                        INABILITY TO USE, THIS SERVICE. UNDER NO CIRCUMSTANCES
                        WILL Upward.net BE RESPONSIBLE FOR ANY DAMAGE, LOSS OR
                        INJURY RESULTING FROM HACKING, TAMPERING OR OTHER
                        UNAUTHORIZED ACCESS OR USE OF THE SERVICE OR YOUR
                        ACCOUNT OR THE INFORMATION CONTAINED THEREIN. FURTHER.
                        TO THE FULLEST EXTENT POSSIBLE BY LAW, IN NO EVENT SHALL
                        UPWARD.NET, ITS SUPPLIERS, OR ANY THIRD PARTIES
                        MENTIONED ON THE SERVICE BE LIABLE FOR ANY DAMAGES
                        WHATSOEVER (INCLUDING, WITHOUT LIMITATION, INCIDENTAL
                        AND CONSEQUENTIAL DAMAGES, LOST PROFITS, OR DAMAGES
                        RESULTING FROM LOST DATA, LOST EMPLOYMENT OPPORTUNITY OR
                        BUSINESS INTERRUPTION) RESULTING FROM THE USE OR
                        INABILITY TO USE THE SERVICE, WHETHER BASED ON WARRANTY,
                        CONTRACT, TORT, OR ANY OTHER LEGAL THEORY, AND WHETHER
                        OR NOT UPWARD.NET IS ADVISED OF THE POSSIBILITY OF SUCH
                        DAMAGES.
                      </p>
                      <p>
                        <u>Limitation of Liability</u>. IF YOU ARE DISSATISFIED
                        WITH ANY PORTION OF THE SITES OR THE SERVICES OR WITH
                        THESE TERMS, YOUR SOLE AND EXCLUSIVE REMEDY IS TO
                        DISCONTINUE USE OF THE SERVICE, UNLESS YOU ENGAGE IN
                        BINDING ARBITRATION AS SET FORTH BELOW.YOUR RECOVERY FOR
                        ANY DAMAGES THAT OCCUR AS A RESULT OF YOUR USE OF ANY
                        SERVICE PROVIDED BY US WILL BE LIMITED TO THE GREATER
                        OF: (A) THE AMOUNT YOU ACTUALLY PAID FOR SUCH SERVICE
                        DURING THE THREE (3) MONTH PERIOD PRECEDING THE EVENT
                        GIVING RISE TO SUCH DAMAGES, OR (B) $100. THIS RECOVERY
                        FOR DAMAGES IS CUMULATIVE AND NOT PER INCIDENT.
                      </p>
                      <p>
                        THIS LIMITATION OF LIABILITY SECTION APPLIES WHETHER THE
                        ALLEGED LIABILITY IS BASED ON CONTRACT, TORT,
                        NEGLIGENCE, STRICT LIABILITY, OR ANY OTHER BASIS, EVEN
                        IF UPWARD.NET HAS BEEN ADVISED OF THE POSSIBILITY OF
                        SUCH DAMAGE. THE FOREGOING LIMITATION OF LIABILITY SHALL
                        APPLY TO THE FULLEST EXTENT PERMITTED BY LAW IN THE
                        APPLICABLE JURISDICTION.
                      </p>
                      <p>
                        SOME JURISDICTIONS DO NOT ALLOW THE DISCLAIMER OR
                        EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR
                        EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL
                        DAMAGES. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS SET
                        FORTH ABOVE MAY NOT APPLY TO YOU OR BE ENFORCEABLE WITH
                        RESPECT TO YOU, AND THE FOREGOING SHALL NOT APPLY TO
                        CERTAIN STATES’ RESIDENTS TO THE EXTENT ANY DAMAGES ARE
                        CAUSED BY OUR NEGLIGENT, FRAUDULENT, OR RECKLESS ACTS.
                      </p>
                      <p id="section-16">
                        <strong>16. </strong>
                        <strong>
                          MANDATORY ARBITRATION AND CLASS ACTION WAIVER
                        </strong>
                      </p>
                      <p>
                        <strong>
                          PLEASE READ THIS “MANDATORY ARBITRATION AND CLASS
                          ACTION WAIVER” SECTION CAREFULLY, AS IT MAY
                          SIGNIFICANTLY AFFECT YOUR LEGAL RIGHTS, INCLUDING YOUR
                          RIGHT TO FILE OR PARTICIPATE IN A LAWSUIT FILED IN
                          COURT.
                        </strong>
                      </p>
                      <p>
                        a.{" "}
                        <strong>
                          <u>Informal dispute resolution procedure</u>
                        </strong>
                      </p>
                      <p>
                        A dispute might arise between you and Upward.net (the
                        “Parties”). If that happens, Upward.net is committed to
                        working with you to reach a reasonable resolution. For
                        any issue or dispute that arises between you and
                        Upward.net, both Parties acknowledge and agree that they
                        will first make a good faith effort to resolve it before
                        initiating any formal dispute resolution proceeding in
                        arbitration or otherwise by notifying the other Party
                        and providing a written description of the dispute. For
                        any dispute you initiate, you agree to send the written
                        description of the dispute along with the email address
                        associated with your account to the following email
                        address:{" "}
                        <a href="mailto:support@upward.net">
                          support@upward.net
                        </a>
                        . For any dispute that Upward.net initiates, we will
                        send our written description of the dispute to the email
                        address associated with your account. The written
                        description must be on an individual basis and provide
                        at least the following information: your name, a
                        description of the nature or basis of the claim or
                        dispute, and the specific relief sought. Upon receipt of
                        such notice, the Parties will promptly negotiate in good
                        faith through one or more informal dispute resolution
                        meetings. The informal dispute resolution meetings shall
                        be individualized such that a separate meeting must be
                        held each time either Party intends to raise a dispute.
                        If either Party is represented by counsel, that Party's
                        counsel may participate in any informal dispute
                        resolution meeting, but the Party also must appear at
                        and participate in the conference. If the dispute is not
                        resolved satisfactorily through this informal process
                        within sixty (60) days after delivery of the written
                        notice of the dispute, you and Upward.net agree to
                        follow the formal dispute resolution provisions set
                        forth in this Section 16. Compliance with the informal
                        dispute resolution process in this Section 16(a) is a
                        prerequisite and condition precedent to commencing any
                        formal dispute resolution proceeding. The parties agree
                        that any relevant limitations period and filing fee or
                        other deadlines will be tolled while the Parties engage
                        in this informal dispute resolution process. Failure to
                        engage in this process by a Party could result in the
                        award of fees against such Party in arbitration.
                      </p>
                      <p>
                        <strong>b. </strong>
                        <strong>
                          <u>Arbitration agreement</u>
                        </strong>
                        <strong></strong>
                      </p>
                      <p>
                        You agree that all claims, disputes, or disagreements
                        that may arise out of the interpretation or performance
                        of these Terms (including their formation, performance,
                        and breach) or payments by or to Upward.net, or that in
                        any way relate to the provision or use of the Services,
                        your relationship with Upward.net, or any other dispute
                        you may have with Upward.net, shall be resolved
                        exclusively through binding arbitration in accordance
                        with this Section 16 (the "<strong>Agreement</strong>").
                        This includes claims that arose, were asserted, or
                        involve facts occurring before the existence of this or
                        any prior agreement between the Parties as well as
                        claims that may arise after the termination of this
                        Agreement. This Agreement is governed by the Federal
                        Arbitration Act ("FAA") in all respects and evidences a
                        transaction involving interstate commerce. You and
                        Upward.net expressly agree that the FAA shall
                        exclusively govern the interpretation and enforcement of
                        the arbitration provisions of this Agreement (Section 16
                        If for whatever reason the rules and procedures of the
                        FAA cannot apply, the laws governing arbitration
                        agreements in the state of California shall apply.
                      </p>
                      <p>
                        Except as set forth in this Section 16(b), the
                        arbitrator, and not any federal, state or local court or
                        agency, shall have exclusive authority to resolve all
                        disputes arising out of or relating to the
                        interpretation, applicability, enforceability or
                        formation of these Terms and this Agreement, including,
                        but not limited to any claim that all or any part
                        thereof are void or voidable, whether a claim is subject
                        to arbitration, and any dispute regarding the payment of
                        JAMS administrative or arbitrator fees (including the
                        timing of such payments and remedies for nonpayment).
                        The arbitrator shall be empowered to grant whatever
                        relief would be available in a court under law or in
                        equity.
                      </p>
                      <p>
                        Notwithstanding the Parties’ decision to resolve
                        disputes through arbitration, each party retains the
                        right to (i) elect to have any claims resolved in small
                        claims court on an individual basis for disputes and
                        actions within the scope of such court’s jurisdiction;
                        (ii) bring an action in state or federal court to
                        protect its intellectual property rights; for such
                        purpose, intellectual property rights means patents,
                        copyrights, moral rights, trademarks, and trade secrets
                        and other confidential or proprietary information, but
                        not privacy or publicity rights; and (iii) seek a
                        declaratory judgment, injunction, or other equitable
                        relief in a court of competent jurisdiction regarding
                        whether a Party's claims are time-barred or may be
                        brought in small claims court. Seeking such relief shall
                        not waive a Party's right to arbitration under this
                        Agreement, and any filed arbitrations related to any
                        action filed pursuant to this paragraph shall
                        automatically be stayed pending the outcome of such
                        action.
                      </p>
                      <p>
                        You and Upward.net agree to submit to the personal
                        jurisdiction of any federal or state court in San
                        Francisco County, California, in order to compel
                        arbitration, to stay proceedings pending arbitration, or
                        to confirm, modify, vacate, or enter judgment on the
                        award entered by the arbitrator; and in connection with
                        any such proceeding, further agree to accept service of
                        process by U.S. mail and hereby waive any and all
                        jurisdictional and venue defenses otherwise available.
                      </p>
                      <p>
                        Except as set forth in Section 16(c) below, if any
                        provision of this Agreement is found by an arbitrator or
                        court of competent jurisdiction to be invalid, the
                        Parties nevertheless agree that the arbitrator or court
                        should endeavor to give effect to the Parties’
                        intentions as reflected in this Section 16, and the
                        other provisions thereof remain in full force and
                        effect.
                      </p>
                      <p>
                        THE PARTIES UNDERSTAND THAT ARBITRATION MEANS THAT AN
                        ARBITRATOR AND NOT A JUDGE OR JURY WILL DECIDE THE
                        CLAIM, AND THAT RIGHTS TO PREHEARING EXCHANGE OF
                        INFORMATION AND APPEALS MAY BE LIMITED IN ARBITRATION.
                        <strong>
                          EACH PARTY HEREBY ACKNOWLEDGES AND AGREES THAT YOU AND
                          UPWARD.NET ARE EACH WAIVING THE RIGHT TO A TRIAL BY
                          JURY TO THE MAXIMUM EXTENT PERMITTED BY LAW.
                        </strong>
                      </p>
                      <p>
                        <strong>c. </strong>
                        <strong>
                          <u>Class action and collective relief waiver</u>
                        </strong>
                        <strong></strong>
                      </p>
                      <p>
                        YOU AND UPWARD.NET ACKNOWLEDGE AND AGREE THAT, TO THE
                        MAXIMUM EXTENT ALLOWED BY LAW, EXCEPT AS SET OUT IN
                        SECTION 16(g), THERE SHALL BE NO RIGHT OR AUTHORITY FOR
                        ANY DISPUTE TO BE ARBITRATED OR LITIGATED ON A CLASS,
                        JOINT, COLLECTIVE OR CONSOLIDATED BASIS OR IN A
                        PURPORTED REPRESENTATIVE CAPACITY ON BEHALF OF THE
                        GENERAL PUBLIC (SUCH AS CLAIMS AS A PRIVATE ATTORNEY
                        GENERAL OR FOR PUBLIC INJUNCTIVE RELIEF). EXCEPT AS SET
                        OUT IN SECTION 16(g), UNLESS BOTH YOU AND UPWARD.NET
                        OTHERWISE AGREE IN WRITING, THE ARBITRATOR MAY NOT
                        PRESIDE OVER ANY FORM OF ANY CLASS, JOINT, COLLECTIVE OR
                        REPRESENTATIVE PROCEEDING. THE ARBITRATOR MAY AWARD
                        RELIEF (INCLUDING ANY DECLARATORY OR INJUNCTIVE RELIEF)
                        ONLY IN FAVOR OF THE INDIVIDUAL PARTY SEEKING RELIEF AND
                        ONLY TO THE EXTENT NECESSARY TO RESOLVE AN INDIVIDUAL
                        PARTY’S CLAIM. THE ARBITRATOR MAY NOT AWARD RELIEF FOR
                        OR AGAINST ANY PERSON OR ENTITY THAT IS NOT A PARTY TO
                        THE PROCEEDING.
                      </p>
                      <p>
                        Both Section 16(c) and Section 16(g) are an essential
                        part of this Agreement, and if all or any part of them
                        is deemed invalid or unenforceable with respect to a
                        particular claim or dispute, neither you nor Upward.net
                        may arbitrate such claim or dispute. Notwithstanding the
                        foregoing, if a court or arbitrator determines that
                        either Section 16(c) or Section 16(g) are not
                        enforceable as to a particular claim or request for
                        relief and all appeals from that decision have been
                        exhausted (or the decision is otherwise final), then the
                        Parties agree that that particular claim or request for
                        relief may proceed in court but shall be severed and
                        stayed pending arbitration of the remaining claims. This
                        Section does not prevent you or Upward.net from
                        participating in a class-wide settlement of claims.
                      </p>
                      <p>
                        <strong>d. </strong>
                        <strong>
                          <u>Arbitration rules</u>
                        </strong>
                        <strong></strong>
                      </p>
                      <p>
                        The arbitration will be administered by JAMS and
                        resolved before a single arbitrator. If JAMS is not
                        available to arbitrate, the Parties will promptly select
                        an alternative arbitration provider, but in no event
                        shall any arbitration be administered by the American
                        Arbitration Association. Except as modified by this
                        Section 16, JAMS will administer the arbitration in
                        accordance with the JAMS Streamlined Arbitration Rules
                        and Procedures for claims that do not exceed $250,000
                        and the JAMS Comprehensive Arbitration Rules and
                        Procedures for claims exceeding $250,000, in each case
                        applying the rules and procedures in effect at the time
                        the arbitration is initiated, excluding any rules or
                        procedures governing or permitting class or
                        representative actions. The JAMS rules and procedures
                        are available at &nbsp;
                        <a href="https://www.jamsadr.com/adr-rules-procedures/">
                          https://www.jamsadr.com/adr-rules-procedures/
                        </a>
                        or by calling JAMS at (800) 352-5267.
                      </p>
                      <p>
                        <strong>e. </strong>
                        <strong>
                          <u>Initiating arbitration</u>
                        </strong>
                        <strong></strong>
                      </p>
                      <p>
                        Only after the Parties have engaged in a good-faith
                        effort to resolve the dispute in accordance with Section
                        16(a), and only if those efforts fail, then either Party
                        may initiate binding arbitration as the sole means to
                        resolve such dispute. A Party that desires to initiate
                        arbitration must deliver to JAMS and the other Party a
                        demand for arbitration that satisfies the following
                        requirements: (1) inclusion of the name, telephone
                        number, mailing address, and email address of the Party
                        seeking arbitration (if you are seeking arbitration, you
                        must provide the email address associated with your
                        Upward.net account); (2) a statement of the legal claims
                        being asserted and the factual bases of those claims;
                        (3) a description of the remedy sought and a good-faith
                        calculation of the amount in controversy (requests for
                        injunctive relief or attorneys’ fees shall not count
                        toward the calculation of the amount in controversy
                        unless such injunctive relief seeks the payment of
                        money); (4) the Party's original signature; and (5) the
                        Party's portion of the applicable filing fee. If the
                        Party seeking arbitration is represented by outside
                        counsel, the demand for arbitration must also include
                        the counsel's name, firm, telephone number, mailing
                        address, email address, and original signature.
                      </p>
                      <p>
                        If you are initiating arbitration, hard-copy service of
                        the demand to Upward.net shall be made in accordance
                        with JAMS's rules and procedures to the following
                        address: Upward.net, 8000 Jarvis Ave, Newark, CA 94560,
                        Attention: General Counsel, and a copy of the same shall
                        also be emailed to{" "}
                        <a href="mailto:support@upward.net">
                          support@upward.net
                        </a>
                        . If Upward.net is initiating arbitration, it will serve
                        a copy of the demand to the email address associated
                        with your Upward.net account, as well as in hard copy if
                        Upward.net knows your mailing address.
                      </p>
                      <p>
                        The arbitrator and/or JAMS may require amendment of any
                        demand or counterclaim that does not satisfy these
                        requirements. When a Party is represented by outside
                        counsel, counsel's signature on the demand for
                        arbitration or any other paper submitted by or on behalf
                        of counsel to JAMS or the arbitrator constitutes a
                        certification that such paper complies with the standard
                        set forth in Federal Rule of Civil Procedure 11(b). The
                        arbitrator has the right to impose sanctions in
                        accordance with the JAMS rules and procedures for any
                        claims or submissions the arbitrator determines to
                        violate such standard, as well as for a Party's failure
                        to comply with Section 16(a).
                      </p>
                      <p>
                        <strong>f. </strong>
                        <strong>
                          <u>Arbitration location and procedure</u>
                        </strong>
                        <strong></strong>
                      </p>
                      <p>
                        Unless you and Upward.net otherwise agree, if you are a
                        resident of the United States the arbitration will be
                        conducted in the county where you reside, and if you are
                        not a resident of the United States the arbitration
                        shall be conducted in California, United States of
                        America. If the amount in controversy does not exceed
                        $10,000 and neither Party seeks injunctive or
                        declaratory relief, then the arbitration will be
                        conducted solely on the basis of documents you and
                        Upward.net submit to the arbitrator, unless the
                        arbitrator determines that a hearing is necessary. If
                        the amount in controversy exceeds $10,000 or either
                        Party seeks declaratory or injunctive relief, either
                        party may request (or the arbitrator may determine) to
                        hold a hearing, which shall be held via videoconference
                        or telephone conference unless the Parties agree
                        otherwise.
                      </p>
                      <p>
                        Subject to the applicable JAMS rules and procedures, the
                        arbitrator will have the discretion to direct a
                        reasonable exchange of information by the Parties, with
                        the objective of maintaining the expedited,
                        cost-efficient nature of the arbitration. The Parties
                        agree that the arbitrator may allow the filing of
                        dispositive motions if they are likely to efficiently
                        resolve or narrow issues in dispute. If a Party timely
                        serves an offer of judgment under Federal Rule of Civil
                        Procedure 68, or any other state-law equivalent, and the
                        judgment that the other Party finally obtains is not
                        more favorable than the unaccepted offer, then the other
                        Party shall pay the costs, including filing fees,
                        incurred after the offer was made. Unless otherwise
                        prohibited by law, all arbitration proceedings will be
                        confidential and closed to the public and any parties
                        other than you and Upward.net, and all records relating
                        thereto will be permanently sealed, except as necessary
                        to obtain court confirmation of the arbitration award
                        (provided that the Party seeking confirmation shall seek
                        to file such records under seal to the extent permitted
                        by law).
                      </p>
                      <p>
                        <strong>g. </strong>
                        <strong>
                          <u>Batch arbitration</u>
                        </strong>
                        <strong></strong>
                      </p>
                      <p>
                        To increase the efficiency of administration and
                        resolution of arbitrations, in the event one hundred or
                        more similar arbitration demands (those asserting the
                        same or substantially similar facts, and seeking the
                        same or substantially similar relief) presented by or
                        with the assistance or coordination of the same law
                        firm(s) or organization(s) or groups of such law firm(s)
                        or organization(s) are submitted to JAMS (or another
                        arbitration provider selected in accordance with Section
                        16(d) if JAMS is unavailable) against Upward.net within
                        a reasonably short timeframe, the arbitration provider
                        shall (i) administer the arbitration demands in batches
                        of 100 demands per batch (to the extent there are fewer
                        than one hundred arbitration demands left over after the
                        batching described above, a final batch will consist of
                        the remaining demands); (ii) designate one arbitrator
                        for each batch; and (iii) provide for a single filing
                        fee due per side per batch. Arbitrator selection for
                        each batch shall be conducted to the greatest extent
                        possible in accordance with the applicable JAMS rules
                        and procedures for such selection, and the arbitrator
                        will determine the location where the proceedings will
                        be conducted. You agree to cooperate in good faith with
                        Upward.net and the arbitration provider to implement
                        such a “batch approach” or other similar approach to
                        provide for an efficient resolution of claims, including
                        the payment of single filing and administrative fees for
                        batches of claims. This Section 16(g) shall in no way be
                        interpreted as authorizing class arbitration of any
                        kind. Upward.net does not agree or consent to class
                        arbitration, private attorney general arbitration, or
                        arbitration involving joint or consolidated claims under
                        any circumstances, except as set forth in this section
                        16(g).
                      </p>
                      <p>
                        <strong>h. </strong>
                        <strong>
                          <u>Arbitrator's decision</u>
                        </strong>
                        <strong></strong>
                      </p>
                      <p>
                        The arbitrator will render an award within the time
                        frame specified in the applicable JAMS rules and
                        procedures. The arbitrator's decision will include the
                        essential findings and conclusions upon which the
                        arbitrator based the award. Judgment on the arbitration
                        award may be entered in any court with valid
                        jurisdiction. The arbitrator will have the authority to
                        award monetary damages on an individual basis and to
                        grant, on an individual basis, any non-monetary remedy
                        or relief available to an individual to the extent
                        available under applicable law, the arbitral forum's
                        rules, and this Agreement. The arbitrator's award of
                        damages and/or other relief must be consistent with
                        Section 16(c) and Section 15 as to the types and the
                        amounts of damages or other relief for which a Party may
                        be held liable. No arbitration award or decision will
                        have any preclusive effect as to issues or claims in any
                        dispute with any person or entity that is not a named
                        party to the arbitration.
                      </p>
                      <p>
                        Attorneys’ fees will be available to the prevailing
                        party in the arbitration only if authorized under
                        applicable substantive law governing the claims in the
                        arbitration.
                      </p>
                      <p>
                        <strong>i. </strong>
                        <strong>
                          <u>Fees</u>
                        </strong>
                        <strong></strong>
                      </p>
                      <p>
                        Pursuant to the applicable JAMS Rules and Consumer
                        Arbitration Minimum Standards, you will be required to
                        pay $250 to initiate an arbitration against Upward.net.
                        To the extent the filing fee for the arbitration exceeds
                        the cost of filing a lawsuit, the arbitrator may require
                        Upward.net to pay the portion of that fee that exceeds
                        the cost of filing suit. You are responsible for your
                        own attorneys' fees unless the arbitration rules and/or
                        applicable law provide otherwise. If the arbitrator
                        finds the arbitration to be non-frivolous, Upward.net
                        will pay all of the actual filing and arbitrator fees
                        for the arbitration, provided your claim does not exceed
                        $75,000. For claims above $75,000, fees and costs will
                        be determined in accordance with applicable JAMS rules.
                        The arbitration rules permit you to recover attorneys’
                        fees in certain cases.
                      </p>
                      <p>
                        The Parties agree that JAMS has discretion to modify the
                        amount or timing of any administrative or arbitration
                        fees due under JAMS's Rules when it deems appropriate
                        (including as specified in Section 16(g)), provided that
                        such modification does not increase the costs to you,
                        and you waive any objection to such fee modification.
                        The Parties also agree that a good-faith challenge by
                        either Party to the fees imposed by JAMS does not
                        constitute a default, waiver, or breach of this Section
                        16 while such challenge remains pending before JAMS, the
                        arbitrator, and/or a court of competent jurisdiction,
                        and that any and all due dates for those fees shall be
                        tolled during the pendency of such challenge.
                      </p>
                      <p>
                        <strong>j. </strong>
                        <strong>
                          <u>Right to opt-out of arbitration</u>
                        </strong>
                        <strong></strong>
                      </p>
                      <p>
                        Upward.net’s updates to these Terms do not provide you
                        with a new opportunity to opt out of arbitration if you
                        agreed to a previous version of the Terms and did not
                        validly opt out of arbitration. Upward.net will continue
                        to honor any valid opt outs if you opted out of
                        arbitration in a prior version of the Terms. If you
                        create a Upward.net account for the first time on or
                        after January 1, 2023, you may opt out of arbitration.
                        To opt out, you must notify Upward.net in writing no
                        later than 30 days after first becoming subject to these
                        Terms. Your notice must include your full name, mailing
                        address, the email address associated with your
                        Upward.net account, a clear indication that you want to
                        opt out of this Mutual Arbitration Agreement, and your
                        original signature. The notice cannot be signed by your
                        attorney, agent, or other representative, and you may
                        only opt out on behalf of yourself individually. You
                        must send your opt-out notice by U.S. Postal Service
                        certified mail to Upward.net, 8000 Jarvis Ave, Newark,
                        CA 94560, Attention: Legal Department. If you opt out of
                        this Section 16, all other parts of this Agreement will
                        continue to apply to you. Opting out of this Section 16
                        has no effect on any other arbitration agreements that
                        you may have entered into with Upward.net or may enter
                        into in the future with Upward.net. If you do not timely
                        opt out of this Section 16, such inaction shall
                        constitute mutual acceptance of the terms of this
                        Agreement by you and Upward.net.
                      </p>
                      <p>
                        <strong>k. </strong>
                        <strong>
                          <u>Changes to this Section</u>
                        </strong>
                        <strong></strong>
                      </p>
                      <p>
                        Upward.net may amend or modify this Section 16 by
                        posting an update to this Agreement on the Site but no
                        such change will apply to any individual claim of which
                        Upward.net already has actual notice. If Upward.net
                        amends or modifies this Section 16 after the date you
                        first accepted this Section 16 (or accepted any
                        subsequent changes to this Agreement), you agree that
                        your continued use of the Platform or Services 30 days
                        after such change will be deemed acceptance of those
                        changes. If you do not agree to such change, you may
                        reject any such change by providing Upward.net written
                        notice of such rejection by certified mail to:
                        Upward.net, Inc., 8000 Jarvis Ave, Newark, CA 94560,
                        Attention: Legal Department, and a copy of the same
                        shall also be emailed to{" "}
                        <a href="">support@upward.net,</a> within the 30 day
                        period following the announcement of such change. To be
                        effective, the notice must include your full name and
                        clearly indicate your intent to reject changes to this
                        Section 16. In the event Upward.net receives an
                        effective notice that opts you our of such changes,
                        Upward.net may elect to terminate this Agreement in
                        whole or in part. Upward.net’s modifications or
                        revisions to this Agreement do not provide you with an
                        opportunity to opt out of arbitration if you were bound
                        by a previous version of the Agreement that included
                        arbitration provisions and you did not validly opt out
                        of arbitration. If you reject changes pursuant to this
                        Section 16 and were already bound by an existing
                        agreement to arbitrate disputes under a previous version
                        of the Terms, then such prior agreement shall remain in
                        full force and effect.<strong></strong>
                      </p>
                      <p id="section-17">
                        <strong>17. </strong>
                        <strong>Term and Termination</strong>
                      </p>
                      <p>
                        These Terms will be effective for as long as you use or
                        access the Service. However, the sections below still
                        apply beyond termination or expiration of these Terms:
                      </p>
                      <p>&#9679; General Service Information</p>
                      <p>
                        &#9679; User Content and Feedback; License to User
                        Content and Feedback
                      </p>
                      <p>
                        &#9679; End User License Grant and Upward.Net
                        Intellectual Property Rights
                      </p>
                      <p>&#9679; Third-Party Links and Services</p>
                      <p>&#9679; Representations, Warranties, and Covenants</p>
                      <p>&#9679; Indemnity</p>
                      <p>&#9679; Disclaimers</p>
                      <p>&#9679; Limitation of Liability</p>
                      <p>
                        &#9679; MANDATORY ARBITRATION AND CLASS ACTION WAIVER
                      </p>
                      <p>&#9679; General Provisions</p>
                      <p id="section-18">
                        <strong>18. </strong>
                        <strong>General Provisions</strong>
                      </p>
                      <p>
                        &#9679; These Terms, and any rights and licenses granted
                        hereunder, may not be transferred or assigned by you.
                        Any such assignment, delegation, or transfer in
                        violation of the foregoing shall be null and voice.
                        Upward.net may freely assign, delegate, or otherwise
                        transfer these Terms or any of our rights and
                        obligations herein without your consent and without
                        notice to you.
                      </p>
                      <p>
                        &#9679; These Terms will be governed by the laws of the
                        state of California without regard to its conflicts of
                        law principles. With respect to any disputes or claims
                        not subject to arbitration, as set forth above, all
                        disputes arising under these Terms must be brought in
                        the state or federal courts located in San Francisco
                        County, California, and each party irrevocably hereby
                        consents to the jurisdiction and venue of any such court
                        in any such action or proceeding.
                      </p>
                      <p>
                        &#9679; Except as expressly set forth herein, there are
                        no other third-party beneficiaries under these Terms.
                      </p>
                      <p>
                        &#9679; The waiver of a breach of any provision of these
                        Terms will not operate or be interpreted as a waiver of
                        any other or subsequent breach.
                      </p>
                      <p>
                        &#9679; If any provision of these Terms is deemed
                        unenforceable, such provision will be changed and
                        interpreted to accomplish the objectives of such
                        provision to the greatest extent possible under
                        applicable law, and the remaining provisions will
                        continue in full force and effect.
                      </p>
                      <p>
                        &#9679; Nothing in these Terms shall be construed as
                        making either party the partner, joint venturer,
                        employer, contractor or employee of the other. Neither
                        party shall have, or hold itself out to any third party
                        as having any authority to make any statements,
                        representations or commitments of any kind, or to take
                        any action, that shall be binding on the other, except
                        as provided for herein or authorized in writing by the
                        party to be bound.
                      </p>
                      <p>
                        &#9679; We will be excused from performance for any
                        period during which, and to the extent that, we are
                        prevented from performing any obligation or provide the
                        Service, in whole or in part, as a result of a cause
                        beyond our reasonable control and without our fault or
                        negligence, including, but not limited to, acts of God,
                        acts of war, epidemics, fire, communication line
                        failures, power failures, earthquakes, floods, blizzard,
                        or other natural disasters (but excluding failure caused
                        by a party's financial condition or any internal labor
                        problems (including strikes, lockouts, work stoppages or
                        slowdowns, or the threat thereof)) (a “Force Majeure
                        Event”). Delays in performing obligations due to a Force
                        Majeure Event will automatically extend the deadline for
                        performing such obligations for a period equal to the
                        duration of such Force Majeure Event.
                      </p>
                      <p>
                        &#9679; We may provide notifications, whether such
                        notifications are required by law or are for marketing
                        or other business related purposes, to you via email
                        notice, written or hard copy notice, or through
                        conspicuous posting of such notice on our website, as we
                        determine in our sole discretion. We reserve the right
                        to determine the form and means of providing
                        notifications to our users, provided that you may opt
                        out of certain means of notification as described in
                        these Terms. Upward.net is not responsible for any
                        automatic filtering you or your network provider may
                        apply to email notifications we send to the email
                        address you provide us.
                      </p>
                      <p>
                        &#9679; These Terms, together with any amendments and
                        any additional agreements you may enter into with
                        Upward.net in connection with the Service, shall
                        constitute the entire agreement between you and
                        Upward.net concerning the Service. If any provision of
                        these Terms are deemed invalid by a court of competent
                        jurisdiction, the invalidity of such provision shall not
                        affect the validity of the remaining provisions of these
                        Terms, which shall remain in full force and effect. No
                        waiver of any term of the Terms shall be deemed a
                        further or continuing waiver of such term or any other
                        term, and our failure to assert any right or provision
                        under these Terms shall not constitute a waiver of such
                        right or provision.
                      </p>
                      <p id="section-19">
                        <strong>19. </strong>
                        <strong>Notice to California Users</strong>
                      </p>
                      <p>
                        Under California Civil Code Section 1789.3, users of the
                        Services from California are entitled to the following
                        specific consumer rights notice: The Complaint
                        Assistance Unit of the Division of Consumer Services of
                        the California Department of Consumer Affairs may be
                        contacted in writing at 1625 North Market Blvd., Suite N
                        112, Sacramento, CA 95834, or by telephone at (916)
                        445-1254 or (800) 952-5210.
                      </p>
                      <p id="section-20">
                        <strong>20. </strong>
                        <strong> Contact Us</strong>
                      </p>
                      <p>
                        Please contact us at{" "}
                        <a href="mailto:support@Upward.net">
                          support@Upward.net
                        </a>{" "}
                        to report any violations of these Terms or to ask any
                        questions regarding these Terms. You may also contact us
                        a <u>Upward.net</u>, 8000 Jarvis Ave, Newark, CA 94560,
                        Attention: Support.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      </ModalBody>
    </React.Fragment>
  );
};

export default TC;
