import { Link } from "react-router-dom";

import renderHeaderDropdown from "./HeaderDropDownHOC";
import { requestDomain } from "../../homepage/services/SeekerServices";

const jobSeekerOptions = [
    {"tag" : "a", "href" : `${requestDomain}/results`, "title" : "Search for jobs" },
    {"tag" : "a", "href" : `${requestDomain}/results/?reg=y`, "title" : "Create an account" },
    {"tag" : Link, "href" : "/?signin=y", "title" : "Job seeker sign in" }
];

const JobSeekerDropDown = renderHeaderDropdown(
    "For job seekers",
    [...jobSeekerOptions]
);

export default JobSeekerDropDown;
