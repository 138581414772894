import React, { Component } from "react";
import { Link } from "react-router-dom";

import { Container, Card, Button } from "reactstrap";

import favicon192Black from "assets/img/homepage/favicon/192x192black.png";

// const Page404 = () => (
class Page404 extends Component {

  componentDidMount() {
    document.getElementsByTagName("body")[0].className = 'backgroundColorF8FAFC';

  }

  render() {

    return (
      <Container >
        <Card style={{ boxShadow: '0 0.5rem 1rem rgba(20, 40, 60, 0.03)', padding: '4rem' }} className="mt-4">
          <div className="text-center">
            <img src={favicon192Black} alt="Upward" />
            <h1 className="display-1 font-weight-bold">404</h1>
            <p className="h1">Page not found.</p>
            <p className="h2 font-weight-normal mt-3 mb-4">
              The page you are looking for might have been removed.
    </p>
            <Link to="/">
              <Button color="primary" size="lg">
                Return to website
      </Button>
            </Link>
          </div>
        </Card>
      </Container>
    )
  }
}
// );

export default Page404;
