import * as types from './constants';

const defaultState = {
    radius: 0,
    location: "",
    alertId: null,
    alerts: [],
    isLoading: true,
    error: "",
    addingNewAlert: false,
    addAlertFailure: "",
    updatingAlert: false
}


export default function (state = defaultState, action) {
    switch (action.type) {
        case types.JOB_SEEKER_FETCH_ALERTS:
            return {
                ...state,
                error: ""
            }
        case types.JOB_SEEKER_FETCH_ALERTS_BEGIN:
            return {
                ...state,
                isLoading: true
            }
        case types.JOB_SEEKER_UPDATE_ALERTS:
            return {
                ...state,
                isLoading: false,
                radius: action.payload.radius,
                location: action.payload.location,
                alertId: action.payload.alertId,
                alerts: [...action.payload.alertTerms]
            }
        case types.JOB_SEEKER_FETCH_ALERTS_ERROR:
            return {
                ...state,
                error: action.payload,
                isLoading: false
            }
        case types.JOB_SEEKER_ADD_NEW_ALERT_BEGIN:
            return {
                ...state,
                addAlertFailure: "",
                addingNewAlert: true
            }
        case types.JOB_SEEKER_ADD_NEW_ALERT:
            const alerts = state.alerts.filter((item) => item !== action.payload);
            return {
                ...state,
                addingNewAlert: false,
                addAlertFailure: "",
                alerts: [action.payload, ...alerts]
            }
        case types.JOB_SEEKER_ADD_NEW_ALERT_FAILURE:
            return {
                ...state,
                addAlertFailure: action.payload
            }
        case types.JOB_SEEKER_DELETE_ALERT:
            return {
                ...state,
                alerts: [...state.alerts.filter((item) => item !== action.payload)]
            }
        case types.JOB_SEEKER_UPDATE_ALERT_BEGIN:
            return {
                ...state,
                addAlertFailure: "",
                updatingAlert: true
            }
        case types.JOB_SEEKER_UPDATE_ALERT:
            return {
                ...state,
                addAlertFailure: "",
                alerts: state.alerts.map(alert => alert === action.payload.oldKeyword ? action.payload.newKeyword : alert),
                updatingAlert: false
            }
        case types.JOB_SEEKER_UPDATE_ALERT_FAILURE:
            return {
                ...state,
                addAlertFailure: action.payload,
                updatingAlert: false
            }
        default:
            return state;
    }
}