export const toTitleCase = (words) => {
   if (!words || !(typeof words === "string")) {
      return words;
   }

   return words.toLowerCase()
      .split(" ")
      .map(
         (word) => (word[0].toUpperCase() + word.slice(1))
      ).join(" ");
};