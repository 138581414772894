import React, { useContext } from "react";
import { Helmet } from "react-helmet";

import { ThemeContext } from "corp-site/employer/context/ThemeContext";

import favicon57 from "assets/img/homepage/favicon/57x57.png";
import favicon76 from "assets/img/homepage/favicon/76x76.png";
import favicon120 from "assets/img/homepage/favicon/120x120.png";
import favicon128 from "assets/img/homepage/favicon/128x128.png";
import favicon152 from "assets/img/homepage/favicon/152x152.png";
import favicon167 from "assets/img/homepage/favicon/167x167.png";
import favicon180 from "assets/img/homepage/favicon/180x180.png";
import favicon192 from "assets/img/homepage/favicon/192x192.png";

import JCFav from "assets/img/upward-logos/JCFav.png";





const HeaderImagesComponent = ( props ) => {
    const context = useContext( ThemeContext );

    return (
        <Helmet>
            <link rel="apple-touch-icon" href={favicon128} />
            {(context === "red") && <link href={JCFav} rel="shortcut icon" />}
            {(context === "green") && <link href={JCFav} rel="shortcut icon" />}
            {(context === "purple") && <link href={JCFav} rel="shortcut icon" />}
            {(context === "orange") && <link href={JCFav} rel="shortcut icon" />}
            {(context === "blue") && <link href={JCFav} rel="shortcut icon" />}
            <link rel="apple-touch-icon" sizes="57x57" href={favicon57} />
            <link rel="apple-touch-icon" sizes="76x76" href={favicon76} />
            <link rel="apple-touch-icon" sizes="120x120" href={favicon120} />
            <link rel="apple-touch-icon" sizes="152x152" href={favicon152} />
            <link rel="apple-touch-icon" sizes="167x167" href={favicon167} />
            <link rel="apple-touch-icon" sizes="180x180" href={favicon180} />
            <link rel="icon" sizes="192x192" href={favicon192} />
            <link rel="icon" sizes="128x128" href={favicon128} />
        </Helmet>
    )
}


export default HeaderImagesComponent;