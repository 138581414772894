import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import queryString from "query-string";

import SignInForm from "corp-site/components/SignInForm";

import ResetPassword from "../signin/ResetPassword";

import { fetchSiteDetails } from "corp-site/job-seeker/profile/redux/actions";
import { logInUser, resetUserPassword, checkIfLoggedIn, requestDomain } from "../../services/SeekerServices";

import { fetchJobSeekerProfile } from 'corp-site/job-seeker/profile/redux/actions'

const SeekerSignIn = ({ className, history, location }) => {
    const dispatch = useDispatch();
    const [loginError, setLoginError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [showSigninForm, setshowSigninForm] = useState(true);
    const [resetSuccess, setResetSuccess] = useState("");
    const [resetError, setResetError] = useState("");

    const searchObject = useSelector(state => state.jobSeekerResults.searchObject);

    const qs = queryString.parse(location.search);

    const onCloseModal = () => {
        history.push(window.location.pathname);
    }

    const handleRedirect = (loginResponse) => {
        if (loginResponse.response === "success") {
            if (window.location.href.indexOf("p=myResume") > -1 || window.location.href.indexOf("p=myProfile") > -1) {
                setTimeout(() => {
                    window.location = requestDomain + "/employee/myResume.jsp";
                }, 10);
            } else if (window.location.href.indexOf("p=myRecentSearches") > -1) {
                setTimeout(() => {
                    window.location = requestDomain + "/employee/myRecentSearches.jsp";
                }, 10);
            } else {
                setTimeout(() => {
                    const parsedQs = { ...qs };
                    delete parsedQs.signin;
                    dispatch(fetchJobSeekerProfile());
                    window.location = requestDomain + "/results" + "?" + queryString.stringify(parsedQs);
                }, 10);
            }
        } else {
            setLoginError(loginResponse.message);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        setIsLoading(true);
        checkIfLoggedIn().subscribe(
            (loginResponse) => {
                handleRedirect(loginResponse);
            }
        )
    }, []);

    useEffect(() => {
      if (!searchObject.siteDataFetched) {
        dispatch(fetchSiteDetails())
      }

    }, [searchObject])

    const handleFormSubmit = (data) => {
        let newData = data;
        newData['email'] = data['username'];
        setIsLoading(true);
        logInUser(data).subscribe(
            (loginResponse) => {
                handleRedirect(loginResponse);
            }
        )
    }

    const handleFormReset = (data) => {
        resetUserPassword(data).subscribe(
            (resetResponse) => {
                if (resetResponse.response === "success") {
                    setResetSuccess(resetResponse.message);
                    setResetError("");
                } else {
                    setResetError(resetResponse.message);
                    setResetSuccess("");
                }
                setIsLoading(false);
            }
        )
    }

    return (
        <Modal isOpen={true} className={(className ? className : "") + " rounded_modal signin-modal"} toggle={onCloseModal} backdrop="static" keyboard={true} >
            <button type="button" onClick={onCloseModal} className="close modalClose" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            <ModalHeader>{showSigninForm ? 'Job Seeker Sign In' : 'Forgot your password?'}</ModalHeader>
            <ModalBody>
                {showSigninForm &&
                    <React.Fragment>
                        <SignInForm onSubmit={handleFormSubmit} loginError={loginError} isLoading={isLoading} />
                    </React.Fragment>
                }
                {!showSigninForm &&
                    <React.Fragment>
                        <ResetPassword initialValues={{creativeId: 72, siteId: searchObject.siteId}} onSubmit={handleFormReset} resetError={resetError} resetSuccess={resetSuccess} />
                        <div className="text-center mt-2"><button className="btn btn-reset" onClick={() => setshowSigninForm(true)}>Back to Job Seeker Sign In</button></div>
                    </React.Fragment>
                }
            </ModalBody>
            <ModalFooter>
                <div className="float-left d-none d-lg-block">
                    Employer? <Link to="/employers?signin=y">Sign in here</Link>
                </div>
                <div className="float-right">
                    Don't have an account? <a href={`${requestDomain}/results/?pageNum=1&reg=y`}>Sign up</a>
                </div>
            </ModalFooter>
        </Modal>
    )

}


export default withRouter(SeekerSignIn);
