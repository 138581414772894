import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";


import { ThemeContext } from "../context/ThemeContext";
import UpwardLogoWhite from "components/Logo_White";
import Abstract2 from "../Abstracts/Abstract2";

import VerticalList from "../verticals/VerticalList";

import { requestDomain, netDomain } from "../../homepage/services/SeekerServices";
import privacyChoicesIcon from '../../../assets/img/osano/privacy_choices_icon.png'

const footerVerticals = [...VerticalList];

class HomeFooter extends Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        const { pathname } = this.props.location;
        return (
            <footer className={"upward_footer " + (this.context ? "footer_" + this.context : "")}>
                <div className="footer_abstract_wrapper">
                    <div className="abstract_wrapper">
                        <Abstract2 />
                    </div>
                </div>
                <div className={"position-relative bg-dark-" + this.context} style={{ "marginTop": "-3px", "zIndex": "99" }}>
                    <Container>
                        <div className="footer_logo_wrapper clearfix">
                            <div className="float-left" style={{ "width": "120px" }}>
                                <UpwardLogoWhite />
                            </div>
                        </div>
                    </Container>
                </div>
                {
                    // <Container>
                    //     <div className="row justify-content-md-center">
                    //         <div className="col-md-3">
                    //             <h5>Top jobs by Title</h5>
                    //             <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam eu porta ipsum. Proin vestibulum rutrum lacinia. In hac habitasse platea dictumst.</p>
                    //         </div>
                    //         <div className="col-md-3">
                    //             <h5>Top jobs by Industry</h5>
                    //             <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam eu porta ipsum. Proin vestibulum rutrum lacinia. In hac habitasse platea dictumst.</p>
                    //         </div>
                    //         <div className="col-md-3">
                    //             <h5>Top jobs by Cities</h5>
                    //             <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam eu porta ipsum. Proin vestibulum rutrum lacinia. In hac habitasse platea dictumst.</p>
                    //         </div>
                    //         <div className="col-md-3">
                    //             <h5>Top jobs by Company</h5>
                    //             <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam eu porta ipsum. Proin vestibulum rutrum lacinia. In hac habitasse platea dictumst.</p>
                    //         </div>
                    //     </div>
                    // </Container><br/><br/>
                }
                <Container>
                    <div className="row justify-content-lg-center">
                        <div className="col-xs-12 col-lg-2 mb-4">
                            <h5><b><a href={requestDomain}>Job Seekers</a></b></h5>
                            <ul className="list-unstyled">
                                <li><a href={requestDomain + `/results`}>Search for jobs</a></li>
                                <li><a href={requestDomain + `/browseCompany`}>Browse Jobs by Company</a></li>
                                <li><a href={requestDomain + `/browseLocation`}>Browse Jobs by Location</a></li>
                                <li><a href={requestDomain + `/results/?reg=y`} target="_blank" rel="noopener noreferrer">Create an account</a></li>
                                <li><a href="https://upward.freshdesk.com/support/solutions/43000357847" target="_blank" rel="noopener noreferrer">Job Seeker FAQs</a></li>
                                <li><a href="/?signin=y">Job Seeker sign in</a></li>
                            </ul>
                        </div>
                        <div className="col-xs-12 col-lg-2 mb-4">
                            <h5><a href={netDomain + "/employers"}><b>Employers</b></a></h5>
                            <ul className="list-unstyled">
                                <li><a href="https://hire.jobcase.com/purchase/post-now">Post a job</a></li>
                                <li><a href={netDomain + "/employers#postBoards"}>Features</a></li>
                                <li><a href={netDomain + "/employers?pricing=y"}>Pricing</a></li>
                                <li><a href="https://upward.freshdesk.com/support/solutions/43000155033" target="_blank" rel="noopener noreferrer">Employer FAQs</a></li>
                                <li><a href={netDomain + "/enterprise"}>Enterprise</a></li>
                                <li><a href={netDomain + "/employers?signin=y"}>Employer sign in</a></li>
                            </ul>
                        </div>
                        <div className="col-xs-12 col-lg-4 mb-4">
                            <h5><b>Jobcase by industry</b></h5>
                            <Row>
                                <Col xs="12" lg="6">
                                    <ul className="list-unstyled" style={{ "margin": 0 }}>
                                        {footerVerticals.slice(0, 6).map((item, index) => (
                                            <li className={pathname === item.link ? " active " : ""} key={index}><Link to={item.link} >{item.title}</Link></li>
                                        ))}
                                    </ul>
                                </Col>
                                <Col xs="12" lg="6">
                                    <ul className="list-unstyled">
                                        {footerVerticals.slice(6).map((item, index) => (
                                            <li className={pathname === item.link ? " active " : ""} key={index}><Link to={item.link} >{item.title}</Link></li>
                                        ))}
                                    </ul>
                                </Col>
                            </Row>
                        </div>
                        <div className="col-xs-12 col-lg-2 mb-4">
                            <h5><b><a href={netDomain + "/partners"}>Partners</a></b></h5>
                            <ul className="list-unstyled">
                                <li><a href={netDomain + "/partners#ad_solutions"}>Advertisers</a></li>
                                <li><a href={netDomain + "/partners#pub-id"}>Publishers</a></li>
                                <li><a href={netDomain + "/partners#ats-id"}>ATS partners</a></li>
                                <li><a href={netDomain + "/partners#prog-id"}>Programmatic partners</a></li>
                                <li><a href="https://www.jobcase.com/employer-onboarding-partners?utm_source=UpwardPartner">Create a partner account</a></li>
                                <li><a href={netDomain + "/t/auth/login/jobcase?utm_source=UpwardPartner"}>Partner sign in</a></li>
                            </ul>
                        </div>
                        <div className="col-xs-12 col-lg-2 mb-4">
                            <h5><b><Link to="/mission">Company</Link></b></h5>
                            <ul className="list-unstyled">
                                <li className={pathname === "/mission" ? " active " : ""} ><a href="https://www.jobcase.com/about-us/">Our mission</a></li>
                                <li><a href="https://www.jobcase.com/about-us/">Leadership</a></li>
                                <li><a href="https://www.jobcase.com/about-us/">Contact us</a></li>
                                <li>{(new Date("2023-2-1") >= new Date()) && (new Date() >= new Date("2022-12-18")) ? <a href="/?privacy-policy">Privacy policy - UPDATED</a> : <a href="/?privacy-policy">Privacy policy</a>}</li>
                                <li className="employer-footer" style={{display: "inline-block"}}>
                                    <a onClick={this.props.openCmpDrawer}>
                                        <img src={privacyChoicesIcon} className="privacy-choices-icon" />
                                        <span className="your-privacy-choices">Your Privacy Choices</span>
                                    </a>
                                </li>
                                <li>{(new Date("2023-2-1") >= new Date()) && (new Date() >= new Date("2022-12-18")) ? <a href="/?terms-and-conditions">Terms of use - UPDATED</a> : <a href="/?terms-and-conditions">Terms of use</a>}</li>
                                <li><a href="/?dmca">DMCA notice</a></li>
                                <li>{(window.location.href.indexOf('.careers') > -1) ? <a href="?unsub">Unsubscribe</a> : <a href="https://www.jobcase.com/about-us/support-at-jobcase/">Unsubscribe</a>}</li>
                                <li><a href="/?privacy-policy#section-10">California privacy notice</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="mt-4">
                        <div className="text-center">
                            <h5><a href="https://www.jobcase.com/hire/contact-us/" target="_blank" rel="noopener noreferrer">If you'd like to know more about how Jobcase can help your hiring, <span style={{fontWeight:"bold",textDecoration:"underline"}}>contact us here.</span></a></h5>
                        </div>
                    </div>
                </Container>
            </footer>
        )
    }

}

HomeFooter.contextType = ThemeContext;

export default withRouter(HomeFooter);
