/*
  Company Auto Suggest
*/
import renderAutoSuggestWithOptions from './AutoSuggestHOC';

import { getLocationSuggestions } from './AutoSuggestService';

const JobLocationAutoSuggest = renderAutoSuggestWithOptions(
   getLocationSuggestions
);

JobLocationAutoSuggest.defaultProps = {
   selected: {
      id: null,
      value: ''
   },
   forceSelection: false,
   placeholder: "Location (City or Zip)"
}

export default JobLocationAutoSuggest;
