/*

    Dumb Component - just to display the Single Job Item.

*/
import React from "react";

import CompanyLogo from "corp-site/employer/Abstracts/CompanyLogo";


const RecentJobItem = ({ job, linkComponent : LinkComponent }) => {
    return (
        <div key={job.id} className="recent_job">
            <header> JOB POSTED <span className="recent_time">{job.formattedPublishedDate}</span></header>
            <section className="recent_has_image">
                <div className="recent_item_image d-flex">
                    { !!job.companyLogo &&     
                        <img className="align-self-center" src={job.companyLogo} alt={job.company} />
                    }
                    {!!!job.companyLogo && 
                        <CompanyLogo className="align-self-center" />
                    }
                </div>
                <h4 className="text-truncate">{job.title}</h4>
                <h5 className="text-truncate">{job.company}</h5>
                <h6>{job.location}</h6>
            </section>
            {LinkComponent &&
                <div className="text-right recent_link"><LinkComponent /></div>
            }
        </div>
    )

}


export default RecentJobItem;