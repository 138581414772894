/*


  Implementation by Taran Sahota
  Dated : 10/08/2019





    Global Actions Drop down used inside the tables;


*/

import React from "react";
import { DropdownToggle } from "reactstrap";


class UpwardActions extends React.Component{
    render(){
        const { children, className, size, ...rest } = this.props;
        return (
            <DropdownToggle className={ (className ? className : "") + " upward_actions_button btn-light dropdown-toggle text-left" } size={size || ""} {...rest} >
              { children } <span className="downArrow ml-2"><i className="fas fa-chevron-down"></i></span>
            </DropdownToggle>
        )
    }
}

export default UpwardActions;