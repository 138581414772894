/*


    Created by Taran Sahota


    Export all the UI elements as on package
    so they can be imported from 1 import as required



*/

/// special buttons import
import UpwardLoadingButton from "./Buttons/LoadingButton";
import UpwardActions from "./Buttons/UpwardActions";

// formatter
import CurrencyFormatter from "./formatter/CurrencyFormatter";
import DateFormatter from "./formatter/DateFormatter";
import PhoneNumberFormatter from "./formatter/PhoneNumberFormatter";
import NumberFormatter from "./formatter/NumberFormatter";
import CandidateJobTimeFormatter from "./formatter/CandidateJobTimeFormatter";


// form elements import
import LoadingInput from "./form-elements/LoadingInput";
import UpwardReduxCheckbox from "./form-elements/UpwardReduxCheckbox";
import UpwardReduxInput, { UpwardAutoSuggestRedux } from "./form-elements/UpwardReduxInput";
import UpwardReduxSelect, { UpwardReduxSelectWithChild, UpwardCreateableReduxSelect } from "./form-elements/UpwardReduxSelect";
import UpwardTextEditor, { UpwardReduxTextEditor } from "./form-elements/UpwardTextEditor";
import StateDropdown from "./form-elements/StateDropdown";
import reactSelectWithOptions from "./form-elements/ReactSelectHOC";
import UpwardReduxInputWithWarning from "./form-elements/UpwardReduxInputWithWarning";
import ReduxStateSelect from "./form-elements/ReduxStateSelect";
import FieldFileInput from "./form-elements/FieldFileInput";

// autofill
import ReduxZipInputAutofill from "./form-elements/ReduxZipInputAutofill";

// Material Redux Integration Fields
import {
    MaterialCheckbox,
    MaterialFromHelper,
    MaterialSelectField,
    MaterialTextField,
    GenerateMaterialSelectOptions
} from "./form-elements/MaterialInputs";

// Loaders
import FileLoader from "./loaders/FieldLoader";
import UpwardButtonLoader from './loaders/UpwardButtonLoader';
import UpwardLoader from "./loaders/UpwardLoader";
import UpwardAdvancedLoader from './loaders/UpwardAdvancedLoader';


// autosuggest
import InputAutosuggest from "./form-elements/autosuggest/InputAutosuggest";
import LocationInputAutosuggest from "./form-elements/autosuggest/LocationInputAutosuggest";
import ReduxAddressInputAutosuggest from "./form-elements/autosuggest/ReduxAddressInputAutosuggest";
import ReduxCityInputAutosuggest from "./form-elements/autosuggest/ReduxCityInputAutosuggest";
import ReduxInputAutosuggest from "./form-elements/autosuggest/ReduxInputAutosuggest";


import JobKeywordAutoSuggest from './AutoSuggest/JobKeywordAutoSuggest';
import JobLocationAutoSuggest from './AutoSuggest/JobLocationAutoSuggest';


export {
    PhoneNumberFormatter,
    CurrencyFormatter,
    DateFormatter,
    NumberFormatter,
    UpwardActions,
    UpwardLoadingButton,
    FileLoader,
    UpwardButtonLoader,
    UpwardLoader,
    UpwardAdvancedLoader,
    CandidateJobTimeFormatter,
    LoadingInput,
    UpwardReduxCheckbox,
    UpwardAutoSuggestRedux,
    UpwardReduxInput,
    UpwardReduxInputWithWarning,
    UpwardReduxSelect,
    UpwardReduxSelectWithChild,
    UpwardCreateableReduxSelect,
    UpwardTextEditor,
    UpwardReduxTextEditor,
    StateDropdown,
    reactSelectWithOptions,
    MaterialCheckbox,
    MaterialFromHelper,
    MaterialSelectField,
    MaterialTextField,
    GenerateMaterialSelectOptions,
    ReduxStateSelect,
    ReduxZipInputAutofill,
    InputAutosuggest,
    LocationInputAutosuggest,
    ReduxAddressInputAutosuggest,
    ReduxCityInputAutosuggest,
    ReduxInputAutosuggest,
    FieldFileInput,
    JobKeywordAutoSuggest,
    JobLocationAutoSuggest
}
