import React, { Fragment } from "react";
import { ModalBody, Row, Col, Container } from "reactstrap";

import ContactForm from "corp-site/components/ContactForm";

const NonUpwardContact = (props) => {
  return (
    <React.Fragment>
      <ModalBody>
        <React.Fragment>
          <div className="clearfix mainSection">
            <div className="container">
              <div className="clearfix contentWrapper">
                <div className="mainContent" id="mainContentHolder">
                    <section>
                      <Container>
                        <Col
                          xs="12"
                          lg={{ size: 8, offset: 2 }}
                          className="text-center"
                        >
                          <h3>Contact us</h3>
                          <p>
                            We want to hear from you! Drop us a line using the
                            form below.
                          </p>
                          <ContactForm onClose={props.onClose}/>
                          <Row>
                            <Col xs="12" className="mt-5 mb-5">
                              <div className="companyInfo">
                                {props.address}
                                <br /><br />
                                <a href={"mailto:info@"+props.siteName+".com"}>info@{props.siteName}.com</a><br />
                                <a href={"mailto:advertiser@"+props.siteName+".com"}>advertiser@{props.siteName}.com</a><br />
                                <a href={"mailto:developer@"+props.siteName+".com"}>developer@{props.siteName}.com</a>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Container>
                    </section>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      </ModalBody>
    </React.Fragment>
  );
};

export default NonUpwardContact;
