import React from "react";
import {
   cityInput$,
   stateInput$,
   zipInput$
} from "ui/util/rxjs/observables";
import {Input} from "reactstrap";
import "./index.css";

class ReduxAddressInputAutosuggest extends React.Component {
   constructor(props) {
      super(props);
      this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
   }

   componentDidMount() {
      if (window.google) {
         this.autocomplete = new window.google.maps.places.Autocomplete(document.getElementById('LocationAutoComplete'), {
            types: ['address'],
            componentRestrictions: {country: 'us'}
         });
         this.autocomplete.addListener("place_changed", this.handlePlaceSelect);
      }
   }

   handlePlaceSelect() {
      const place = this.autocomplete ? this.autocomplete.getPlace() : null;
      const addressComponents = place ? place.address_components : null;

      const address1 = place && place.name
         ? place.name
         : "";

      this.props.input.onChange(address1);

      // Autofill the city, state, and zip fields
      let city = "", state = "", zip = "";
      for (const {long_name: longName, short_name: shortName, types} of addressComponents) {
         const type = types && types[0] ? types[0] : "";
         if (type === "locality") {
            city = longName;
         } else if (type === "administrative_area_level_1") {
            state = shortName;
         } else if (type === "postal_code") {
            zip = longName;
         }
      }

      cityInput$.next(city);
      stateInput$.next(state);
      zipInput$.next(zip);
   }

   render() {

      const {input, meta: {touched, error} = {touched: null, error: null, warning: null}, onSelectAddress, ...custom} = this.props;
      return (
         <Input
            id="LocationAutoComplete"
            ref="input"
            type="text"
            value={input.value}
            {...input} {...custom}
         />
      )
   }
}
export default ReduxAddressInputAutosuggest;
