import React, { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import queryString from "query-string";
import axios from "axios";
import '../../constants';

import HeaderImagesComponent from "corp-site/components/HeaderImagesComponent";

import { ThemeContext } from "../../corp-site/employer/context/ThemeContext";
import HomeHeader from "../../corp-site/employer/components/Header";
import Header2 from "../../corp-site/employer/components/NonUpwardHeader";
import HomeFooter from "../../corp-site/employer/components/Footer";
import Footer2 from "../../corp-site/employer/components/NonUpwardFooter";
import HomepageMain from "./homepage/components/HomepageMain";
import Homepage2 from "./homepage/components/NonUpwardHomepage";
import CountDisplay from "../../corp-site/employer/employer/components/CountDisplay";
import GetAlerted from "./homepage/components/GetAlerted";
import GetMatched from "./homepage/components/GetMatched";
import Subscribe from "./homepage/components/Subscribe";
import MoreDetails from "./homepage/components/MoreDetails";
import FindBetter from "./homepage/components/FindBetter";
import CareerOptions from "./homepage/components/CareerOptions";
import CareerOpportunities from "./homepage/components/CareerOpportunities";
import ReachCandidates from "./homepage/components/ReachCandidates";
import {openCmpDrawer} from "../../components/OsanoSupport";
import Banner from "../../components/BottomBanner";

import SeekerSignIn from "./homepage/signin";
import SiteLanguage from "../terms/index";

import JTBG from "../../assets/img/non-upward-logos/jt_bg_1.jpg";
import AAJBG from "../../assets/img/non-upward-logos/aaj_bg_1.jpg";
import JTHBG from "../../assets/img/non-upward-logos/jth_bg_1.jpg";

import JTFav from "../../assets/img/non-upward-logos/jt_favicon.ico";
import AAJFav from "../../assets/img/non-upward-logos/aaj_favicon.ico";
import JTHFav from "../../assets/img/non-upward-logos/jth_favicon.ico";
import faviconBlue from "assets/img/homepage/favicon/favicon_blue.ico";

const HomePage = ( props ) => {

    const [ showSeekerSignin , setshowSeekerSignin ] = useState( false );
    const [ showLanguage , setShowLanguage ] = useState( false );
    const [ domainAbbr , setDomainAbbr ] = useState("");
    const [ domainName , setDomainName ] = useState("");
    const [ isSite2 , setIsSite2 ] = useState(false);
    const [ favPath, setFavPath ] = useState("");
    const [ backgroundURL, setBackgroundURL ] = useState("");
    
    const qs = queryString.parse(props.location.search);

    const isUpward = window.location.href.indexOf('upward') > -1;

    useEffect(() => {
      axios
        .get(global.API_URL + global.JOB_DIR + "/api/siteValues.jsp")
        .then(response => {
          setDomainAbbr(response.data.siteAbbr);
          setDomainName(response.data.siteName);
          setIsSite2(response.data.isSite2Site);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
    },[]); 

    useEffect(()=>{
      if(window.location.host.indexOf("jobtomic") > -1){
        setFavPath(JTFav);
      } else if (window.location.host.indexOf("acquireajob") > -1){
        setFavPath(AAJFav);
      } else if (window.location.host.indexOf("jobtorch") > -1){
        setFavPath(JTHFav);
      } else if (!isUpward && isSite2){
        setFavPath("/images/"+domainAbbr+"/favicon.ico");
      } else if(isUpward && window.location.href.indexOf("/employers") > -1){
        setFavPath("../../assets/img/upward-logos/JCFav.png");
      } else {
        setFavPath(faviconBlue);
      }
      if(window.location.host.indexOf("jobtomic") > -1){
        setBackgroundURL(JTBG);
      } else if (window.location.host.indexOf("acquireajob") > -1){
        setBackgroundURL(AAJBG);
      } else if (window.location.host.indexOf("jobtorch") > -1){
        setBackgroundURL(JTHBG);
      } else if (isSite2){
        setBackgroundURL("/images/"+domainAbbr+"/bg_1.jpg");
      } else {
        setBackgroundURL("/images/homepage/bg_1.jpg");
      }
    },[isSite2]);

    useEffect(()=>{
      if (window.fcWidget) {
        window.fcWidget.destroy();
      }
      if (qs && qs.signin && qs.signin === "y") {
        setshowSeekerSignin(true);
      }
      if (window.location.href.indexOf("?contact") > -1 || window.location.href.indexOf("?privacy-policy") > -1 || window.location.href.indexOf("?personal-information") > -1 || window.location.href.indexOf("?terms-and-conditions") > -1 || window.location.href.indexOf("?dmca") > -1 || window.location.href.indexOf("?unsub") > -1 || window.location.href.indexOf("?california-privacy-notice") > -1) {
        setShowLanguage(true);
      }
      return () => {
        setShowLanguage( false );
        setshowSeekerSignin( false );
      }
    },[qs]);

        return (
          <Fragment>
            {isUpward && <ThemeContext.Provider value="blue">
              <Helmet>
                <title>Job Search | Upward - Find a Job. Move Upward.</title>
                <meta name="keywords" content="upward jobs board" />
                <link
                  rel="icon"
                  type="image/png"
                  href={favPath}
                  sizes="16x16"
                />
              </Helmet>
              <HomeHeader inverted></HomeHeader>
              <HomepageMain></HomepageMain>
              <CountDisplay></CountDisplay>
              <GetMatched></GetMatched>
              <FindBetter></FindBetter>
              <MoreDetails></MoreDetails>
              <GetAlerted></GetAlerted>
              <Subscribe></Subscribe>
              <HomeFooter openCmpDrawer={openCmpDrawer}></HomeFooter>
              <Banner />
              {showSeekerSignin && <SeekerSignIn />}
              {showLanguage && <SiteLanguage openCmpDrawer={openCmpDrawer}/>}
            </ThemeContext.Provider>}
            {!isUpward && <ThemeContext.Provider value="blue">
            <Helmet>
              <title>{domainName} | Your search for a better job starts here. </title>
              <meta name="keywords" content={{domainName} + " jobs board"} />
              <link
                rel="icon"
                type="image/png"
                href={favPath}
                sizes="16x16"
              />
            </Helmet>
            <Header2 inverted isSite2={isSite2} abbr={domainAbbr}></Header2>
            <Homepage2 abbr={domainAbbr} backgroundURL={backgroundURL}></Homepage2>
            <CareerOptions></CareerOptions>
            <CareerOpportunities></CareerOpportunities>
            <ReachCandidates></ReachCandidates>
            <Footer2 jobDir={global.JOB_DIR} openCmpDrawer={openCmpDrawer}></Footer2>
            <Banner />
            {showSeekerSignin && <SeekerSignIn />}
            {showLanguage && <SiteLanguage openCmpDrawer={openCmpDrawer}/>}
          </ThemeContext.Provider>}
          </Fragment>
        );

}


export default HomePage;