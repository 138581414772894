/*

    Script returns list to display after random interval

*/
import { useEffect, useState } from "react";

const randomTimeoutGenerator = ( interval : number ) => {
    if( interval ){
        return ( Math.random() * interval + 3 ) * 1000
    } else {
        return ( Math.random() * 15 + 5 ) * 1000;
    }
}


export default function RandomIntervalList ( 
    defaultList : [], listSize : number = 10, interval : number = 10, truncate : boolean = false
) : Array<any> {
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [displayList, setDisplayList] = useState<Array<any>>([]);
    let customTimeout : any;

    useEffect(() => {
        if (currentIndex <= defaultList.length - 1) {
            setDisplayList(
                (prevList) => (
                    [defaultList[currentIndex], ...( truncate ? prevList.slice(0, listSize - 1 ) : prevList ) ]
                )
            );
            customTimeout = setTimeout(() => {
                setCurrentIndex(
                    (prevIndex) => prevIndex + 1
                );
            }, randomTimeoutGenerator( interval ) );
        } else {
            clearTimeout(customTimeout);
        }
        return () => {
            if (customTimeout) {
                clearTimeout(customTimeout);
            }
        }
    }, [currentIndex]);

    useEffect(() => {
        setDisplayList([...defaultList.slice(0, listSize ).reverse()]);
        if (customTimeout) {
            clearTimeout(customTimeout);
        }
        customTimeout = setTimeout(() => {
            setCurrentIndex(listSize);
        }, 3000 );
        return () => {
            if (customTimeout) {
                clearTimeout(customTimeout);
            }
        }
    }, [defaultList]);

    return displayList;
}