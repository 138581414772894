/*
  General AutoSuggestInput which shows loading input.
  Shows some data is being fetched.
*/

import React from 'react';

import LoadingInput from 'ui/form-elements/LoadingInput';

export default class AutoSuggestInputComponent extends React.Component {
   render() {
      const { isLoading, ...rest } = this.props.inputProps;
      return (
         <LoadingInput inputProps={rest} isLoading={isLoading} />
      );
   }
}
