/*

  Implementated by Amit Sharma | Dated : 12/17/2019

  Modified by 

  ⁋__(*_*)__¶ Taran Sahota

*/

import React, { Component } from 'react';

export default class FieldFileInput extends Component {
  constructor(props) {
    super(props)
    this.onChange = this.onChange.bind(this)
  }

  onChange(e) {
    const { input: { onChange } } = this.props
    onChange(e.target.files[0])
  }

  render() {
    const { input : { value }, label, required, meta: { touched, error }, id, name, className, ...custom } = this.props  //whatever props you send to the component from redux-form Field
    return (
      <div className={"custom-file" }>
        <input type="file" className={ className + " custom-file-input "  + ( error && touched ? " is-invalid " : "")} 
          id={id} 
          name={name} 
          onChange={this.onChange} 
          {...custom} />
        <label className="custom-file-label" htmlFor={id}>{( value && value.name ? value.name : (label ? label : "Choose File"))}</label>
      </div>
    )
  }
}