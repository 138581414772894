import axios from "axios";
import { of, from } from "rxjs";
import { map, catchError } from "rxjs/operators";
import '../../constants';

export const getKeywordSuggestions = (keyword) => {
    return from(
        axios.get(global.API_URL + global.JOB_DIR + '/autocomplete2.jsp?type=title&query=' + keyword)
        //https://stage.upward.careers/autocomplete.jsp?type=title&query=Software%20Engineer%20
    ).pipe(
        map(response => {
            if (response && response.data) {
                return [...response.data.results];
            } else
                return [];
        }),
        catchError((err) => { console.log(err); return of([]) })
    );
}

export const getLocationSuggestions = (keyword) => {
    return from(
        axios.get(global.API_URL + global.JOB_DIR + '/autocomplete2.jsp?type=location&query=' + keyword)
        //https://stage.upward.careers/autocomplete.jsp?type=title&query=Software%20Engineer%20
    ).pipe(
        map(response => {
            if (response && response.data) {
                return [...response.data.results];
            } else
                return [];
        }),
        catchError((err) => { console.log(err); return of([]) })
    );
}