/*


  Modified by Taran Sahota
  Dated : 9/19/2019


  Added handling for employer routes.
  have to be revisited to properly layout the routes
  for the employer app.

*/

import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import homePageRoutes from "./index";

import {UpwardLoader} from 'ui'
import HomePageLayout from "../layouts/HomePageLayout";
import AuthLayout from "../layouts/Auth";
import Page404 from "../pages/auth/Page404";

import ScrollToTop from "../components/ScrollToTop";

const childRoutes = (Layout, routes) =>
  routes.map(({ children, path, component: Component, hasSubRoutes }, index) =>
    children ? (
      // Route item with children
      children.map(({ path, component: Component }, index) => (
        <Route
          key={index}
          path={path}
          exact
          render={props => (
            <Layout>
              <Component {...props} />
            </Layout>
          )}
        />
      ))
    ) : (
        <Route
          key={index}
          path={path}
          {...(hasSubRoutes ? {} : { exact: true })}
          render={props => (
            <Layout>
              <Component {...props} />
            </Layout>
          )}
        />
      )
  );

const Routes = () => (
  <Router>
    <ScrollToTop>
      <React.Suspense
        fallback={
          <div className="clearfix align-middle pb-3 my-auto h-100 w-100">
            <UpwardLoader size="120" />
          </div>
        }
      >
        <Switch>
          {childRoutes(HomePageLayout, homePageRoutes)}
          <Route
            render={() => (
              <AuthLayout>
                <Page404 />
              </AuthLayout>
            )}
          />
        </Switch>
      </React.Suspense>
    </ScrollToTop>
  </Router>
);

export default Routes;
