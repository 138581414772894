import React, {useCallback} from "react";
import InputAutosuggest from "./InputAutosuggest";

const ReduxInputAutosuggest = ({ input, label, meta : { touched, error }, getSuggestions, ...custom }) => {
   const handleSelect =  useCallback((suggestion)=> {
      input.onChange(suggestion.value)
   }, [input.onChange]);

   return (<InputAutosuggest
      {...custom}
      {...input}
      getSuggestions={getSuggestions}
      handleSelect={
         custom.handleSelect
            ? custom.handleSelect
            : handleSelect
      }
      handleChange={
         useCallback(
            (event, {newValue}) => {
               event.preventDefault();
               input.onChange(newValue);
            }, [input.onChange])
      }
      className={`${touched && error ? " is-invalid " : ""} ${custom.className}`}
   />);
};

export default ReduxInputAutosuggest;