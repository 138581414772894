import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { ChevronDown } from "react-feather";
import { ThemeContext } from "../context/ThemeContext";

function renderHeaderDropdown(
    dropDownTitle,
    dropDownOptions
) {

    return class extends Component {

        constructor(props) {
            super(props);
            this.state = {
                dropdownOpen: false
            }
        }

        toggle = () => {
            this.setState({
                dropdownOpen: !this.state.dropdownOpen
            })
        }

        render() {
            return (
                <ThemeContext.Consumer >
                    {color => (
                        < Dropdown inNavbar className={"upward_header_dropdown upward_header_dropdown_" + color + (this.props.active ? " active " : "") + (this.props.inverted ? " dropdown_inverted " : "") + (this.props.classNameDropdown ? (" " + this.props.classNameDropdown) : "")} isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                            <DropdownToggle {...(this.props.btnSize ? { size: this.props.btnSize } : { size: "lg" })} {...(this.props.className ? { "className": this.props.className } : { "color": "dark" })}>
                                {this.props.children ? this.props.children : <Fragment><span>{dropDownTitle}</span> <ChevronDown size="18" /></Fragment>}
                            </DropdownToggle>
                            <DropdownMenu  {...(this.props.rightAlign ? { "right": true } : {})}>
                                {
                                    dropDownOptions && dropDownOptions.length > 0 &&
                                    <Fragment>
                                        {dropDownOptions.map((item, index) => (
                                            <DropdownItem key={index} {...(item.tag ? { "tag": item.tag } : { "tag": Link })} href={item.externalhref || item.reghref} target={item.externalhref ? "_blank" : ""} href={item.href || item.link} to={item.href || item.link}>{item.title}</DropdownItem>
                                        ))}
                                    </Fragment>
                                }
                            </DropdownMenu>
                        </Dropdown>
                    )}
                </ThemeContext.Consumer>
            )
        }
    }
}

export default renderHeaderDropdown;