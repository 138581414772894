import {debounceTime, distinctUntilChanged, switchMap} from "rxjs/operators";
import {of} from "rxjs";

const AUTO_SUGGEST_DEBOUNCE_TIME = 250;

/*

   ARGUMENT:
      getSuggestions( <input> )
         <input> is a string

         RETURNS a list of options of type Object
            with **required** properties:
               label
               value


               EX:
                  getSuggestions( ... ) returns the following
                     [
                        {
                           label: "cow",
                           value: "moo",
                        }, {
                           label: "lion",
                           value: "rawr",
                        }
                     ]
 */
const autoSuggest = (getSuggestions) => (source$) =>
   source$.pipe(
      debounceTime(AUTO_SUGGEST_DEBOUNCE_TIME),
      distinctUntilChanged(),
      switchMap((input) => {
            return input
               ? getSuggestions(input)
               : of([]);
         }
      )
   );


export {
   autoSuggest
}