import axios from "axios";
import { from, of } from "rxjs";
import { map, catchError, first } from 'rxjs/operators';

const BASE_URL = "/api/profile/";
const DEFAULT_FAILURE_MESSAGE = { "response": "failure", "message": "" }; // default failure message.

/* Implementation By: Amit Sharma | Method to post request to register New Employer */
export const getJobSeekerAlerts = () => {
    return from(
        axios.get(global.API_URL + global.JOB_DIR + BASE_URL + 'getAlerts.jsp')
    ).pipe(
        first(),
        map(response => {
            if (response && response.data) {
                return response.data;
            } else
                return { ...DEFAULT_FAILURE_MESSAGE, "message": "failed to fetch" };
        }),
        catchError((err) => { console.log(err); return of({ ...DEFAULT_FAILURE_MESSAGE, "message": "failed to fetch" }) })
    );
};


export const addJobSeekerAlert = (alert) => {
    if (!alert.alertId) {
        return of({ ...DEFAULT_FAILURE_MESSAGE, "message": "Missing alert id." })
    }
    let requestParams = {};
    requestParams.alertId = alert.alertId;
    requestParams.serviceType = "AJ";
    requestParams.newJob = alert.job_alert;
    requestParams.location = alert.location;
    return from(
        axios.get(global.API_URL + global.JOB_DIR + BASE_URL + 'keywordUpdate.jsp', { params: requestParams })
    ).pipe(
        first(),
        map(response => {
            if (response && response.data) {
                return response.data;
            } else
                return { ...DEFAULT_FAILURE_MESSAGE, "message": "Add keyword failure" };
        }),
        catchError((err) => { console.log(err); return of({ ...DEFAULT_FAILURE_MESSAGE, "message": "Add keyword failure" }) })
    );
}


export const updateJobSeekerAlert = (alert) => {
    if (!alert.alertId) {
        return of({ ...DEFAULT_FAILURE_MESSAGE, "message": "Missing alert id." })
    }
    let requestParams = {};
    requestParams.alertId = alert.alertId;
    requestParams.serviceType = "UJ";
    requestParams.oldKeyword = alert.oldKeyword;
    requestParams.newKeyword = alert.newKeyword;
    return from(
        axios.get(global.API_URL + global.JOB_DIR + BASE_URL + 'keywordUpdate.jsp', { params: requestParams })
    ).pipe(
        first(),
        map(response => {
            if (response && response.data) {
                return response.data;
            } else
                return { ...DEFAULT_FAILURE_MESSAGE, "message": "Update keyword failure" };
        }),
        catchError((err) => { console.log(err); return of({ ...DEFAULT_FAILURE_MESSAGE, "message": "Update keyword failure" }) })
    );
}



export const deleteJobSeekerAlert = (alert) => {
    if (!alert.alertId) {
        return of({ ...DEFAULT_FAILURE_MESSAGE, "message": "Missing alert id." })
    }
    let requestParams = {};
    requestParams.alertId = alert.alertId;
    requestParams.serviceType = "DJ";
    requestParams.keyword = alert.keyword;
    return from(
        axios.get(global.API_URL + global.JOB_DIR + BASE_URL + 'keywordUpdate.jsp', { params: requestParams })
    ).pipe(
        first(),
        map(response => {
            if (response && response.data) {
                return response.data;
            } else
                return { ...DEFAULT_FAILURE_MESSAGE, "message": "Delete keyword failure" };
        }),
        catchError((err) => { console.log(err); return of({ ...DEFAULT_FAILURE_MESSAGE, "message": "Delete keyword failure" }) })
    );
}
