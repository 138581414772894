import React, { useState, useEffect } from "react";
import { Container, Col } from "reactstrap";
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import randomIntervalList from "ui/hooks/randomIntervalList";

import { getRecentCandidates } from "../services";

import RecentCandidateItem from "./RecentCandidateItem";


const RecentCandidates = ({ vertical, className, maxCount, showHeader, linkComponent }) => {
    const [candidates, setCandidates] = useState([]);
    const displayCandidates = randomIntervalList(candidates, maxCount ? maxCount : 4, 12, true);

    useEffect(() => {
        const candidateSubscription = getRecentCandidates(vertical ? vertical.catIds : [], 20 ).subscribe(
            (data) => {
                setCandidates(data.candidates);
            }
        );
        return () => {
            candidateSubscription.unsubscribe();
        }
    }, []);


    return (
        <section className={"upward_vertical_recent_items recent_candidate_items " + (className ? className : "")}>
            <Container>
                {showHeader && <h3 className="text-center">Recent {vertical.name} candidates</h3>}
                <TransitionGroup className="row d-flex justify-content-center align-items-center">
                    {displayCandidates.map((candidate) => (
                        <CSSTransition
                            key={candidate.userId}
                            timeout={1100}
                            classNames="recent_item"
                        ><Col className="recent_item_parent" {...(maxCount > 2 ? { "md": 6, "lg": 3 } : {})}>
                            <div className="recent_item">
                                <RecentCandidateItem candidate={candidate} {...(linkComponent ? { linkComponent } : {} )} />
                            </div>
                        </Col>
                        </CSSTransition>
                    ))}
                </TransitionGroup>
            </Container>
        </section>
    )
}

export default RecentCandidates;