/*


  Implementation by Taran Sahota
  Dated : 9/19/2019

  Global Date formatter for the app.


  returns in format : MM/DD/YYYY

  PROPS

    timeStamp               (required)

    dateType
        "MM/dd/YYYY"        (default)
            EX:
                5/13/1995
                12/25/1988

        "MMM YYYY"
            EX:
                May 1995
                December 1988


        isTodayString       (default = false)
                            if true, today's date will be displayed as "Today" instead of the specified formatting

    showTime
        false               (default)
                            Does not add anything after the date

        true                Adds "at HH:mm am/pm" after the date

*/


import React, {Component, Fragment} from "react";

const numericDateOption = {year: 'numeric', month: 'numeric', day: 'numeric'};
const longMonthNumericYearOption = {year: 'numeric', month: 'long'};
const timeOptions = {hour: 'numeric', minute: 'numeric'};


class DateFormatter extends Component {


    render() {

        const {dateType, showTime, timeStamp, isTodayString} = this.props;
        const t = new Date(timeStamp);

        const dateInput = (new Date(timeStamp)).setHours(0,0,0,0);
        const dateNull = (new Date(null)).setHours(0,0,0,0);

        if (dateNull === dateInput) return (<Fragment></Fragment>); //  handle null timestamp

        if (isTodayString){
            const today = (new Date()).setHours(0,0,0,0);
            
            if (today === dateInput) return (<>Today</>);
            
        }


        let options = {};

        if (dateType === null || dateType === 'MM/dd/YYYY')
            options = {...options, ...numericDateOption};
        else if (dateType === 'MMM YYYY')
            options = {...options, ...longMonthNumericYearOption};


        if (showTime)
            options = {...options, ...timeOptions};


        let formatted = t.toLocaleDateString('en-US', options);

        return (
            <Fragment>
                {formatted}
            </Fragment>
        )
    }
}


export default DateFormatter;