/*

  Implementation by Bryan Perez
  Dated : 10/01/2019


  PROPS:
    placeHolder (optional)
    onChange (optional) listener that gets called when there is a change

    value (optional) sets the editor's text value


  <strong>   bold tag
  <i>   italic tag
  <u>   underline
  <ul><li>
  <ol><li>

  Cannot change size of text
  Cannot add a link
  Can add an image; has to be an external link to an image

*/

import React from 'react';
import ReactQuill from "react-quill";


class UpwardTextEditor extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value : props.value ? props.value : ""
        };

        this.handleChange = this.handleChange.bind(this);
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        const {value} = this.props;

        if ((value !== prevProps.value) && value !== this.state.value){
            this.setState({value});
        }
    }

    handleChange(value) {
        this.setState({ value: value });

        const {onChange} = this.props;
        if (onChange) onChange(value);
    }

    render() {

        const {placeHolder} = this.props;
        return (
            <ReactQuill
                placeholder={placeHolder ? placeHolder : "Type something..."}
                value={this.state.value}
                onChange={this.handleChange}
                modules={quillModules}
                formats={quillFormats}
            />);
    }
}

/*
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
const quillModules= {
    toolbar: [
        ['bold', 'italic', 'underline'],
        [{'list': 'ordered'}, {'list': 'bullet'}],
        ['clean']
    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
    }
};

/*
* Quill editor formats
* See https://quilljs.com/docs/formats/
*/
const quillFormats = [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'video'
];


export const UpwardReduxTextEditor = ({input, meta:{ touched, error, warning }, className, ...rest}) => {
    return (
        <ReactQuill
          {...input}
          onChange={(newValue, delta, source) => {
            if (source === 'user') {
              input.onChange(newValue);
            }
          }}
          onBlur={(range, source, quill) => {
            input.onBlur(quill.getHTML());
          }}
          modules={quillModules}
         formats={quillFormats}
        className={(touched && error ? " is-invalid " : "") + (className ? className : "")}
         {...rest}
        />
      );
}


export default UpwardTextEditor;