/*

    Higher order component to render react select

    takes in options to render.

*/
import React from "react";
import Select from 'react-select';

function reactSelectWithOptions( options ){

    return class extends React.Component{
        constructor(props){
            super( props );
            this.state = {
                value : this.props.value
            };
            this.handleChange = this.handleChange.bind( this );
        }

        handleChange( option ){
            if( this.props.onChange ){
                this.props.onChange( option.value );
            }
        }
        
        render(){
            return (
                <Select className={"react-select-container "+ (this.props.className ? this.props.className : "")} classNamePrefix="react-select" isSearchable={false}
                value={options.find(option => option.value.toLowerCase() === this.props.value.toLowerCase())} onChange={this.handleChange} options={options} required={this.props.required}
                isDisabled={ this.props.isDisabled || false } placeholder={ this.props.placeholder || "Select..." }
                />
            )
        }
    }

}

export default reactSelectWithOptions;