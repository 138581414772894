/*


  Implementation by Taran Sahota
  Dated : 9/19/2019



    Implementation to use redux form with react-select.

    this is reusable field for the text, email, password, number


*/
import React from "react";
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';

const UpwardReduxSelect = ({ input, options, placeholder, meta:{ touched, error }, selectOption, ...custom })=> {

    if (!input.value && options && options.length === 1 && selectOption) {
        selectOption(options[0]);
    }


    return (
        <Select 
            {...input}
            value={input.value}
            onChange={ value => input.onChange( value ) }
            onBlur={()=> input.onBlur( input.value ) }
            blurInputOnSelect={false}
            options={options}
            {...custom}
            className={ "react-select-container" + (touched && error ? " is-invalid " : "") }
            classNamePrefix="react-select"
            placeholder={placeholder || "Select"}
        />
    )
};

export const UpwardReduxSelectWithChild = ({ input, meta:{ touched, error, warning }, childComponent : ChildComponent, size, ...custom })=> {
    return (
        <ChildComponent 
            {...input}
            value={input.value}
            onChange={ value => input.onChange( value ) }
            onBlur={()=> input.onBlur( input.value ) }
            blurInputOnSelect={false}
            className={ (touched && error ? " is-invalid " : "" ) + ( size ? "react-select-" + size : "" ) }
            {...custom}
        />
    )
}


export const UpwardCreateableReduxSelect = ({ input, options, placeholder, meta:{ touched, error, warning }, ...custom })=>{
    return (
        <CreatableSelect
            {...input}
            value={input.value}
            onChange={ value => input.onChange( value ) }
            onBlur={()=> input.onBlur( input.value ) }
            options={options}
            blurInputOnSelect={false}
            {...custom}
            className={ "react-select-container" + (touched && error ? " is-invalid " : "") }
            classNamePrefix="react-select"
            placeholder={placeholder || ""}
        />
    )
}


export default UpwardReduxSelect;