import React, { useState, useEffect } from "react";
import { Container, Col } from "reactstrap";
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import randomIntervalList from "ui/hooks/randomIntervalList";

import { getRecentJobs } from "../services";

import RecentJobItem from "./RecentJobItem";


const RecentJobs = ({ vertical, className, maxCount, showHeader, linkComponent }) => {
    const [serverJobs, setJobs] = useState([]);
    const displayJobs = randomIntervalList(serverJobs, maxCount ? maxCount : 4, 12, true);

    useEffect(() => {
        const jobSubscription = getRecentJobs(vertical ? vertical.catIds : []).subscribe(
            (data) => {
                setJobs(data.jobs);
            }
        );
        return () => {
            jobSubscription.unsubscribe();
        }
    }, []);


    return (
        <section className={"upward_vertical_recent_items recent_job_items " + (className ? className : "" )}>
            <Container>
                {showHeader && <h3 className="text-center">Recent {vertical.name} jobs</h3> }
                <TransitionGroup className="row d-flex justify-content-center align-items-center">
                    {displayJobs.map((job) => (
                        <CSSTransition
                            key={job.jobId}
                            timeout={100}
                            classNames="recent_item"
                        ><Col className="recent_item_parent" {...(maxCount > 2 ? { "md":6, "lg":3 } : {} )} >
                            <div className="recent_item">
                                <RecentJobItem job={job} {...(linkComponent ? { linkComponent } : {})} />
                            </div>
                        </Col>
                        </CSSTransition>
                    ))}
                </TransitionGroup>
            </Container>
        </section>
    )
}

export default RecentJobs;