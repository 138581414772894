import axios from "axios";
import { from, of } from "rxjs";
import * as qs from "query-string";
import { map, catchError } from 'rxjs/operators';
import '../../constants';

const JOB_BASE_URL = "/ectools/job";

export const getJobTitleSuggestions = (jobTitle) => (from(
      axios.get(global.API_URL + global.JOB_DIR +  `/autocomplete2.jsp?type=title&query=${jobTitle}`)
   ).pipe(
      map((res) => {
            const {status, data} = res;
            if (status !== 204 && data) {
               const {results} = data;
               return results ?
                  results.map(result => ({
                     ...result,
                     label: result.value || ""
                  }))
                  : [];
            } else {
               return [];
            }
         }
      ))
);

export const getJobTitleAutoSuggestions = (jobTitle) => {
   if (!jobTitle) {
      return of({});
   }
   let queryObj = {};
   queryObj["keywords"] = jobTitle;
   queryObj["maxSuggestions"] = 10;
   queryObj["minSuggestions"] = 5;
   return from(
      axios.get(`${JOB_BASE_URL}/titleAutoSuggest?${qs.stringify(queryObj)}`)
   ).pipe(
      map(response => { return response.data }),
      catchError((err) => {
         console.log(err); return of({ suggestions: [] });
      })
   );
};

export const getTemplateSuggestions = (queryObj) => {
   const queryString = qs.stringify(queryObj);
   return from(
      axios.get(`${JOB_BASE_URL}/getTemplateTitles?${queryString}`)
   ).pipe(
      map(response => { return response.data }),
      catchError((err) => {
         console.log(err); return of({ templates: [] });
      })
   );
};

export const getTemplateDescription = (id) => {
   if (!id) {
      return of({});
   }
   return from(
      axios.get(`${JOB_BASE_URL}/getTemplateDescription?id=${id}`)
   ).pipe(
      map(response => { return response.data }),
      catchError((err) => {
         console.log(err); return of({ template: {} })
      })
   );
};
