/*


  Implementation by Taran Sahota
  Dated : 9/19/2019




  Custom field validators to be used with redux form


  documentation  : https://redux-form.com/8.2.2/examples/fieldlevelvalidation/


  feel free to add more validators here
  so they can be used globally.





*/
import lodash from "lodash";

// validator to show that the field is required.
const required = (value) => { return value ? undefined : 'Required' }
// validator for required for the react-select.
const selectRequired = (value) => { return lodash.isObject(value) && value.value ? undefined : "Required" }

// validator for maxlength
const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less.` : undefined;

const phoneNumberLength = phoneNumber => {

  const phoneNumberStr = phoneNumber && phoneNumber.toString().replace(/\D/g, '');;


  return phoneNumberStr
    && phoneNumberStr.length <= 11
    && phoneNumberStr.length >= 10
    ? undefined
    : "e.g. \"xxx-xxx-xxxx\"";
};


// validator for minlength
const minLength = min => value =>
  value && value.trim().length >= min ? undefined : `Must be at least ${min} characters.`;

const zipLength = value => value && value.toString().length < 5 ? `Must be 5 digits.` : undefined;

// validator for a number field.
const number = value => value && isNaN(Number(value)) ? 'Must be a number' : undefined;


// validator for min value for field.
const minValue = min => value =>
  value && value < min ? `Must be at least ${min}` : undefined;


// validator for email field.
const email = (value) => {
  return (
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(value) ?
      'Invalid email address.' : undefined
  )
};

const validUrl = (value) => {
  return (
    value && !/^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[A-Za-z\u00a1-\uffff0-9]-*)*[A-Za-z\u00a1-\uffff0-9]+)(?:\.(?:[A-Za-z\u00a1-\uffff0-9]-*)*[A-Za-z\u00a1-\uffff0-9]+)*(?:\.(?:[A-Za-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value) ? "Invalid url." : undefined
  )
};

export default {
  required,
  selectRequired,
  maxLength,
  minLength,
  number,
  minValue,
  email,
  validUrl,
  phoneNumberLength,
  zipLength
}
