if (process.env['REACT_APP_ENV'] === 'dev') {
	global.API_URL = process.env['REACT_APP_API_URL'];
} else {
  global.API_URL = '/jsp';
}

export const API_URL = global.API_URL;

if (process.env['REACT_APP_ENV'] === 'dev' || window.location.host.indexOf("upward") > -1) {
  global.JOB_DIR = "/upward";
} else if (window.location.host.indexOf("acquireajob") > -1) {
  global.JOB_DIR = "/site2";
} else if (window.location.host.indexOf("jobtomic") > -1) {
  global.JOB_DIR = "/jt";
} else if (window.location.host.indexOf("jobtorch") > -1) {
  global.JOB_DIR = "/jth";
}

export const JOB_DIR = global.JOB_DIR;

if (process.env['REACT_APP_ENV'] === 'dev') {
  global.RETURN_URL = process.env['REACT_APP_API_URL'];
} else {
  global.RETURN_URL = window.location.origin + '/jsp';
} 

export const RETURN_URL = global.RETURN_URL;