import React from 'react';
import lodash from 'lodash';
import BaseAutoSuggestComponent from './BaseAutoSuggestComponent';
import { BehaviorSubject, of } from 'rxjs';
import { map, debounceTime, distinctUntilChanged, catchError, switchMap } from "rxjs/operators";

const AUTO_SUGGEST_DEBOUNCE_TIME = 250;

function renderAutoSuggestWithOptions(
   dataSource,
   renderSuggestion,
   getSuggestionValue,
   reFormatSelection
) {
   return class extends React.Component {
      constructor(props) {
         super(props);
         this.state = {
            value: props.selected.value,
            isLoading: false,
            suggestions: [],
            selectedSuggestion: props.selected ? props.selected : { id: null, value: '' }
         };
         this.stopLoading = this.stopLoading.bind(this);
         this.dataSubject$ = new BehaviorSubject("");
      }

      componentDidMount() {
         this.subscription = this.dataSubject$.pipe(
            debounceTime(AUTO_SUGGEST_DEBOUNCE_TIME),
            distinctUntilChanged(),
            switchMap((input) => {
               let strInput = "";
               if (typeof input === "object" && input.value) {
                  strInput = input.value;
               } else if (typeof input === "object" && input.name) {
                  strInput = input.name;
               } else {
                  strInput = input;
               }
               if (strInput.length > 2) {
                  return dataSource(strInput).pipe(
                     map((data) => { return data }),
                     catchError((error) => { console.log(error); return of([]) })
                  )
               } else {
                  return of([]);
               }
            })
         ).subscribe(
            (data) => {
               this.setState({
                  isLoading: false,
                  suggestions: data,
               });
            }
         );
      }

      componentWillUnmount() {
         this.subscription.unsubscribe();
      }

      onChange = (event, { newValue }) => {
         this.setState({
            value: newValue,
            selectedSuggestion: null
         }, () => {
            this.onSelection();
         });
      };

      onSelection() {
         if (this.props.onSelection) {
            let obj;
            if (reFormatSelection) {
               if (this.state.selectedSuggestion) {
                  obj = reFormatSelection(this.state.selectedSuggestion);
               } else {
                  obj = reFormatSelection(this.state.value);
               }
            } else {
               obj = {};
               obj.value = this.state.value;
               if (this.state.selectedSuggestion) {
                  obj.id = this.state.selectedSuggestion.id;
                  obj.value = this.state.selectedSuggestion.value;
               }
            }
            this.props.onSelection(obj);
         }
      }

      onSuggestionSelected = (suggestion) => {
         this.setState({
            selectedSuggestion: suggestion
         }, () => {
            this.onSelection();
         });
      }

      loadSuggestions(value) {
         const queryObj = {};
         queryObj.value = value;
         this.setState({
            isLoading: true
         }, () => {
            this.dataSubject$.next(queryObj);
         });
      }

      onSuggestionsFetchRequested = ({ value }) => {
         this.loadSuggestions(value);
      };

      stopLoading() {
         this.setState({
            isLoading: false
         });
      }

      onSuggestionsClearRequested = () => {
         this.setState({
            suggestions: []
         });
      };

      render() {
         const { suggestions, isLoading } = this.state;
         const { className, selected, placeholder } = this.props;
         let value = selected && selected.value ? selected.value : "";
         if (!lodash.isUndefined(this.props.value) && lodash.isString(this.props.value)) {
            value = this.props.value;
         }
         // Autosuggest will pass through all these props to the input.
         const inputProps = {
            placeholder: placeholder || 'Search',
            value,
            onChange: this.onChange,
            isLoading,
            onBlur: this.stopLoading,
            disabled: this.props.disabled || false,
            className: className || ''
         };

         return (
            <BaseAutoSuggestComponent
               id={this.props.id || "AutoSuggest"}
               suggestions={suggestions}
               onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
               onSuggestionsClearRequested={this.onSuggestionsClearRequested}
               onSuggestionSelected={this.onSuggestionSelected}
               inputProps={inputProps}
               {...(renderSuggestion ? { renderSuggestion } : {})}
               {...(getSuggestionValue ? { getSuggestionValue } : {})}
            />
         );
      }
   };
}


export default renderAutoSuggestWithOptions;
