/*

    Higher order component to render react select

    takes in options to render.

*/
import React from "react";
import Select from 'react-select';
import {stateObservable$} from "ui/util/rxjs/observables";

const stateOptions = [
   {"value": "al", "label": "Alabama"},
   {"value": "ak", "label": "Alaska"},
   {"value": "az", "label": "Arizona"},
   {"value": "ar", "label": "Arkansas"},
   {"value": "ca", "label": "California"},
   {"value": "co", "label": "Colorado"},
   {"value": "ct", "label": "Connecticut"},
   {"value": "de", "label": "Delaware"},
   {"value": "dc", "label": "District of Columbia"},
   {"value": "fl", "label": "Florida"},
   {"value": "gu", "label": "Guam"},
   {"value": "ga", "label": "Georgia"},
   {"value": "hi", "label": "Hawaii"},
   {"value": "id", "label": "Idaho"},
   {"value": "il", "label": "Illinois"},
   {"value": "in", "label": "Indiana"},
   {"value": "ia", "label": "Iowa"},
   {"value": "ks", "label": "Kansas"},
   {"value": "ky", "label": "Kentucky"},
   {"value": "la", "label": "Louisiana"},
   {"value": "me", "label": "Maine"},
   {"value": "md", "label": "Maryland"},
   {"value": "ma", "label": "Massachusetts"},
   {"value": "mi", "label": "Michigan"},
   {"value": "mn", "label": "Minnesota"},
   {"value": "ms", "label": "Mississippi"},
   {"value": "mo", "label": "Missouri"},
   {"value": "mt", "label": "Montana"},
   {"value": "ne", "label": "Nebraska"},
   {"value": "nv", "label": "Nevada"},
   {"value": "nh", "label": "New Hampshire"},
   {"value": "nj", "label": "New Jersey"},
   {"value": "nm", "label": "New Mexico"},
   {"value": "ny", "label": "New York"},
   {"value": "nc", "label": "North Carolina"},
   {"value": "nd", "label": "North Dakota"},
   {"value": "oh", "label": "Ohio"},
   {"value": "ok", "label": "Oklahoma"},
   {"value": "or", "label": "Oregon"},
   {"value": "pa", "label": "Pennsylvania"},
   {"value": "pr", "label": "Puerto Rico"},
   {"value": "ri", "label": "Rhode Island"},
   {"value": "sc", "label": "South Carolina"},
   {"value": "sd", "label": "South Dakota"},
   {"value": "tn", "label": "Tennessee"},
   {"value": "tx", "label": "Texas"},
   {"value": "ut", "label": "Utah"},
   {"value": "vt", "label": "Vermont"},
   {"value": "va", "label": "Virginia"},
   {"value": "vi", "label": "Virgin Islands"},
   {"value": "wa", "label": "Washington"},
   {"value": "wv", "label": "West Virginia"},
   {"value": "wi", "label": "Wisconsin"},
   {"value": "wy", "label": "Wyoming"}
];

class ReduxStateSelect extends React.Component {

   componentDidMount() {
      this.subscription = stateObservable$.subscribe(state => {
         this.props.input.onChange(state);
      })
   }

   componentWillUnmount() {
      if (this.subscription) {
         this.subscription.unsubscribe();
      }
   }

   handleChange = (option) => {
      if (this.props.input.onChange) {
         this.props.input.onChange(option.value);
      }
   };

   getSelectedValue = () => {
      const {value} = this.props.input;
      if (value) {
         return stateOptions.find(option => option.value.toLowerCase() === value.toLowerCase());
      } else {
         return null;
      }
   };

   render() {
      return (
         <Select
            className={"react-select-container " + (this.props.className ? this.props.className : "")}
            classNamePrefix="react-select"
            isSearchable={true}
            value={this.getSelectedValue()}
            onChange={this.handleChange}
            options={stateOptions}
            maxMenuHeight={135}
            required={this.props.required}
            blurInputOnSelect={false}
            isDisabled={this.props.isDisabled || false}
            placeholder={this.props.placeholder || "Select..."}
         />
      )
   }
}


export default ReduxStateSelect;
