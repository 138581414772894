/*

  Modified by Taran Sahota
    && Bryan Perez ^_^

*/

import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import { createEpicMiddleware } from 'redux-observable';

import { rootReducer } from "../reducers/index";
import { rootEpic } from "./epics";


const epicMiddleware = createEpicMiddleware();


const middlewares = [thunk, epicMiddleware];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore() {
    const store = createStore(
        rootReducer,
        composeEnhancers(
            applyMiddleware(...middlewares)
        )
    );
    epicMiddleware.run(rootEpic);

    return store;
}
