import React, { Component } from "react";
import { Container } from "reactstrap";
import { requestDomain } from "../../services/SeekerServices";

import { ThemeContext } from "../../../employer/context/ThemeContext";

import BG1 from "../../../../assets/img/non-upward-logos/bg_3.png";

class FindBetter extends Component{


    render(){
        return(
            <section className={"upward_find_better "+ (this.context ? "upward_find_better_" + this.context : "" ) } style={window.location.host.indexOf("jobtorch") > -1 ? {"background" : "none"} : {"background" : `url(${BG1}) 16% center no-repeat fixed`}}>
                <Container style={{"height":"984px","marginTop":"10%"}}>
                    <div className="clearfix col-lg-6 col-md-6 col-sm-6" style={{"float":"right"}}>
                        <h3 style={{"fontSize":"3.5em","color": "#0076a3"}}>Apply to career <br /> opportunities in minutes.</h3>
                        <h3 style={{"fontSize":"24px","color": "#666666","paddingTop":"10px"}}>Quickly create your skills profile, search for jobs that match your skill set, and apply in just a few clicks. It's really that easy.</h3>
                        <a href={requestDomain + `/results?reg=y`} className="btn btn-sm mt-3" style={{"borderRadius": "6px","fontSize":"18px","backgroundColor": "#ff9800","borderColor": "#ff9800","padding": "0 60px","lineHeight": "2.4","textShadow": "none !important","color":"white"}}><b>Get Started</b></a>
                    </div>
                </Container>
            </section>
        )
    }

}

FindBetter.contextType = ThemeContext;

export default FindBetter;