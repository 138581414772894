/*

      PROPS:
         placeholder={"example: \'cashier\'"}                  Optional

         handleSelect(suggestion || strInput )                 Optional

            suggestion
               is the parameter if a autosuggest is clicked by the user
            strInput
               is the parameter if the user just types
 */

import React, {useEffect} from "react";
import InputWithAutosuggest from "ui/form-elements/autosuggest/InputAutosuggest";
import {getLocationSuggestions} from "services/suggestions/location";
import {getCurrentLocation} from "services/search";

const LocationInputAutosuggest = (props) => {

   useEffect(() => {
      const subscription = getCurrentLocation().subscribe(res => {
         const {region, city} = res;
         if (region && city) {
            props.handleChange(
               null,
               {
                  newValue: `${city}, ${region}`
               });
         }
      });
      return () => {
         subscription && subscription.unsubscribe();
      }
   }, []);

   return (
      <InputWithAutosuggest
         getSuggestions={getLocationSuggestions}
         placeholder={"location, (city, zip code)"}
         {...props}
      />
   );
};

export default LocationInputAutosuggest;