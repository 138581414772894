import React, { useContext } from "react";
import { Row, Col, Container } from "reactstrap";

import { ThemeContext } from "../../../employer/context/ThemeContext";
import DontStayImg from "assets/img/homepage/homepage/homepage_3.jpg";
import QuickImg from "assets/img/homepage/homepage/homepage_4.jpg";
import useIsInViewport from "ui/hooks/inViewport";
import { CSSTransition } from 'react-transition-group';
import { requestDomain } from "../../services/SeekerServices";

const EnterpriseDetails = () => {

    const context = useContext(ThemeContext);
    const[inView1, detailsImg1] = useIsInViewport();
    const[inView2, detailsImg2] = useIsInViewport();

        return(
            <section className={"home_details upward_more_details "+ (context ? "upward_more_details_"+ context : "") }>
                <Container>
                    <div className="language_wrapper">
                        <Row className="align-items-center">
                            <Col lg={{ "size": 6, "offset": 1 }} className="order-lg-12">
                                <CSSTransition in={inView1} timeout={200} classNames="more_details_img"><img ref={detailsImg1} src={DontStayImg} className="w-100 more_details_img" alt="" height="383" /></CSSTransition>
                            </Col>
                            <Col lg={{"size":4,"offset":1}}>
                                <div className="text-left MoreDetails">
                                    <h3 className="">Don’t stay at a job <br />you hate.</h3>
                                    <p>Not every job is right for you. If you feel in your gut there is something better, go for it!</p>
                                    <p className="mb-5">We give you the tools to succeed in your search.</p>
                                    <a className={(context ? "text-dark-"+ context : "" )} href={`${requestDomain}/results/?pageNum=1&reg=y`}>Create an account</a>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="language_wrapper">
                        <Row className="d-flex align-items-center">
                            <Col xs="12" lg={{"size":5,"offset":1}} >
                                <CSSTransition in={inView2} timeout={200} classNames="more_details_img"><img ref={detailsImg2} src={QuickImg} alt="" className="more_details_img mx-auto d-block" style={{"maxWidth":"100%"}} /></CSSTransition>
                            </Col>
                            <Col xs="12" lg={{"size":4,"offset":1}} className="text-left MoreDetails painlessText">
                                <h3>Quick & painless <br /> application process</h3>
                                <p>We know you are busy. Our applications take just a few minutes. And once you have added a resume file, then you can apply with just one click.</p>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        )

}

export default EnterpriseDetails;
