import React, { useState, useEffect, useContext, Fragment } from "react";
import { Row, Col, Container } from "reactstrap";
import CountUp from 'react-countup';
import useIsInViewport from "ui/hooks/inViewport";

import { getCandidateCount, getJobCount } from "../../verticals/services";

import { ThemeContext } from "../../context/ThemeContext";

import upwardJobcase from "assets/img/vectors/logo/Jobcase_Wordmark_Black.svg";
import emp1 from "assets/img/homepage/employer/employer_help_hire.png";

const defaultJobCount = 48254;
const defaultCandidateCount = 139527;

const CountDisplay = (props) => {
    const { colored, isVertical, verticalName, verticalCategories } = props;
    const [ jobCount, setJobCount ] = useState( 0 );
    const [serverJobCount, setServerJobCount] = useState(defaultJobCount);
    const [serverCandidateCount, setServerCandidateCount] = useState(defaultCandidateCount);
    const [ candidateCount, setCandidateCount ] = useState( 0 );
    const context = useContext( ThemeContext );
    const [countDisplayInview, countDisplayRef] = useIsInViewport();
    useEffect( ()=> {
        const candidateSubscription = getCandidateCount( verticalCategories ).subscribe(
            ( data ) => {
                setServerCandidateCount( data.applicantCount );
            }
        );
        return () => {
            candidateSubscription.unsubscribe();
        }
    },[]);
    useEffect(() => {
        const jobSubscription = getJobCount(verticalCategories).subscribe(
            (data) => {
                setServerJobCount(data.recentJobCount);
            }
        );
        return () => {
            jobSubscription.unsubscribe();
        }
    }, []);

    useEffect(()=>{
        if( countDisplayInview ){
            setCandidateCount( serverCandidateCount );
        } else {
            setCandidateCount( defaultCandidateCount );
        }
    }, [countDisplayInview, serverCandidateCount]);

    useEffect(() => {
        if (countDisplayInview) {
            setJobCount(serverJobCount);
        } else {
            setJobCount( defaultJobCount );
        }
    }, [countDisplayInview, serverJobCount]);

    return (
        <section ref={countDisplayRef} className={"upward_count_display "+ (context && colored ? "upward_count_display_" + context : "" ) }>
            <Container>
                <Row>
                    <Col xs="12" xl={{ size: 4, offset: 1 }}>
                        <div className="text-left first_num">
                            <img className="rounded" src={upwardJobcase} alt="We help you hire smarter" style={{"height":"30px","margin-bottom":"30px"}}/>
                            <h3 className="mb-4">We help you hire smarter</h3>
                            <p className="mb-4">It doesn’t matter if you’re a local shop or global retailer, Jobcase helps businesses of all sizes recruit the people they need. With our superior job matching and a community of more than 120 million workers, you can quickly connect with the right people, at the right budget, right when you need them.
                            </p>
                        </div>
                    </Col>
                    <Col xs="12" xl={{ size: 4, offset: 1 }}>
                        <div className="text-left">
                            <img className="img-fluid rounded hireImg d-none d-md-block" src={emp1} alt="The scale you need for success" />
                        </div>
                    </Col>
                </Row>
                <Row className="mt-sm-0 mt-md-5">
                    <Col xs="12" xl={{ size: 4, offset: 1 }}>
                        <div className="text-center first_num">
                            <h2><CountUp start={ isVertical ? 9000 : 100000 } end={candidateCount} duraction={15} useEasing separator="," /></h2>
                            { !isVertical && <p>Applications received in last 30 days</p> }
                            {isVertical && <p>Monthly applications received {verticalName && <Fragment> in <span className=""><b>{verticalName}</b></span></Fragment> }</p> }
                            <h2><CountUp start={isVertical ? 9000 : 10000} end={jobCount} duraction={15} useEasing separator="," /></h2>
                            { !isVertical && <p>Jobs posted in last 30 days</p>}
                            {isVertical && <p>Monthly jobs posted {verticalName && <Fragment> in <span className=""><b>{verticalName}</b></span></Fragment> }</p>}
                        </div>
                    </Col>
                    <Col xs="12" xl={{ size: 4, offset: 1 }}>
                        <div className="text-left mt-sm-0 mt-md-4">
                            <h4>The scale you need for success</h4>
                            <p className="my-4"><span className="orange_plus">&#43;</span> 120&#43; million registered members</p>
                            <p className="my-4"><span className="orange_plus">&#43;</span> 20&#43; million unique visitors per month</p>
                            <p className="my-4"><span className="orange_plus">&#43;</span> #3 among US online career services¹</p>
                            <p className="comscore" style={{"fontSize":"11px","position":"relative"}}>¹Comscore Media Metrix&reg;, Career Services and Development, Total Audience, Jan 2022, U.S.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )

}


export default CountDisplay;