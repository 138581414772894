import axios from "axios";
import { from, of } from "rxjs";
import { map, catchError, first } from 'rxjs/operators';

const DEFAULT_FAILURE_MESSAGE = { "response": "failure", "message": "" }; // default failure message.

export const getJobSeekerProfile = () => {
    return from(
        axios.get(global.API_URL + global.JOB_DIR +'/api/profile/getDetails.jsp')
    ).pipe(
        first(),
        map(response => {
            if (response && response.data) {
                return response.data;
            } else
                return { ...DEFAULT_FAILURE_MESSAGE, "message": "failed to fetch" };
        }),
        catchError((err) => { console.log(err); return of({ ...DEFAULT_FAILURE_MESSAGE, "message": "failed to fetch" }) })
    );
};

export const getJobSeekerResume = () => {
    return from(
        axios.get(global.API_URL + global.JOB_DIR +'/api/profile/getResumeDetails.jsp')
    ).pipe(
        first(),
        map(response => {
            if (response && response.data) {
                return response.data;
            } else
                return { ...DEFAULT_FAILURE_MESSAGE, "message": "failed to fetch" };
        }),
        catchError((err) => { console.log(err); return of({ ...DEFAULT_FAILURE_MESSAGE, "message": "failed to fetch" }) })
    );
};
