import React, { useEffect, useContext, useState } from "react";

import { Row, Col, Container } from "reactstrap";
import { withRouter } from 'react-router-dom';
import { requestDomain } from "../../homepage/services/SeekerServices";

import JTLogo from "../../../assets/img/non-upward-logos/jt_logo_homepage.png";
import AAJLogo from "../../../assets/img/non-upward-logos/aaj_logo.png";
import JTHLogo from "../../../assets/img/non-upward-logos/jth_logo.png";

import { ThemeContext } from "../context/ThemeContext";

const HomeHeader2 = ({ inverted, employersActive, jobSeekerActive, history, location, isSite2, abbr}) => {
    const context = useContext(ThemeContext);
    const [headerScrollClass, setHeaderScrollClass] = useState("");
    const [logoPath, setLogoPath] = useState("/images/logo_homepage.png");

    const handleWindowScroll = () => {
        if (window.scrollY) {
            if (window.scrollY > 130) {
                setHeaderScrollClass(" header_opaque header_scrolling ");
            } else {
                setHeaderScrollClass(" ");
            }
        }
    }

    useEffect(() => {
        document.addEventListener("scroll", handleWindowScroll, false);
        return () => {
            document.removeEventListener("scroll", handleWindowScroll);
        }
    });

    useEffect(()=>{
        if(window.location.host.indexOf("jobtomic") > -1){
            setLogoPath(JTLogo);
        } else if(window.location.host.indexOf("acquireajob") > -1) {
            setLogoPath(AAJLogo);
        } else if(window.location.host.indexOf("jobtorch") > -1) {
            setLogoPath(JTHLogo);
        }
    },[]);

    useEffect(()=>{
        if(isSite2 && window.location.host.indexOf("acquireajob") < -1 && window.location.host.indexOf("jobtomic") < -1){
            setLogoPath("/images/"+abbr+"/logo.png");
        }
    },[isSite2]);

    return (
        <header className={"upward_header " + (context ? "header_" + context : "") + " " + headerScrollClass + (inverted ? " upward_header_inverted " : "")}>
            <nav className="navbar fixed-top">
                <Container>
                    <div className="clearfix" style={{ "width": "100%" }}>
                        <Row className="">
                            <Col xs="4" md="2" className="mr-auto">
                                <a className="navbar-brand" href="/">
                                    <img src={logoPath} alt="Logo"/>
                                </a>
                            </Col>
                            <Col xs="2" md="1" lg="4" xl="4" className="order-xs-4 order-md-12">
                                <div className="text-right d-none d-lg-block">
                                    <a href={requestDomain + `/results?reg=y&SID=tSfL42`} className={"mr-3 bold"}>Join Now</a>
                                    <a href={requestDomain + `/results/?pageNum=1&signin=y`} className={"mr-3 bold"}>Sign In</a>
                                    <a href="https://www.upward.net/employer/registration/username?plan=basic&src=JT&sjso=yes" className="btn btn-sm" style={{"backgroundColor": "#ff9800","borderColor": "#ff9800","padding": "2px 15px","lineHeight": "2.4","textShadow": "none !important","color":"white"}}><img alt="briefcase" style={{"float":"left","width":"20px","margin":"6px 10px 0 0"}} src="https://www.jobtomic.com/o/briefcaseOrg.png"/> <b>Post a Job</b></a>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </nav>
        </header>
    )
}

export default withRouter(HomeHeader2);