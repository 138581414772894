/*

 
*/
import React from "react";

const HomePageLayout = ({ children }) => (
  <React.Fragment>
    <div className="clearfix">
        {children}
    </div>
  </React.Fragment>
);

export default HomePageLayout;
