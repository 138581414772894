import * as types from './constants';
import * as profileTypes from '../../job-seeker/profile/redux/constants'

const isUpward = (window.location.href.indexOf("upward") > -1 ? true : false);

const defaultState = {
    CID: (isUpward ? 2242 : 2715),
    OID: 1313,
    SID: "",
    totalJobs: 0,
    pagesize: 0,
    start: 0,
    jobs: [],
    rightSideJobs: [],
    ipLocation: {},
    selectedJob: {},
    loadError: "",
    isLoading: true,
    isMoreLoading: false,
    searchObject: {
        job: "",
        location: "",
        CID: (isUpward ? 2242 : 2715),
        OID: 1313,
        SID: "",
        solrJtid: "",
        seJk: "",
        seSt: "",
        siteId: "",
        siteName: "",
        siteAbbr: "",
        siteDataFetched: false,
    }
}


export default function (state = defaultState, action) {
    switch (action.type) {
        case types.JOB_SEEKER_FETCH_RESULTS_BEGIN:
            return {
                ...state,
                selectedJob: {
                    ...state.selectedJob
                },
                loadError: "",
                isLoading: action.payload.start === 0,
                isMoreLoading: action.payload.loadMore !== 0
            }
        case types.JOB_SEEKER_UPDATE_RESULTS:
            return {
                ...state,
                selectedJob: {
                    ...state.selectedJob
                },
                totalJobs: action.payload.totalJobs,
                start: +action.payload.start,
                pagesize: action.payload.pagesize,
                jobs: +action.payload.start === 0 ? [...action.payload.jobs] : [...state.jobs, ...action.payload.jobs],
                rightSideJobs: action.payload.rightSideJobs.length > 0 ? [...action.payload.rightSideJobs] : [...state.rightSideJobs],
                isLoading: false,
                isMoreLoading: false,
            }
        case types.JOB_SEEKER_FETCH_RESULTS_UPDATE_START:
            return {
                ...state,
                selectedJob: {
                    ...state.selectedJob
                },
                start: +state.start + state.pagesize
            }
        case types.JOB_SEEKER_FETCH_RESULTS_ERROR:
            return {
                ...state,
                selectedJob: {
                    ...state.selectedJob
                },
                isLoading: false,
                isMoreLoading: false,
                loadError: action.payload
            }
        case types.JOB_SEEKER_UPDATE_SEARCH_PARAMS:
            return {
                ...state,
                searchObject: {
                    ...state.searchObject,
                    ...action.payload
                },
                ...action.payload,
                start: 0
            }
        case types.JOB_SEEKER_UPDATE_RESULTS_SELECTED_JOB:
            return {
                ...state,
                selectedJob: {
                    ...action.payload
                }
            }
        case types.JOB_SEEKER_UPDATE_IP_LOCATION:
            return {
                ...state,
                ipLocation: {
                    ...action.payload
                }
            }
        case profileTypes.JOB_SEEKER_UPDATE_SITE_DETAILS:
            return {
              ...state,
              searchObject: {
                ...state.searchObject,
                ...action.payload,
                siteDataFetched: true //siteData need only be fetched once
              }
            }
        default:
            return state;
    }
}
