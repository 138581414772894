import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Container } from "reactstrap";
import { requestDomain } from "../../homepage/services/SeekerServices";
import { ThemeContext } from "../context/ThemeContext";
import privacyChoicesIcon from '../../../assets/img/osano/privacy_choices_icon.png'

class HomeFooter extends Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <footer>
                <Container>
                    <div className="py-1 text-center small">
                        <div style={{"color":"gray","fontSize":"12px","marginBottom":"5px"}}>Disclaimer: is a job search engine. All trademarks, service marks, logos, and/or domain names are the property <br/>of their respective owners. This website and its contents are not endorsed, sponsored by or affiliated with any listed employers.</div>
                        <span style={{"color": "#0076A3"}}>&copy; &trade;&mdash; All Rights Reserved.</span> &nbsp;&nbsp; {(new Date("2023-2-1") >= new Date()) && (new Date() >= new Date("2022-12-18")) ? <a href="?privacy-policy" style={{"color": "#0076A3"}}>Privacy Policy - UPDATED</a> : <a href="?privacy-policy" style={{"color": "#0076A3"}}>Privacy Policy</a>}&nbsp; | &nbsp;<div className="non-upward-footer" style={{display: "inline-block"}}><a href="javascript:void(0);" onClick={this.props.openCmpDrawer}><img src={privacyChoicesIcon} className="privacy-choices-icon" /><span className="your-privacy-choices">Your Privacy Choices </span></a></div>&nbsp; |  &nbsp;{(new Date("2023-2-1") >= new Date()) && (new Date() >= new Date("2022-12-18")) ? <a href="?terms-and-conditions" style={{"color": "#0076A3"}}>Terms of Agreement - UPDATED</a> : <a href="?terms-and-conditions" style={{"color": "#0076A3"}}>Terms of Agreement</a>}&nbsp; | &nbsp;<a href={requestDomain + `/results`} style={{"color": "#0076A3"}}>Search Jobs</a> |{/* &nbsp;&nbsp;<a href="/jobs/advertiserDetails.jsp">Advertise with Us</a>&nbsp;&nbsp; */} <a href="?contact" style={{"color": "#0076A3"}}>Contact Us</a>&nbsp; | &nbsp;<a href="?unsub" style={{"color": "#0076A3"}}>Unsubscribe</a>&nbsp; | &nbsp;<a href="?dmca" style={{"color": "#0076A3"}}>DMCA Notice</a>
                        <div className="footer-links"><a href="?privacy-policy#section-10" style={{"color": "#0076A3"}}>California privacy notice</a>&nbsp;</div>
                        <div className="text-center py-1">
                            <a href="https://www.upward.careers" className="text-decoration-none">
                                <span style={{'color':'rgb(23, 28, 36) !important'}}>powered by &nbsp; &nbsp;</span>
                                <img width="70" src="https://www.upward.careers/images/upwardlogo.png" alt="Upward"/>
                            </a>
                        </div>
                    </div>
                </Container>
            </footer>
        )
    }

}

HomeFooter.contextType = ThemeContext;

export default withRouter(HomeFooter);