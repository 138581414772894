import React, { useContext } from "react";
import { Field, reduxForm } from "redux-form";
import { Row, Col, Alert } from 'reactstrap';


import FormValidators from "ui/FormValidators";

import { ThemeContext } from "corp-site/employer/context/ThemeContext";

import { UpwardLoadingButton, UpwardReduxInput } from "ui";


const ResetPassword = (props) => {
    const context = useContext(ThemeContext);
    const { handleSubmit, isLoading, resetError, resetSuccess } = props;
    return (
        <form onSubmit={handleSubmit}>
            <Row>
                <Col xs={{ "size": 10, "offset": 1 }} >
                    {
                        resetError && (
                            <Alert color="danger">
                                <div className="alert-message">
                                    {resetError}
                                </div>
                            </Alert>
                        )
                    }
                    {
                        resetSuccess && (
                            <Alert color="success">
                                <div className="alert-message">
                                    {resetSuccess}
                                </div>
                            </Alert>
                        )
                    }
                    <div className="form-group">
                        <Field
                            type="text"
                            bsSize="lg"
                            placeholder="Job Seeker Email Address"
                            name="email"
                            component={UpwardReduxInput}
                            validate={[FormValidators.required, FormValidators.email]} />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={{ "size": 6, "offset": 3 }} className="mt-4">
                    <UpwardLoadingButton block type="submit" color="primary" size="lg" isLoading={isLoading}
                        className={"btn-rounded btn-dark-"+context}><b>Submit</b></UpwardLoadingButton>
                </Col>
            </Row>
        </form>
    )
}

export default reduxForm({
    form: "resetPassword",
})(ResetPassword);
