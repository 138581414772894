/*


  Modified by Taran Sahota
  Dated : 9/19/2019

  added form reducer - required for Redux Form to work

*/

import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import jobSeekerAlerts from 'corp-site/job-seeker/job-alerts/redux/reducer';
import jobSeekerProfile from 'corp-site/job-seeker/profile/redux/reducer';
import jobSeekerResume from 'corp-site/job-seeker/profile/redux/resumeReducer';
import jobSeekerResults from 'corp-site/results/redux/reducer';
import seekerRegistrationReducer from 'corp-site/sign-up/redux/reducer';


export const rootReducer = combineReducers({
  form: formReducer,
  jobSeekerAlerts: jobSeekerAlerts,
  jobSeekerProfile: jobSeekerProfile,
  jobSeekerResume: jobSeekerResume,
  jobSeekerResults: jobSeekerResults,
  seekerRegistration: seekerRegistrationReducer
});
