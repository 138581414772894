/*


  Implementation by Taran Sahota
  Dated : 9/19/2019



    Implementation to use redux form with input field.

    this is reusable field for the text, email, password, number


*/
import React from "react";
import {
    Input
} from "reactstrap";

const UpwardReduxInput = ({ input, meta: { touched, error, warning }, ...custom }) => {
    return (
        <Input {...(touched ? { valid: !error } : {})} {...input} {...custom} className={touched && error ? " is-invalid " : ""} />
    )
};

export const UpwardAutoSuggestRedux = ({ input, label, className, meta: { touched, error }, component: Component, childComponent: ChildComponent, ...custom }) => {
    return (
        <ChildComponent {...custom} className={(className ? className : '') + ' ' + (touched && error ? " is-invalid " : "")} selected={input.value} onSelection={input.onChange} {...input} />
    )
}

export default UpwardReduxInput;