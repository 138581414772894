

import React from "react";
import { ModalHeader, ModalBody } from 'reactstrap';

const DMCA = (props) => {

    const currentYear = new Date().getFullYear();

    return (
        <React.Fragment>
            <ModalHeader style={{paddingBottom:0}}>
                <h3 className="center">DMCA Notice and Trademark Infringement</h3>
            </ModalHeader>
            <ModalBody>
                <React.Fragment>
                <div className="clearfix mainSection">
                <div className="container">
                    <div className="clearfix contentWrapper">
                        <div className="mainContent" id="mainContentHolder">
                            <div className="row">
                                <div className="col-md-12 col-lg-12">
                                    <p>
                                        <b>Last Updated January 2020</b><br/>
                                    </p>
                                    <p>{props.domain}
                                        respects the intellectual property rights of others. If you believe something on this website has infringed your intellectual property rights, our policy is to respond to alleged infringement notices that comply with the Digital Millennium Copyright
                                        Act of 1998 (the &quot;DMCA&quot;); please provide the following information in writing:</p>
            
                                    <ol>
                                        <li>Identification of the work claimed to have been infringed;</li>
                                        <li>Identification of the material that is claimed to be infringing and where it is located on the website;</li>
                                        <li>Information reasonably sufficient to allow us to contact you, such as your address, telephone number, and e-mail address;</li>
                                    </ol>
            
                                    <p>A statement that you have a good faith belief that use of the material in the manner complained of it not authorized by the intellectual property owner, its agent, or the law;</p>
                                    <p>A statement, made under penalty of perjury, that the above information is accurate, and that you are the intellectual property owner or are authorized to act on behalf of the owner.</p>
                                    <p>Our Agent can be reached using the Contact Us link on the website.</p>
                                    <p>UNDER FEDERAL LAW, IF YOU KNOWINGLY MISREPRESENT THAT ONLINE MATERIAL IS INFRINGING, YOU MAY BE SUBJECT TO CRIMINAL PROSECUTION FOR PERJURY AND CIVIL PENALTIES, INCLUDING MONETARY DAMAGES, COURT COSTS, AND ATTORYNEYS&#39; FEES.</p>
                                    <p>In accordance with the DMCA and other applicable law, we have a policy of terminating, if appropriate, Users, third party posters and employers who are deemed to be repeat infringers.</p>
            
                                    <div style={{"text-align":"center","padding":"25px 0"}}>
                                        Copyright © {currentYear} www.upward.net All rights reserved.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                </React.Fragment>
            </ModalBody>
        </React.Fragment>
    )

}


export default DMCA;
