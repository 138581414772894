import React, {useCallback, useContext, useState} from "react";
import {Row, Col, Container, Form, FormGroup, Button} from "reactstrap";
import {ThemeContext} from "../../../employer/context/ThemeContext";
import InputAutosuggest from "../../../../ui/form-elements/autosuggest/InputAutosuggest";
import {getJobTitleSuggestions} from "../../../../services/suggestions/job";
import LocationInputAutosuggest from "../../../../ui/form-elements/autosuggest/LocationInputAutosuggest";
import { requestDomain } from "../../services/SeekerServices";

const HomepageMain = (props) => {
   const context = useContext(ThemeContext);
   const [location, setLocation] = useState("");
   let [jobTitle, setJobTitle] = useState("");

   const handleSubmit = useCallback((event) => {
      event.preventDefault();
      let formattedJobTitle = jobTitle.toLowerCase().split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');
      if (formattedJobTitle === "") {
         formattedJobTitle = 'All Jobs';
      }
      window.location = `${requestDomain}/results?job=${formattedJobTitle}&location=${location}`;
   }, [jobTitle, location]);

   return (
      <section className={"upward_homepage_main upward_homepage_main_" + context} style={{"background": "url("+props.backgroundURL+") no-repeat center center fixed","backgroundSize": "cover"}}>
         <Container className="position-relative">
            <Row className="d-flex align-items-center">
               <Col xs="12" className="text-center" style={{"height":"984px","marginTop":"10%"}}>
                  <div style={{"background": "rgba(255,255,255, 0.8)","borderRadius": "25px", "padding": "50px 0","margin":"0 auto","maxWidth":"900px"}}>
                  <div md="12"><span style={{"fontSize":"3.5em"}}>Your search for a better job starts here.</span> <br/><span style={{"color": "#2D9CDB","fontSize":"1.3em"}}>Millions of Jobs. Millions of Candidates. One Destination.</span></div><br/>
                  <Form inline className="job_search_form job_search_form2" style={{"justifyContent": "center"}} onSubmit={handleSubmit}>
                     <FormGroup className="pt-2">
                        <InputAutosuggest
                           getSuggestions={getJobTitleSuggestions}
                           value={jobTitle}
                           placeholder={"example: ’cashier’"}
                           handleSelect={
                              useCallback(
                                 (suggestion) => {
                                    setJobTitle(suggestion.value);
                                 },
                                 [setJobTitle]
                              )
                           }
                           handleChange={
                              useCallback(
                                 (event, {newValue}) => {
                                    event.preventDefault();
                                    setJobTitle(newValue);
                              }, [])
                           }
                        />
                     </FormGroup>
                     <FormGroup className="pt-2">
                        <LocationInputAutosuggest
                           value={location}
                           id={"locationInput"}
                           handleSelect={
                              useCallback(
                                 (suggestion) => {
                                    setLocation(suggestion.value);
                                 },
                                 [setLocation]
                              )
                           }
                           handleChange={
                              useCallback(
                                 (event, {newValue}) => {
                                    if (event) {
                                       event.preventDefault();
                                    }
                                    setLocation(newValue);
                                 }, [])
                           }

                        />
                     </FormGroup>
                     <div className="text-center pt-2">
                     <Button type="submit" className={"btn-rounded btn-lg btn-dark-" + context}><b>Search</b></Button>
                     </div>
                  </Form>
                  </div>
               </Col>
            </Row>
         </Container>
      </section>
   )
};

export default HomepageMain;
