

import React, { useState } from "react";
import { ModalHeader, ModalBody, Row, Col } from 'reactstrap';
import {
    Button,
    Card,
    CardBody,
    Form,
    FormGroup,
    Input,
    Label
} from "reactstrap";
import axios from "axios";

const CalDoNotSell = ( props ) => {

    const [fNameVal, setFNameValue] = useState('');
    const [lNameVal, setLNameValue] = useState('');
    const [emailVal, setEmailValue] = useState('');
    const [phoneVal, setPhoneValue] = useState('');
    const [dropDownVal, setDropdDownValue] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showCheck, setShowCheck] = useState(false);
    const [showResend, setShowResend] =  useState(false);

    let query = {
        siteId : props.siteId,
        fname : fNameVal,
        lname : lNameVal,
        email : emailVal,
        phone : phoneVal,
        action : dropDownVal
    }

    const handleSubmit = e => {
        e.preventDefault();
        var queryString = Object.keys(query).map(key => key + '=' + query[key]).join('&');
        axios.get(global.API_URL + global.JOB_DIR + "/employee/ccpaUnsubAlert.jsp?" + queryString).then((response) => {
            if(response.data.status === "success"){
                setShowResend(true);
                setShowSuccess(true);
                setShowError(false);
            } else {
                setShowSuccess(false);
                setShowResend(true);
                setShowError(true);
            }
        }).catch(function (error) {
            // handle error
            console.log(error);
            setShowSuccess(false);
            setShowResend(true);
            setShowError(true);
        })

    }

    return (
        <React.Fragment>
            <ModalHeader style={{paddingBottom:0}}>
                <h3 className="center">Do Not Sell My Personal Information</h3>
            </ModalHeader>
            <ModalBody>
                <React.Fragment>
                    <div className="container">
                        <div className="clearfix contentWrapper">
                            <div className="mainContent" id="mainContentHolder">
                                <div className="row">
                                    <div className="col-md-12 col-lg-12">
                                        <p>
                                            <b>Effective date: 1/1/2020</b>
                                            <br/>
                                        </p>
                                        <h3 style={{fontSize:"22px"}}>Your right to opt out of sale of your personal information</h3>
                                        <p>Under the California Consumer Privacy Act of 2018 (CCPA), beginning January 1, 2020, California residents have the right to opt out of the sale of personal information about them or their household, such as (though not limited to) their name, postal or email address, and other personally identifying information. You need not be physically present in California to exercise this right provided that you have a current California residence.</p>
                                        <p>This right is subject to certain exemptions. For example, the law does not apply to information that has been aggregated and/or de-identified such that it could not reasonably be used to identify you. It also does not apply to information that we share with third-party service providers in order for them to perform certain business functions for us.</p>
                                        <h3 style={{fontSize:"22px"}}>How to exercise your opt out rights</h3>
                                        <p>If you would like to opt out of the sale of your personal information, you may fill out the form below or send an email detailing your request to <a href={'mailto:dataprivacy@'+props.domain} style={{"text-decoration":"underline","text-transform":"lowercase"}}>dataprivacy@{props.domain}</a>. When submitting your request via email, be sure to include your first and last name as well as the email address and phone number you used when you registered on our website. If this information is not present in the Email we will be unable to fulfill your request.</p>
                                        <h3 style={{"margin-bottom":0,"font-size" : "30px","margin-top":"30px"}}>California Consumer Portal</h3>
                                        <p>Please provide us with the information you used to register with us. An email will be sent to you with a link to verify your account and confirm this request.</p>
                                            <Card>
                                                <CardBody>
                                                    <Form onSubmit={handleSubmit}>
                                                        <Row>
                                                            <Col xs={{ "size": 6 }} >
                                                                <FormGroup>
                                                                    <Input onChange={e => setFNameValue(e.target.value)} bsSize="lg" type="text" placeholder="First name" id="fname" name="fname" className="inputBox inputSmall pull-left"/>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col xs={{ "size": 6 }} >
                                                                <FormGroup>
                                                                    <Input onChange={e => setLNameValue(e.target.value)} type="text" bsSize="lg" placeholder="Last name" id="lname" name="lname" className="inputBox inputSmall"/>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={{ "size": 12 }} >
                                                                <FormGroup>
                                                                    <Input onChange={e => setEmailValue(e.target.value)} type="email" placeholder="&#x2a;Email address" id="email" name="email" className="inputBox inputSmall" required />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={{ "size": 12 }} >
                                                                <FormGroup>
                                                                    <Input onChange={e => setPhoneValue(e.target.value)} type="text" placeholder="Phone number" id="phone" name="phone" className="inputBox inputSmall" />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={{ "size": 12 }} >
                                                                <FormGroup>
                                                                    <Input onChange={e => {setShowCheck(true);setDropdDownValue(e.target.value)}} type="select" id="action" name="action" placeholder="Type of request" className="inputBox inputSmall" required>
                                                                        <option value="">*Type of request (dropdown)</option>
                                                                        <option value="ns">Please do not sell my information</option>
                                                                        <option value="mi">Please provide stored/shared personal information (California residents only)</option>
                                                                        <option value="del">Please delete my information</option>
                                                                    </Input>
                                                                </FormGroup>
                                                                <p style={{"color": "#A7A7A7","font-size":"13px","margin":"10px 0 10px"}}>&#x2a;Required</p>
                                                            </Col>
                                                        </Row>
                                                        {showCheck && <Row>
                                                            <Col xs={{ "size": 12 }}>
                                                                <FormGroup>
                                                                    <Input type="checkbox" id="cal" name="cal" value="cal" required/>
                                                                    &nbsp; <Label for="vehicle1">I understand that I may be asked to provide proof that I am a California resident</Label>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>}
                                                        <Row>
                                                            <Col xs={{ "size": 12 }} >
                                                                <div className="form-actions">
                                                                    <Button type="submit" id="consumerPortalSubmit" className="btn btn-lg btn-rounded btn-dark-blue">Submit request</Button>
                                                                    &nbsp; &nbsp; {showResend && <a href="javascript:void(0);" onclick={handleSubmit}>resend request</a>}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </CardBody>
                                            </Card>

                                        <React.Fragment>
                                            {showSuccess && <p id="successMsg" style={{"color":"#922521"}} className="hide">
                                                Your request has been received. You will receive an email to verify your account and confirm this request. Please be sure to add <a href={'mailto:dataprivacy@'+props.domain} style={{"font-weight":"bold","text-decoration":"underline","color":"#922521"}}>dataprivacy@{props.domain}</a> to your whitelist to ensure that our communications are received in your inbox.
                                                <br/>
                                                <br/>
                                                Once you verify this request with the link in an email, the personally identifiable information you provided during application will not be shared or sold, and our personally identifiable information will be removed from our system.
                                            </p>}
                                            {showError && <p id="duplicateMsg" className="hide" style={{"color":"#922521"}}>
                                                We were unable to find a user record that matches the information you submitted. Please send an email to <a href={'mailto:dataprivacy@'+props.domain} style={{ "text-decoration":"underline","text-transform":"lowercase"}}>dataprivacy@{props.domain}</a> for further assistance.
                                            </p>}
                                        </React.Fragment>
                                        <br/>
                
                                        <p>There is no charge for making privacy-related requests.</p>
                                        <p>Please note that, in order to better safeguard your privacy and the privacy of others, we may be required to verify your identity before processing certain data-related requests.</p>
                                        <p>
                                            In some cases, we may be unable to fulfill your request because we have no way to verify your identity to the standard the law and/or its associated regulations require. For example (but without limitation), if you have visited our website, but never registered as a user, or applied for a job, or interacted with us via email or other means, we probably do not have enough information to confirm your identity to even a "reasonable degree of certainty" (as the applicable regulations may define that term). Also, in addition to any other exemptions applicable law and/or regulation may provide to your rights under the CCPA (particularly with regard to the deletion of your personal information), we may be unable to delete certain information (e.g., our web host's server and error logs) for technical reasons. </p>
                                        <p>
                                            As with the right to opt out, the other rights provided by the CCPA are subject to certain exemptions and exceptions, as specified in the applicable federal statutes and/or associated regulations issued by California's Office of the Attorney General. Those statues and/or regulations may also stipulate the maximum time allowed for acknowledging and responding to a request. There is no charge for making privacy-related requests. </p>
                                        <p>
                                            Only you, or a person registered with the California Secretary of State that you authorize to act on your behalf, may make a verifiable consumer request related to your personal information. 
                                        </p>
                                        <p>
                                            You may only make a verifiable consumer request for access or data portability twice within a 12-month period. The verifiable consumer request must: 
                                        </p>
                                        <p>
                                            Provide sufficient information that allows us to reasonably verify you are the person about whom we collected personal information or an authorized representative; and Describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it. We cannot respond to your request or provide you with personal information if we cannot verify your identity or authority to make the request and confirm the personal information relates to you. Making a verifiable consumer request does not require you to create an account with us. However, we do consider requests made through your password protected account sufficiently verified when the request relates to personal information associated with that specific account. 
                                        </p>
                                        <p>We will only use personal information provided in a verifiable consumer request to verify the requestor's identity or authority to make the request.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            </ModalBody>
        </React.Fragment>
    )

}


export default CalDoNotSell;
