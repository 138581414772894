import React from "react";
import { ReactComponent as Logo1 } from "assets/img/vectors/company_logo/default/company_1.svg";
import { ReactComponent as Logo2 } from "assets/img/vectors/company_logo/default/company_2.svg";
import { ReactComponent as Logo3 } from "assets/img/vectors/company_logo/default/company_3.svg";
import { ReactComponent as Logo4 } from "assets/img/vectors/company_logo/default/company_4.svg";
import { ReactComponent as Logo5 } from "assets/img/vectors/company_logo/default/company_5.svg";
import { ReactComponent as Logo6 } from "assets/img/vectors/company_logo/default/company_6.svg";
import { ReactComponent as Logo7 } from "assets/img/vectors/company_logo/default/company_7.svg";
import { ReactComponent as Logo8 } from "assets/img/vectors/company_logo/default/company_8.svg";
import { ReactComponent as Logo9 } from "assets/img/vectors/company_logo/default/company_9.svg";
import { ReactComponent as Logo10 } from "assets/img/vectors/company_logo/default/company_10.svg";
import { ReactComponent as Logo11 } from "assets/img/vectors/company_logo/default/company_11.svg";
import { ReactComponent as Logo12 } from "assets/img/vectors/company_logo/default/company_12.svg";
import { ReactComponent as Logo13 } from "assets/img/vectors/company_logo/default/company_13.svg";
import { ReactComponent as Logo14 } from "assets/img/vectors/company_logo/default/company_14.svg";
import { ReactComponent as Logo15 } from "assets/img/vectors/company_logo/default/company_15.svg";
import { ReactComponent as Logo16 } from "assets/img/vectors/company_logo/default/company_16.svg";


export const CompanyLogo1 = (props) => (<Logo1 {...props} />);
export const CompanyLogo2 = (props) => (<Logo2 {...props} />);
export const CompanyLogo3 = (props) => (<Logo3 {...props} />);
export const CompanyLogo4 = (props) => (<Logo4 {...props} />);
export const CompanyLogo5 = (props) => (<Logo5  {...props} />);
export const CompanyLogo6 = (props) => (<Logo6  {...props} />);
export const CompanyLogo7 = (props) => (<Logo7  {...props} />);
export const CompanyLogo8 = (props) => (<Logo8  {...props} />);
export const CompanyLogo9 = (props) => (<Logo9  {...props} />);
export const CompanyLogo10 = (props) => (<Logo10  {...props} />);
export const CompanyLogo11 = (props) => (<Logo11  {...props} />);
export const CompanyLogo12 = (props) => (<Logo12  {...props} />);
export const CompanyLogo13 = (props) => (<Logo13  {...props} />);
export const CompanyLogo14 = (props) => (<Logo14  {...props} />);
export const CompanyLogo15 = (props) => (<Logo15  {...props} />);
export const CompanyLogo16 = (props) => (<Logo16  {...props} />);

const logoArray = [CompanyLogo1, CompanyLogo2, CompanyLogo3, CompanyLogo4, CompanyLogo5, CompanyLogo6, CompanyLogo7, CompanyLogo8, CompanyLogo9, CompanyLogo10, CompanyLogo11, CompanyLogo12, CompanyLogo13, CompanyLogo14, CompanyLogo15, CompanyLogo16 ];

const CompanyLogo = (props)=>{
    const randomNum = Math.floor( Math.random() * 16 );
    const SelectedLogo = logoArray[randomNum];

    return (
        <SelectedLogo {...props} />
    )
}

export default CompanyLogo;