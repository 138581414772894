import { Link } from "react-router-dom";

import renderHeaderDropdown from "./HeaderDropDownHOC";

const employerOptions = [
    { "tag": Link, "href": "/employers", "title": "Employers" },
    { "tag": "a", "link": "https://hire.jobcase.com/purchase/post-now", "title": "Post a job" },
    { "tag": Link, "href": "/employers#postBoards", "title": "Features" },
    { "tag": Link, "href": "/employers?pricing=y", "title": "Pricing" },
    { "tag": Link, "href": "/enterprise", "title": "Enterprise" },
    { "tag": Link, "href": "/enterprise#serveIndustries", "title": "Jobcase by industry" },
    { "tag": Link, "href": "/employers?signin=y", "title": "Employer sign in" }
];

const EmployersDropDown = renderHeaderDropdown(
    "For employers",
    [...employerOptions]
);

export default EmployersDropDown;