/*


  Implementation by Taran Sahota
  Dated : 9/19/2019



    takes in props from parent and passes them as it to child
    also takes in inputProps - required for autosuggest implementation
    show loader inside the input field towards the right 



*/

import React from "react";
import PropTypes from "prop-types";
import { Input } from "reactstrap";
import FieldLoader from "../loaders/FieldLoader";



class LoadingInput extends React.Component{

    render(){
        const { isLoading, inputProps, value, ...rest } = this.props;
        return (
            <div className="loading_input_wrapper">
                <Input {...rest} {...inputProps}  />
                { isLoading ? <FieldLoader /> : "" }
            </div>
        )
    }
}

LoadingInput.propTypes = {
    isLoading : PropTypes.bool
}

export default LoadingInput;