import React, {useState, Fragment, useEffect} from "react";
import { Link } from "react-router-dom";

  const BottomBanner = () => {

  const[ showBanner, setShowBanner ] = useState(false);

  useEffect(()=>{
    if((new Date("2022-12-28") >= new Date()) && (new Date() >= new Date("2022-12-18"))){
      setShowBanner(true);
    }else{
      setShowBanner(false);
    }
  },[]);

  const closeBanner = (e) => {
    e.preventDefault();
    document.cookie = "banner=true";
    setShowBanner(false);
  }

  return (
    
    <Fragment>
      <div className="alert alert-warning animated bounceInUp" id="pp-notification" role="alert" style={showBanner && document.cookie.indexOf('banner=true') <= -1 ? {"display":"block", "padding": "0", "background": "#fcf8e3"} : {"display" : "none"}}>
        <div className="row" style={{"display":"block"}}>
          <div className="col-xs-12 pp-banner-text clean-text">
            <a href="#" onClick={closeBanner} className="clean-text-render" id="pp_modal_close_x">&times;</a>
            We've updated our <Link to="/?privacy-policy" className="pp_alert privacy_policy">Privacy policy</Link> and <Link to="/?terms-and-conditions" className="terms">Terms of use</Link>, please take a moment to review both.
          </div>
        </div>
      </div>
    </Fragment>

  );
  
  }

export default BottomBanner;
