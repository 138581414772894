import React, {useCallback, useContext, useState} from "react";
import {Row, Col, Container, Form, FormGroup, Button} from "reactstrap";
import {CSSTransition} from 'react-transition-group';
import useIsInViewport from "ui/hooks/inViewport";
import RecentJobs from "corp-site/employer/verticals/components/RecentJobs";
import RecentCandidates from "corp-site/employer/verticals/components/RecentCandidates";
import homepage1 from "assets/img/homepage/homepage/homepage_main.png";
import Abstract4 from "corp-site/employer/Abstracts/Abstract4";
import Abstract5 from "corp-site/employer/Abstracts/Abstract5";
import {ThemeContext} from "../../../employer/context/ThemeContext";
import InputAutosuggest from "../../../../ui/form-elements/autosuggest/InputAutosuggest";
import {getJobTitleSuggestions} from "../../../../services/suggestions/job";
import LocationInputAutosuggest from "../../../../ui/form-elements/autosuggest/LocationInputAutosuggest";
import { requestDomain } from "../../services/SeekerServices";

const CandidateHomepageLink = () => {
   return (
      <a href={`${requestDomain}/results/?pageNum=1&reg=y`}><b>sign up</b></a>
   )
};

const JobHomepageLink = () => {
   return (
      <a href={`${requestDomain}/results/?pageNum=1&reg=y`}><b>apply now</b></a>
   )
};

const HomepageMain = () => {
   const context = useContext(ThemeContext);
   const [inView, homeImageRef] = useIsInViewport();
   const [location, setLocation] = useState("");
   let [jobTitle, setJobTitle] = useState("");

   const handleSubmit = useCallback((event) => {
      event.preventDefault();
      let formattedJobTitle = jobTitle.toLowerCase().split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');
      if (formattedJobTitle === "") {
         formattedJobTitle = 'All Jobs';
      }
      window.location = `${requestDomain}/results?job=${formattedJobTitle}&location=${location}`;
   }, [jobTitle, location]);


   return (
      <section className={"upward_homepage_main upward_homepage_main_" + context}>
         <Container className="position-relative" style={{"minHeight": "100%"}}>
            <Row className="d-flex align-items-center">
               <Col xs="12" lg={{"size": 6, "offset": 1}}>
                  <div md="12"><h2>Find a <br className="d-md-none"/> better job. <br/>Move upward.</h2></div>
                  <Form inline className="job_search_form" onSubmit={handleSubmit}>
                     <FormGroup className="pt-2">

                        <InputAutosuggest
                           getSuggestions={getJobTitleSuggestions}
                           value={jobTitle}
                           placeholder={"example: ’cashier’"}
                           handleSelect={
                              useCallback(
                                 (suggestion) => {
                                    setJobTitle(suggestion.value);
                                 },
                                 [setJobTitle]
                              )
                           }
                           handleChange={
                              useCallback(
                                 (event, {newValue}) => {
                                    event.preventDefault();
                                    setJobTitle(newValue);
                              }, [])
                           }
                        />
                     </FormGroup>
                     <FormGroup className="pt-2">
                        <LocationInputAutosuggest
                           value={location}
                           id={"locationInput"}
                           handleSelect={
                              useCallback(
                                 (suggestion) => {
                                    setLocation(suggestion.value);
                                 },
                                 [setLocation]
                              )
                           }
                           handleChange={
                              useCallback(
                                 (event, {newValue}) => {
                                    if (event) {
                                       event.preventDefault();
                                    }
                                    setLocation(newValue);
                                 }, [])
                           }

                        />
                     </FormGroup>
                     <div className="text-center pt-2">
                     <Button type="submit" className={"btn-rounded btn-lg btn-dark-" + context}><b>Search
                        jobs</b></Button>
                     </div>
                  </Form>
               </Col>
               <Col xs="12" lg="5" className="overflow-hidden">
                  <div className="homepage_img_wrapper text-center">
                     <CSSTransition in={inView} timeout={200} classNames="home_page_abstract_1">
                        <div className="homepage_abstract_wrapper homepage_abstract_1">
                           <Abstract4/>
                        </div>
                     </CSSTransition>
                     <img ref={homeImageRef} src={homepage1} style={{"margin": "80px"}} alt="Home Page"/>
                     <CSSTransition in={inView} timeout={200} classNames="home_page_abstract_2">
                        <div className="homepage_abstract_wrapper homepage_abstract_2">
                           <Abstract5/>
                        </div>
                     </CSSTransition>
                  </div>
               </Col>
            </Row>
            <RecentCandidates maxCount={1} linkComponent={CandidateHomepageLink}/>
            <RecentJobs maxCount={1} linkComponent={JobHomepageLink}/>
         </Container>
      </section>
   )
};

export default HomepageMain;
