import React from "react";
import { ModalHeader, ModalBody } from "reactstrap";
import {openCmpDrawer} from "../../components/OsanoSupport";

const PrivacyPolicy = (props) => {
  return (
    <React.Fragment>
      <ModalHeader style={{ paddingBottom: 0 }}>
        <h3 className="center">Upward Privacy Policy</h3>
      </ModalHeader>
      <ModalBody>
        <React.Fragment>
          <div className="clearfix mainSection">
            <div className="container">
              <div className="clearfix contentWrapper">
                <div className="mainContent" id="mainContentHolder">
                  <div className="row">
                    <div className="col-md-12 col-lg-12">
                      <p>Last Updated: June 26, 2023</p>
                      <p>
                        Upward.net LLC respects the privacy of our users and has
                        developed this Privacy Policy to help you understand how
                        we collect, use and disclose the information from or
                        about you that we receive via our websites, online
                        services, software and/or mobile applications
                        (collectively "Upward.net", "we", "our", "us", "website"
                        or the "Services"). If you have questions about our
                        privacy practices, please refer to the end of this
                        Privacy Policy for information on how to Contact Us!
                      </p>
                      <p>
                        Upward.net is powered by{" "}
                        <a href="https://www.jobcase.com/">Jobcase.com</a> - an
                        online community where workers come together to help
                        each other get ahead. When you register with Upward.net,
                        you are automatically registered with Jobcase and are
                        also bound by the Jobcase{" "}
                        <a href="https://www.jobcase.com/terms-of-use">
                          terms of use
                        </a>{" "}
                        and subject to the Jobcase{" "}
                        <a href="https://www.jobcase.com/privacy-policy">
                          privacy policy
                        </a>
                        . After you register with Upward.net, click{" "}
                        <a href="https://www.jobcase.com/">here</a> to access
                        full functionality from your Jobcase account.
                      </p>
                      <p>
                        As always, using any Upward.net service is subject to
                        our Privacy Policy and our{" "}
                        <a href="https://www.upward.careers/?terms-and-conditions">
                          Terms of Use
                        </a>
                        . We recommend that you read the complete policy to
                        understand how we use data to help you with our
                        Services. You may also click on any link below to
                        navigate to a specific Section. If you are a California
                        resident and would like to see a summary of the privacy
                        disclosures applicable to you, please click{" "}
                        <u>
                          <a href="#section-10">here</a>
                        </u>{" "}
                        for our California Privacy Notice.
                      </p>
                      <ol>
                        <li>
                          <a href="#section-1">INFORMATION WE COLLECT</a>
                        </li>
                        <li>
                          <a href="#section-2">
                            HOW WE USE THE INFORMATION WE COLLECT
                          </a>
                        </li>
                        <li>
                          <a href="#section-3">
                            HOW WE DISCLOSE THE INFORMATION WE COLLECT
                          </a>
                        </li>
                        <li>
                          <a href="#section-4">YOUR RIGHTS AND CHOICES</a>
                        </li>
                        <li>
                          <a href="#section-5">
                            ONLINE AND MOBILE APP ADVERTISING
                          </a>
                        </li>
                        <li>
                          <a href="#section-6">
                            THIRD-PARTY LINKS AND FEATURES
                          </a>
                        </li>
                        <li>
                          <a href="#section-7">
                            HOW WE PROTECT AND STORE YOUR INFORMATION{" "}
                          </a>
                        </li>
                        <li>
                          <a href="#section-8">CHILDREN</a>
                        </li>
                        <li>
                          <a href="#section-9">INTERNATIONAL USERS</a>
                        </li>
                        <li>
                          <a href="#section-10">CALIFORNIA PRIVACY NOTICE</a>
                        </li>
                        <li>
                          <a href="#section-11">
                            CHANGES TO OUR PRIVACY POLICY
                          </a>
                        </li>
                        <li>
                          <a href="#section-12">CONTACT INFORMATION</a>
                        </li>
                      </ol>
                      <p id="section-1">
                        1. <strong>INFORMATION WE COLLECT</strong>
                      </p>
                      <p>
                        We collect several types of information from and about
                        you. Depending on how you interact with the Service, the
                        information you provide, and we collect, may vary.
                      </p>
                      <p>
                        <strong>a. Information You Provide to Us</strong>
                      </p>
                      <p>
                        Upward.net collects information directly from you
                        through your use of our Services. The following
                        describes common types of information we may collect
                        from you.
                      </p>
                      <p>
                        <em>Information We Collect from Job Seekers</em>
                      </p>
                      <p>
                        &#9679; <strong>Your Contact Information.</strong> We
                        collect contact information you provide, such as your
                        name, email address, home address, phone number, social
                        media handle, login information and profile picture,
                        when you sign up to search for available jobs through
                        our Services.
                      </p>
                      <p>
                        &#9679;{" "}
                        <strong>
                          Information About your Employment History.{" "}
                        </strong>
                        We collect employment information you provide, such as,
                        your employment and education history, employment
                        credentials (such as licenses or professional
                        memberships), job preferences (including targeted
                        compensation), and certain personal characteristics such
                        as your military/veteran status. You can also provide
                        other information such as your honors, awards, training,
                        a personal website URL, and other information.
                      </p>
                      <p>
                        &#9679; <strong>Demographics</strong>. We may collect
                        your demographic information, such as your age, gender,
                        and zip code. We may also ask you for optional
                        demographic information such as citizenship, race,
                        disability status, and ethnicity in connection with
                        certain employment opportunities. Providing this
                        information is entirely voluntary, and will be used only
                        for the purposes of providing the Services to you.
                        Please note that this information may be considered
                        “sensitive” or “sensitive personal information” under
                        applicable law, and we will comply with applicable legal
                        requirements in our collection and use of such
                        information.
                      </p>
                      <p>
                        &#9679; <strong>Your Content.</strong> We may collect
                        user content you may voluntarily choose to submit or
                        otherwise make available certain content through our
                        Services. For example, you may post your resume, work
                        history and other application materials.
                      </p>
                      <p>
                        &#9679; <strong>Inferences We Draw.</strong> Using the
                        other information collected about you, we may draw
                        inferences about you, reflecting what we believe to be
                        your preferences, characteristics, predispositions, and
                        attitudes.
                      </p>
                      <p>
                        &#9679;
                        <strong>
                          Address Book and Other Services That Sync with the
                          Services.
                        </strong>
                        If you use our address book or “contacts” importer (or
                        other similar features) to upload your address book into
                        our Services, we store this information (including phone
                        numbers, names, email addresses and other information)
                        and use it to help you manage and leverage your contacts
                        in connection with work-life success and our Services.
                      </p>
                      <p>
                        &#9679; <strong>Communications with Us.</strong> We may
                        collect communication information if you contact us for
                        customer service, to provide feedback, or to participate
                        in a survey or sweepstakes, and we may receive
                        information such as your name, email address, and/or
                        phone number in connection with the communication.
                      </p>
                      <p>
                        <em>
                          Information We Collect from Business Partners and
                          Potential Business Partners
                        </em>
                      </p>
                      <p>
                        &#9679;{" "}
                        <strong>Contact and Business Information.</strong> We
                        may collect your information if you are an employee or
                        agent of a company or other organization that is
                        interested in learning how our Services and provide us
                        information through the Services or request. We may
                        collect your name and contact information, your
                        organization’s name, and any other information you
                        choose to provide.
                      </p>
                      <p>
                        &#9679; <strong>Commercial Information.</strong> We
                        collect information regarding products or services you
                        may order from us or provide to us, and information
                        necessary for us to fulfill orders and maintain our
                        commercial relationship. This information may include
                        cardholder name and the last four digits of a payment
                        card number.
                      </p>
                      <p>
                        &#9679;{" "}
                        <strong>Communications and User Content.</strong> We
                        also may collect comments you submit and surveys or
                        profiles you complete.
                      </p>
                      <p>
                        <strong>
                          b. Information We Collect Through Automated Means
                        </strong>
                      </p>
                      <p>
                        As you navigate through and interact with our Service,
                        we may use automated technologies to collect certain
                        information:
                      </p>
                      <p>
                        &#9679; <strong>Usage Data.</strong> We collect and
                        analyze usage information, including the dates, times,
                        and browsers you access our Services on, the features
                        you use and the pages you visit, the types of content
                        you upload or post to our Services, error logs and other
                        system activity, and the websites or applications
                        through or from which you access the Services. We may
                        also use service providers to collect on a real-time
                        basis information on your use and navigation of
                        Upward.net. This may include mouse movements and how you
                        scroll through our website, but we do not collect
                        keystroke data. The information, including your IP
                        address, collected by the technology will be disclosed
                        to or collected directly by these service providers.
                      </p>
                      <p>
                        &#9679; <strong>Device Data.</strong> We collect and
                        analyze information about the device(s) you use to
                        access our Services. The information we collect may
                        include your IP address, information about your device’s
                        hardware, the operating system on your device and the
                        version of that operating system, your language
                        preferences, and unique device identifiers such as
                        mobile IDs or advertising IDs.
                      </p>
                      <p>
                        &#9679; <strong>Location Information.</strong> We and
                        our service providers collect general location (city and
                        state) information from your computer or mobile device
                        when you use the Services. We may also request that you
                        provide your zip code or city and state. This
                        information allows us to suggest jobs or other content
                        based on your general location. With your permission, we
                        may also collect precise geolocation information from
                        your device, passed to us from third-party services or
                        GPS-enabled devices that you have set up. Please note,
                        that most mobile devices allow you to prevent real time
                        location data being sent to us through your device
                        settings.
                      </p>
                      <p>
                        When you visit our website, we and our service providers
                        and partners collect information about you and your use
                        of the Services through the use of cookies and similar
                        technologies, such as tracking pixels and ad tags,
                        together “cookies”, to understand how you navigate
                        through and interact with our Services, to learn what
                        content is popular, to enhance the Services and provide
                        you with a more relevant and personalized experience,
                        for authentication and advertising purposes, to save
                        your preferences to collect certain usage, device and
                        location information as described above and facilitate
                        your access to our website and personalize your online
                        experience. Cookies are small text files that web
                        servers place on your device; they are designed to store
                        basic information and to help websites and apps
                        recognize your browser. Upward may use both session
                        cookies and persistent cookies. A session cookie
                        disappears after you close your browser. A persistent
                        cookie remains after you close your browser and may be
                        accessed every time you use our Services. Tracking
                        pixels (sometimes referred to as web beacons or clear
                        GIFs) are tiny electronic tags with a unique identifier
                        embedded in websites, online ads, and/or email; they are
                        designed to collect usage information like ad
                        impressions or clicks and email open rates, to measure
                        the popularity of our Services and associated
                        advertising, and to access cookies. To generally update
                        your cookies settings to notify you when a cookie is
                        being set or updated or to disable cookies altogether,
                        please consult your web browser settings. You may
                        decline our cookies if your web browser software
                        permits, although this may prevent you from accessing
                        certain features of the Service.
                      </p>
                      <p>
                        <strong>c. Information We Collect from Others</strong>
                      </p>
                      <p>
                        Upward may collect information about you from others,
                        including the following:
                      </p>
                      <p>
                        &#9679;{" "}
                        <strong>
                          Log-in Services and Social Network Connections.
                        </strong>{" "}
                        When you access the Services through a third-party
                        connection or log-in (e.g., through a social network),
                        you allow us to have access to and store certain
                        information from you’re the third-party connection or
                        log-in (e.g., the social network profile). This
                        information may include your name, social network
                        log-in, including your username and password, profile
                        picture, “likes,” images you upload, and list of
                        friends, depending on your settings on such services. If
                        you do not wish to have this information disclosed, do
                        not use a third-party connection or log-in connection to
                        access the Services. For a description of how a
                        third-party connection or log-in handles your
                        information, please refer to their privacy policies and
                        terms of use, which may permit you to modify your
                        privacy settings.
                      </p>
                      <p>
                        &#9679; <strong>Other Third Parties.</strong> We may
                        collect information about you to supplement the
                        information we collect directly from third parties, such
                        as our affiliates, business partners, marketers, data
                        co-ops (into which companies contribute certain
                        information about their customers (e.g., name and email
                        address) in exchange for information from one another to
                        enhance their customer lists and preferences),
                        researchers, analysts, and other parties. This
                        information may include demographic, contact, and
                        statistical information and employment history.
                      </p>
                      <p>
                        <strong>d. California Privacy Notice</strong>
                      </p>
                      <p>
                        If you are a California resident, please click{" "}
                        <u>
                          <a href="https://upward.careers/?california-privacy-notice">
                            here
                          </a>
                        </u>{" "}
                        to see our California Privacy Notice for information
                        about our privacy practices in accordance with
                        California law.
                      </p>
                      <p id="section-2">
                        2.{" "}
                        <strong>HOW WE USE THE INFORMATION WE COLLECT</strong>
                      </p>
                      <p>
                        Upward and our service providers use the information we
                        collect from and about you for the following business
                        and operational purposes:
                      </p>
                      <p>&#9679; Operate the Services</p>
                      <p>
                        &#9679; Provide, improve, personalize, enhance, and
                        promote our Services
                      </p>
                      <p>
                        &#9679; Process, fulfill and invoice for transactions
                        and services requested by you
                      </p>
                      <p>&#9679; Analyze use of our Services</p>
                      <p>&#9679; Conduct research</p>
                      <p>&#9679; Detect and prevent fraud</p>
                      <p>
                        &#9679; Resolve disputes, protect ourselves and other
                        Services users and others, and enforce any legal terms
                        that govern use of our Services
                      </p>
                      <p>
                        &#9679; Administer any surveys, promotions or contests
                      </p>
                      <p>
                        &#9679; Communicate with you, including for
                        transactional, marketing and promotional purposes, in
                        accordance with applicable legal requirements
                      </p>
                      <p>
                        &#9679; Gain insights into prospective customers or
                        users that might have an interest in our Services
                      </p>
                      <p>
                        &#9679; Provide customer support, including responding
                        to questions, requests, and issues
                      </p>
                      <p>
                        &#9679; Comply with laws, regulations, and other legal
                        process and procedures
                      </p>
                      <p>
                        <strong>
                          Combined/Aggregated/De-identified Information
                        </strong>
                        . We may combine the information we collect through the
                        Services with information we collect automatically or
                        receive from other sources to help you get the most out
                        of the Service, and combined information may be used in
                        accordance with this Privacy Policy. We may also
                        aggregate and/or de-identify information collected
                        through our Services in such a way that the information
                        cannot reasonably be linked to you or your device. We
                        may use de-identified or aggregated data for any
                        purpose, including for research and marketing purposes,
                        and we may disclose such data to any third parties,
                        including advertisers, promotional partners, sponsors,
                        and others.
                      </p>
                      <p id="section-3">
                        3.{" "}
                        <strong>
                          HOW WE DISCLOSE THE INFORMATION WE COLLECT
                        </strong>
                      </p>
                      <p>
                        Upward discloses the information we collect from and
                        about you for the following business and operational
                        purposes:
                      </p>
                      <p>
                        &#9679; <strong>Potential Employers.</strong> To provide
                        the Service, we may disclose your contact information
                        and employment information with potential employers, as
                        well as with their affiliates, agencies and service
                        providers (e.g., their applicant tracking system
                        providers). You should refer to the potential employer’s
                        terms of service and privacy policy for more information
                        on what information on how they will use, share, and
                        protect your information.
                      </p>
                      <p>
                        &#9679; <strong>Service Providers.</strong> To provide
                        the Service, we disclose your information to third
                        parties that perform services on our behalf, such as
                        billing, payment processing, advertising, analytics,
                        data storage and processing, customer support, security,
                        fraud prevention, and other, similar services.
                      </p>
                      <p>
                        &#9679; <strong>Affiliates.</strong> Upward provides
                        access to or discloses your information to our
                        affiliates who may use your information for the same
                        purposes described in <a href="#section-2">Section 2</a>{" "}
                        of this Privacy Policy to operate and improve their
                        respective services.
                      </p>
                      <p>
                        &#9679;
                        <strong>
                          For Legal Reasons or the Protection of Upward.net and
                          Others.
                        </strong>
                        Upward will disclose the information we collect from and
                        about you to (1) comply with relevant law, subpoena,
                        warrant or other legal proceeding; (2) enforce our Terms
                        of Use or this Policy; (3) prevent physical harm or
                        financial loss or in connection with an investigation of
                        suspected or actual illegal activity; or (4) as we
                        determine is necessary to protect and defend our rights,
                        other users of our service, or third parties. This
                        includes exchanging information with other companies and
                        organizations for fraud protection, spam/malware
                        prevention, and other similar purposes.
                      </p>
                      <p>
                        &#9679;{" "}
                        <strong>Surveys &amp; Contest Providers.</strong> Upward
                        will share your information with contest or survey
                        sponsors and/or administrators. In accordance with law,
                        if you are the winner of a contest or sweepstakes,
                        Upward may disclose your first name and city/state on a
                        publicly available winners’ list.
                      </p>
                      <p>
                        &#9679; <strong>Business Transfers.</strong> In a
                        transfer, merger, reorganization, sale or similar event
                        of the company or its assets, your information may be
                        transferred subject to any actual or potential acquirer.
                        If such transfer is subject to any mandatory
                        restrictions under applicable laws, Upward will comply
                        with those restrictions.
                      </p>
                      <p>
                        &#9679;{" "}
                        <strong>Other Unaffiliated Third Parties.</strong>{" "}
                        Upward may also disclose your information to other
                        unaffiliated third parties such as third parties we
                        collaborate with in connection with integration,
                        co-marketing, and certain other coordinated efforts and
                        other third parties whose offers we believe you may
                        value. Upward will not disclose mobile phone numbers
                        collected solely through our SMS text programs to
                        unaffiliated third parties for their marketing purposes
                        without your consent.
                      </p>
                      <p>
                        &#9679; <strong>Your Consent.</strong> If you consent to
                        our disclosure of your information, Upward will disclose
                        your information consistent with your consent.
                      </p>
                      <p>
                        Upward may disclose aggregate or de-identified
                        information which cannot be used to identify an
                        individual to any third party, including advertisers,
                        promotional partners, and sponsors, at our discretion.
                      </p>
                      <p id="section-4">
                        <strong>4. YOUR RIGHTS AND CHOICES</strong>
                      </p>
                      <p>
                        You have the following rights and choices with respect
                        to your information:
                      </p>
                      <p>
                        <strong>a. </strong>
                        <strong>Rights Regarding Your Information</strong>
                      </p>
                      <p>
                        Depending on where you live, you may have certain rights
                        with respect to your personal information. We extend
                        these rights to all individuals who are entitled to such
                        rights under applicable law and may extend some or all
                        of these rights to all individuals, regardless of
                        residency, subject to this Privacy Policy. Please note 
                        that if you are a Colorado resident, all of the rights 
                        in this section will apply to you. Specifically,
                        applicable individuals have the right to ask us to:
                      </p>
                      <p>
                        &#9679; Inform you about the categories of personal
                        information we collect or disclose about you; the
                        categories of sources of such information; the business
                        or commercial purpose for collecting your personal
                        information; and the categories of third parties with
                        whom we disclose personal information.
                      </p>
                      <p>
                        &#9679; Provide you access to and/or a copy of certain
                        personal information we hold about you in some cases in
                        a portable format, if technically feasible.
                      </p>
                      <p>
                        &#9679; Correct or update personal information we hold
                        about you.
                      </p>
                      <p>
                        &#9679; Delete certain personal information we have
                        about you.
                      </p>
                      <p>
                        &#9679; Opt out of the processing of your personal
                        information for purposes of profiling in furtherance of
                        decisions that produce legal or similarly significant
                        effects, if applicable.
                      </p>
                      <p>
                        To provide some of these rights, we may need to take
                        reasonable steps to verify your identity before
                        responding to a request, which may include, at a
                        minimum, depending on the sensitivity of the information
                        you are requesting and the type of request you are
                        making, verifying your name and email address. You may
                        also be permitted to designate an authorized agent to
                        submit certain requests on your behalf. In order for an
                        authorized agent to be verified, you must provide the
                        authorized agent with signed, written permission to make
                        such requests or a power of attorney. We may also follow
                        up with you to verify your identity before processing
                        the authorized agent's request.
                      </p>
                      <p>
                        Applicable individuals may also have the right to opt
                        out of “sales” of your information and “sharing/use of
                        your information for targeted advertising” as described
                        below in subsection c. In addition, we do not
                        discriminate against you for exercising your rights.
                      </p>
                      <p>
                        There are certain exemptions that may apply. For
                        example, we need to retain certain information to
                        provide the Services to you.
                      </p>
                      <p>
                        If you would like further information regarding your
                        legal rights or would like to exercise any of them,
                        please click{" "}
                        <a
                          href={
                            window.location.hostname === "www.jobtomic.com"
                              ? "https://privacyportal.onetrust.com/webform/d6c0258c-bfbe-4326-8414-aaa9dea289be/5ec666ad-a354-4adb-835a-97010b457d5e?website=jobtomic.com"
                              : "https://privacyportal.onetrust.com/webform/d6c0258c-bfbe-4326-8414-aaa9dea289be/e2eb1322-2c64-440c-92d5-440d08b25b5b?website=upward.net"
                          }
                        >
                          here
                        </a>{" "}
                        or contact us at{" "}
                        <a href="mailto:datarequests@upward.net">
                          datarequests@upward.net
                        </a>
                        .
                      </p>
                      <p>
                        Depending on where you live, you may have the right to
                        appeal our decision to deny your request. If we deny
                        your request, we will provide you with information on
                        how to appeal the decision, if applicable, in our
                        communications with you.
                      </p>
                      <p>
                        <strong>
                          b. Opting Out of Marketing Communications
                        </strong>
                      </p>
                      <p>
                        <u>Email marketing</u>. If you wish to opt-out from
                        receiving additional email offers, advertisements and
                        promotions relating to this website, please click on the
                        “unsubscribe” link in our emails to "opt-out" or email{" "}
                        <a href="mailto:support@upward.net">
                          support@upward.net
                        </a>{" "}
                        with “Email unsubscribe” in the subject line. Please
                        note that it may take us some time, consistent with our
                        legal obligations, to process your request. Even if you
                        opt out from receiving marketing messages from us, you
                        will continue to receive administrative messages from
                        us, updates to our policies and practices, and other
                        communications regarding our relationship or
                        transactions with you.
                      </p>
                      <p>
                        <u>SMS marketing</u>. To stop receiving text messages
                        from us, text “STOP” in response to any text message, or
                        send an email to{" "}
                        <a href="mailto:support@Upward.net">
                          support@Upward.net
                        </a>{" "}
                        with “SMS unsubscribe” in the subject line.
                      </p>
                      <p>
                        <strong>
                          c. Opting Out of Sales of Personal Information and
                          Processing/Sharing of Personal Information for
                          Targeted Advertising Purposes
                        </strong>
                      </p>
                      <p>
                        As explained in the “Other Unaffiliated Third Parties”
                        provision in <a href="#section-4">Section 4</a> above,
                        we sometimes disclose information (e.g., usage
                        information, online identifiers, and/or hashed email
                        addresses) to unaffiliated third parties that we
                        collaborate with or that provide online targeted
                        advertising that we think may be of value to you, or
                        that assist us with analytics. These disclosures may be
                        considered a “sale” or the processing/sharing of
                        personal information for targeted advertising purposes
                        under applicable law.
                      </p>
                      <p>
                        If you would like to opt out of our <em>online</em>{" "}
                        disclosure of your personal information for purposes
                        that could be considered “sales” or “sharing” for
                        purposes of targeted advertising, please click{" "}
                        <u>
                          <a href="#" onClick={openCmpDrawer}>
                            here
                          </a>
                        </u>
                        . You can also submit a request to opt out of our{" "}
                        <em>offline</em> disclosures of information that are
                        subject to applicable opt out rights by clicking click{" "}
                        <u>
                          <a href="https://privacyportal.onetrust.com/webform/d6c0258c-bfbe-4326-8414-aaa9dea289be/56367eaf-45d3-4338-b098-59b38b4a8dbe?website=upward.net">
                            here
                          </a>
                        </u>{" "}
                        or by emailing us at{" "}
                        <a href="mailto:datarequests@upward.net">
                          datarequests@upward.net
                        </a>{" "}
                        with a subject line of “Offline Opt Out”.
                      </p>
                      <p>
                        Please note that if you have a legally-recognized
                        browser-based opt out preference signal turned on via
                        your device browser, we recognize such preference in
                        accordance with applicable law.
                      </p>
                      <p id="section-5">
                        <strong>5. ONLINE AND MOBILE APP ADVERTISING</strong>
                      </p>
                      <p>
                        <strong>a. Online Advertising</strong>
                      </p>
                      <p>
                        As part of our Service, we may allow third-party
                        advertising technologies, including ad networks and ad
                        servers, that use cookies to deliver relevant and
                        targeted content and advertising to you. These ads may
                        be based on various factors, including the content of
                        the page you are visiting, information you provide, your
                        searches, demographic data, and other information we
                        collect about you. These ads may be based on your
                        current activity or your activity over time and across
                        other websites and online services and may be tailored
                        to your interests.
                      </p>
                      <p>
                        Upward sometimes associates cookies with de-identified
                        data linked to or derived from data you have submitted
                        to us (e.g., your email address) and disclose such
                        information to advertising partners and vendors in
                        hashed, non-human-readable form to provide you with more
                        relevant ads when you visit other websites and mobile
                        applications.
                      </p>
                      <p>
                        This Privacy Policy does not govern, the use of cookies
                        that may be placed on your device(s) you use to access
                        our Services by non-affiliated third parties.
                      </p>
                      <p>
                        If you are interested in more information about tailored
                        browser advertising and how you can generally control
                        cookies from being put on your devices to deliver
                        tailored advertising, please visit the
                        <a href="http://optout.networkadvertising.org">
                          Network Advertising Initiative’s Consumer Opt-Out link
                        </a>
                        or the
                        <a href="http://optout.aboutads.info">
                          Digital Advertising Alliance’s Consumer Opt-Out
                        </a>
                        link to opt out of receiving tailored advertising from
                        companies that participate in those programs. To opt out
                        of Google Analytics for display advertising or customize
                        Google display network ads, please visit the
                        <a href="https://adssettings.google.com/authenticated">
                          Google Ads Settings
                        </a>
                        page. For additional ways to opt out of targeted
                        advertising activities under applicable law, see Section
                        5 below.
                      </p>
                      <p>
                        Please note that these opt-outs apply per browser and
                        per device, so you will have to opt out for each
                        device—and each browser on each device—through which you
                        access our Services.
                      </p>
                      <p>
                        <strong>b. Notice Concerning Do Not Track.</strong>
                      </p>
                      <p>
                        Do Not Track (“DNT”) is a privacy preference that is
                        available in certain web browsers. Upward is committed
                        to providing you with meaningful choices about the
                        information collected on our Services for third party
                        purposes and provide information on how to opt-out where
                        possible above. However, we do not currently recognize
                        or respond to browser-initiated DNT signals. Learn more
                        about <a href="https://allaboutdnt.com">Do Not Track</a>
                        . Please note that Do Not Track is a different privacy
                        mechanism than the user “preference signal” referenced
                        in the “Your Rights and Choices” section in{" "}
                        <a href="#section-5">Section 5</a>
                        below, which is a browser-based control that indicates
                        whether you would like to opt out of processing of your
                        information for certain purposes under applicable laws,
                        such as the “sale” of your information or the disclosure
                        of your information for targeted advertising purposes.
                        Please see the “Your Rights and Choices” section in
                        <a href="#section-5">Section 5</a> below for more
                        information.
                      </p>
                      <p id="section-6">
                        6. <strong>THIRD-PARTY LINKS AND FEATURES</strong>
                      </p>
                      <p>
                        For your convenience and to improve the usage of the
                        website we may insert links to third-party websites and
                        third-party plug-ins, such as Facebook or LinkedIn. This
                        Policy does not apply to such third party websites.
                        These links take you outside of our service and off of
                        our website and are beyond our control. The websites
                        that such links may direct you to have their own
                        separate Privacy Policies. We are not liable for the
                        content and activities of those websites. Your visits
                        and access to such websites is at your own risk. Please
                        note that third parties' sites may utilize their own
                        cookies with users, collect users' data, and/or solicit
                        users' Personally Identifiable Information. We encourage
                        you to read their privacy policies before using their
                        websites or services or giving them your information.
                      </p>
                      <p id="section-7">
                        7.{" "}
                        <strong>
                          HOW WE PROTECT AND STORE YOUR INFORMATION
                        </strong>
                      </p>
                      <p>
                        In order to protect both the Personally Identifiable
                        Information and the general information that we receive
                        from you, we have implemented various security measures.
                        All information you provide to us is stored on our
                        secure servers behind firewalls and we do not store any
                        financial information. The safety and security of your
                        information also depends on you. Where you have chosen a
                        password for access to certain parts of our website, you
                        are responsible for keeping the password confidential.
                        Unfortunately, no data transmission over the internet or
                        wireless network can be guaranteed to be completely
                        secure, so while we strive to protect your Personally
                        Identifiable Information, you understand that there are
                        security and privacy limitations which are beyond our
                        control, privacy and security cannot be guaranteed, and
                        information can be viewed or tampered with when in
                        transit by a third party.
                      </p>
                      <p>
                        Upward will keep your information for as long as
                        necessary to fulfill the purposes described above,
                        unless a longer retention is required or permitted by
                        law. Upward may delete or de-identify your information
                        sooner if we receive a verifiable deletion request,
                        subject to exemptions under applicable law. The
                        retention period that applies to your information may
                        vary depending on the Services you use and how you
                        interact with them. Upward may retain cached or archived
                        copies of your information.
                      </p>
                      <p id="section-8">
                        8. <strong>CHILDREN</strong>
                      </p>
                      <p>
                        Upward.net and its services are not intended for minors
                        and, per our Terms of Use, users must be 16 years of age
                        to use our website. We do not knowingly collect or
                        solicit Personally Identifiable Information from anyone
                        under the age of 16 or knowingly allow such persons to
                        register to use the service. No one under the age of 16
                        is allowed to provide any Personally Identifiable
                        Information to or on Upward.net or its services. If you
                        become aware that a child has provided us with
                        Personally Identifiable Information without parental
                        consent, please contact us. If we become aware that a
                        child under 16 years of age has provided us with
                        Personally Identifiable Information, we will delete such
                        information as quickly as possible.
                      </p>
                      <p id="section-9">
                        9. <strong>INTERNATIONAL USERS</strong>
                      </p>
                      <p>
                        Please note that we are located in the United States and
                        provides the Services, and processes your data, in the
                        United States. We may subcontract the processing of your
                        data to, or otherwise disclose your data to, service
                        providers and business partners in countries other than
                        your country of residence, including the United States,
                        in accordance with applicable law. Such third parties
                        may be engaged in, among other things, the provision of
                        our Services to you, the processing of transactions,
                        and/or the provision of support services. By providing
                        us with your information, you acknowledge and agree to
                        any such transfer, storage, or use.
                      </p>
                      <p id="section-10">
                        10. <strong>CALIFORNIA PRIVACY NOTICE</strong>
                      </p>
                      <p>
                        This California Privacy Notice (“Notice”) gives
                        California residents the additional information about
                        our information collection and use practices required by
                        the California Consumer Privacy Act of 2018 (“CCPA”), as
                        amended by the California Privacy Rights Act of 2020. If
                        we use a term that is capitalized in this Notice and we
                        don’t define it herein, you can find the definition for
                        the term within this Privacy Policy.
                      </p>
                      <p>
                        This Notice provides information about the categories of
                        personal information that we collect from California
                        residents, the purposes for which we use the
                        information, the categories of third parties to whom we
                        disclose the information for business purposes, our
                        “sales” and “sharing” of information, and retention
                        practices.
                      </p>
                      <table
                        border="1"
                        cellspacing="0"
                        cellpadding="0"
                        width="100%"
                      >
                        <tbody>
                          <tr style={{ background: "lightgrey" }}>
                            <td
                              style={{ padding: "10px" }}
                              width="168"
                              valign="top"
                            >
                              <p>
                                <strong>
                                  Categories of information collected{" "}
                                </strong>
                                (<em>see</em> <a href="#section-1">Section 1</a>
                                , “Information We Collect” in this Privacy
                                Policy for additional information)
                              </p>
                            </td>
                            <td
                              style={{ padding: "10px" }}
                              width="228"
                              valign="top"
                            >
                              <p>
                                <strong>Purpose of use</strong>
                              </p>
                            </td>
                            <td
                              style={{ padding: "10px" }}
                              width="228"
                              valign="top"
                            >
                              <p>
                                <strong>
                                  Categories of third parties to whom we
                                  disclose the information for business and
                                  operational purposes
                                </strong>
                                (<em>see</em> <a href="#section-3">Section 3</a>
                                , “How We Disclose the Information We Collect”
                                in this Privacy Policy for additional
                                information)
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ padding: "10px" }}
                              width="168"
                              valign="top"
                            >
                              <p>
                                <strong>Identifiers</strong>
                                <br />
                                <br />
                                <br />
                                such as name, address, and email address; and
                                information about your browser or device such as
                                your IP address and device IDs
                              </p>
                            </td>
                            <td
                              style={{ padding: "10px" }}
                              width="228"
                              valign="top"
                            >
                              <p>&#9679; Register users</p>
                              <p>
                                &#9679; Provide the Services and customer
                                service
                              </p>
                              <p>&#9679; Communicate with you</p>
                              <p>
                                &#9679; Personalize your experience and
                                customize the Services
                              </p>
                              <p>
                                &#9679; Understand your interests and engage in
                                analysis
                              </p>
                              <p>&#9679; Engage in marketing and advertising</p>
                              <p>
                                &#9679; Detect bugs and identify errors and
                                improve the Services
                              </p>
                              <p>
                                &#9679; Increase security, prevent fraud, and
                                promote legal compliance
                              </p>
                            </td>
                            <td
                              style={{ padding: "10px" }}
                              width="228"
                              valign="top"
                            >
                              <p>
                                &#9679; Potential employers, employment agencies
                                and other business partners
                              </p>
                              <p>&#9679; Service providers</p>
                              <p>
                                &#9679; Service providers for employers and
                                agencies
                              </p>
                              <p>
                                &#9679; Ad networks and advertising partners
                              </p>
                              <p>&#9679; Affiliates</p>
                              <p>
                                &#9679; Entities to assist with legal and
                                security purposes
                              </p>
                              <p>
                                &#9679; Entities in connection with sales or
                                transfer of business or assets
                              </p>
                              <p>&#9679; Others with your consent</p>
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ padding: "10px" }}
                              width="168"
                              valign="top"
                            >
                              <p>
                                <strong>Commercial Information</strong>
                              </p>
                              <p>
                                <strong> </strong>
                              </p>
                              <p>such as transaction history</p>
                            </td>
                            <td
                              style={{ padding: "10px" }}
                              width="228"
                              valign="top"
                            >
                              <p>
                                &#9679; Provide the Services and customer
                                service
                              </p>
                              <p>
                                &#9679; Understand your interests and engage in
                                analysis
                              </p>
                              <p>&#9679; Communicate with you</p>
                              <p>
                                &#9679; Personalize your experience and
                                customize the Services
                              </p>
                              <p>&#9679; Engage in marketing and advertising</p>
                              <p>
                                &#9679; Detect bugs and identify errors and
                                improve the Services
                              </p>
                              <p>
                                &#9679; Increase security, prevent fraud, and
                                promote legal compliance
                              </p>
                            </td>
                            <td
                              style={{ padding: "10px" }}
                              width="228"
                              valign="top"
                            >
                              <p>&#9679; Service providers</p>
                              <p>&#9679; Affiliates</p>
                              <p>
                                &#9679; Entities to assist with legal and
                                security purposes
                              </p>
                              <p>
                                &#9679; Entities in connection with sales or
                                transfer of business or assets
                              </p>
                              <p>&#9679; Others with your consent</p>
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ padding: "10px" }}
                              width="168"
                              valign="top"
                            >
                              <p>
                                <strong>Customer service information</strong>
                                <br />
                                <br />
                                <br />
                                such as questions and other messages you address
                                to us directly through online forms, by email,
                                over the phone, or by post
                              </p>
                            </td>
                            <td
                              style={{ padding: "10px" }}
                              width="228"
                              valign="top"
                            >
                              <p>
                                &#9679; Provide the Services and customer
                                service
                              </p>
                              <p>&#9679; Communicate with you</p>
                              <p>&#9679; Improve the Services</p>
                              <p>
                                &#9679; Detect bugs and identify errors and
                                improve the Services
                              </p>
                              <p>
                                &#9679; Increase security, prevent fraud, and
                                promote legal compliance
                              </p>
                            </td>
                            <td
                              style={{ padding: "10px" }}
                              width="228"
                              valign="top"
                            >
                              <p>&#9679; Service providers</p>
                              <p>&#9679; Affiliates</p>
                              <p>
                                &#9679; Entities to assist with legal and
                                security purposes
                              </p>
                              <p>
                                &#9679; Entities in connection with sales or
                                transfer of business or assets
                              </p>
                              <p>&#9679; Others with your consent</p>
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ padding: "10px" }}
                              width="168"
                              valign="top"
                            >
                              <p>
                                <strong>Employment Information</strong>
                              </p>
                              <p>
                                <strong> </strong>
                              </p>
                              <p>
                                such as your employment or education history,
                                employment credentials (such as licenses or
                                professional memberships), job preferences
                                (including targeted compensation), and certain
                                personal characteristics such as your
                                military/veteran status
                              </p>
                            </td>
                            <td
                              style={{ padding: "10px" }}
                              width="228"
                              valign="top"
                            >
                              <p>
                                &#9679; Provide the Services and customer
                                service
                              </p>
                              <p>&#9679; Communicate with you</p>
                              <p>
                                &#9679; Personalize your experience and
                                customize the Services
                              </p>
                              <p>
                                &#9679; Understand your interests and engage in
                                analysis
                              </p>
                              <p>&#9679; Engage in marketing and advertising</p>
                              <p>
                                &#9679; Detect bugs and identify errors and
                                improve the Services
                              </p>
                              <p>
                                &#9679; Increase security, prevent fraud, and
                                promote legal compliance
                              </p>
                            </td>
                            <td
                              style={{ padding: "10px" }}
                              width="228"
                              valign="top"
                            >
                              <p>
                                &#9679; Potential employers, employment agencies
                                and other business partners
                              </p>
                              <p>&#9679; Service providers</p>
                              <p>
                                &#9679; Service providers for employers and
                                agencies
                              </p>
                              <p>&#9679; Affiliates</p>
                              <p>
                                &#9679; Entities to assist with legal and
                                security purposes
                              </p>
                              <p>
                                &#9679; Entities in connection with sales or
                                transfer of business or assets
                              </p>
                              <p>&#9679; Others with your consent</p>
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ padding: "10px" }}
                              width="168"
                              valign="top"
                            >
                              <p>
                                <strong>
                                  User-generated content and demographic
                                  information
                                </strong>
                                <br />
                                <br />
                                <br />
                                such as any user-generated content you
                                voluntarily decide to upload on the Services, or
                                other information you provide in connection with
                                your account such as your resume
                              </p>
                            </td>
                            <td
                              style={{ padding: "10px" }}
                              width="228"
                              valign="top"
                            >
                              <p>
                                &#9679; Provide the Services and customer
                                service
                              </p>
                              <p>&#9679; Improve the Services</p>
                              <p>
                                &#9679; Understand your interests and engage in
                                analysis
                              </p>
                              <p>
                                &#9679; Detect bugs and identify errors and
                                improve the Services
                              </p>
                              <p>
                                &#9679; Increase security, prevent fraud, and
                                promote legal compliance
                              </p>
                            </td>
                            <td
                              style={{ padding: "10px" }}
                              width="228"
                              valign="top"
                            >
                              <p>
                                &#9679; Potential employers, employment agencies
                                and other business partners
                              </p>
                              <p>&#9679; Service providers</p>
                              <p>
                                &#9679; Service providers for employers and
                                agencies
                              </p>
                              <p>&#9679; Affiliates</p>
                              <p>
                                &#9679; Entities to assist with legal and
                                security purposes
                              </p>
                              <p>
                                &#9679; Entities in connection with sales or
                                transfer of business or assets
                              </p>
                              <p>&#9679; Others with your consent</p>
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ padding: "10px" }}
                              width="168"
                              valign="top"
                            >
                              <p>
                                <strong>
                                  Internet network and device activity data
                                </strong>
                                <br />
                                <br />
                                <br />
                                such as browsing information and usage
                                information
                              </p>
                            </td>
                            <td
                              style={{ padding: "10px" }}
                              width="228"
                              valign="top"
                            >
                              <p>
                                &#9679; Provide the Services and customer
                                service
                              </p>
                              <p>
                                &#9679; Personalize your experience and
                                customize the Services
                              </p>
                              <p>&#9679; Improve the Services</p>
                              <p>
                                &#9679; Understand your interests and engage in
                                analysis
                              </p>
                              <p>&#9679; Engage in marketing and advertising</p>
                              <p>
                                &#9679; Detect bugs and identify errors and
                                improve the Services
                              </p>
                              <p>
                                &#9679; Increase security, prevent fraud, and
                                promote legal compliance
                              </p>
                            </td>
                            <td
                              style={{ padding: "10px" }}
                              width="228"
                              valign="top"
                            >
                              <p>&#9679; Service providers</p>
                              <p>
                                &#9679; Ad networks and advertising partners
                              </p>
                              <p>&#9679; Affiliates</p>
                              <p>
                                &#9679; Entities to assist with legal and
                                security purposes
                              </p>
                              <p>
                                &#9679; Entities in connection with sales or
                                transfer of business or assets
                              </p>
                              <p>&#9679; Others with your consent</p>
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ padding: "10px" }}
                              width="168"
                              valign="top"
                            >
                              <p>
                                <strong>Geolocation information</strong>
                                <br />
                                <br />
                                <br />
                                Such as your approximate location based on IP
                                address or precise location (with your consent)
                              </p>
                            </td>
                            <td
                              style={{ padding: "10px" }}
                              width="228"
                              valign="top"
                            >
                              <p>
                                &#9679; Provide the Services and customer
                                service
                              </p>
                              <p>
                                &#9679; Personalize your experience and
                                customize the Services
                              </p>
                              <p>&#9679; Improve the Services</p>
                              <p>
                                &#9679; Understand your interests and engage in
                                analysis
                              </p>
                              <p>&#9679; Engage in marketing and advertising</p>
                              <p>
                                &#9679; Detect bugs and identify errors and
                                improve the Services
                              </p>
                              <p>
                                &#9679; Increase security, prevent fraud, and
                                promote legal compliance
                              </p>
                            </td>
                            <td
                              style={{ padding: "10px" }}
                              width="228"
                              valign="top"
                            >
                              <p>&#9679; Service providers</p>
                              <p>&#9679; Affiliates</p>
                              <p>
                                &#9679; Entities to assist with legal and
                                security purposes
                              </p>
                              <p>
                                &#9679; Entities in connection with sales or
                                transfer of business or assets
                              </p>
                              <p>&#9679; Others with your consent</p>
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ padding: "10px" }}
                              width="168"
                              valign="top"
                            >
                              <p>
                                <strong>Other information</strong>
                                <br />
                                <br />
                                <br />
                                Any other information that you provide in the
                                course of using the Services
                              </p>
                            </td>
                            <td
                              style={{ padding: "10px" }}
                              width="228"
                              valign="top"
                            >
                              <p>
                                Purposes of use will depend on the additional
                                information you provide
                              </p>
                            </td>
                            <td
                              style={{ padding: "10px" }}
                              width="228"
                              valign="top"
                            >
                              <p>
                                Disclosure will depend on the additional
                                information you provide
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <p>
                        <u>Sale and Sharing of Personal Information</u>
                      </p>
                      <p>
                        As explained in <a href="#section-3">Section 3</a>, we
                        sometimes disclose personal information to third-party
                        advertising providers for targeted advertising purposes,
                        so that we can provide you with more relevant and
                        tailored ads regarding our Services, or use analytics
                        partners to assist us in analyzing use of our services
                        and our user/customer base. The disclosure of your
                        personal information to these third parties to assist us
                        in providing these services may be considered a “sale”
                        of personal information under the CCPA, or, the
                        “sharing” of your personal information for purposes of
                        “cross-context behavioral advertising.”
                      </p>
                      <p>
                        The following chart lists the categories of personal
                        information we have sold or shared over the last 12
                        months and the categories of third parties to which we
                        have sold or shared:
                      </p>
                      <table
                        border="1"
                        cellspacing="0"
                        cellpadding="0"
                        width="100%"
                      >
                        <tbody>
                          <tr style={{ background: "lightgrey" }}>
                            <td
                              style={{ padding: "10px" }}
                              width="208"
                              valign="top"
                            >
                              <p>
                                <strong>
                                  Category of Personal Information
                                </strong>
                              </p>
                            </td>
                            <td
                              style={{ padding: "10px" }}
                              width="208"
                              valign="top"
                            >
                              <p>
                                <strong>
                                  Categories of Third Parties to Which we Have
                                  “Sold” this PI
                                </strong>
                              </p>
                            </td>
                            <td
                              style={{ padding: "10px" }}
                              width="208"
                              valign="top"
                            >
                              <p>
                                <strong>
                                  Categories of Third Parties to Which we Have
                                  “Shared” this PI
                                </strong>
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ padding: "10px" }}
                              width="208"
                              valign="top"
                            >
                              <p>Identifiers</p>
                            </td>
                            <td
                              style={{ padding: "10px" }}
                              width="208"
                              valign="top"
                            >
                              <p>
                                Ad networks and advertising partners; Analytics
                                providers
                              </p>
                            </td>
                            <td
                              style={{ padding: "10px" }}
                              width="208"
                              valign="top"
                            >
                              <p>Ad networks and advertising partners</p>
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ padding: "10px" }}
                              width="208"
                              valign="top"
                            >
                              <p>Internet network and device activity data</p>
                            </td>
                            <td
                              style={{ padding: "10px" }}
                              width="208"
                              valign="top"
                            >
                              <p>
                                Ad networks and advertising partners; Analytics
                                providers
                              </p>
                            </td>
                            <td
                              style={{ padding: "10px" }}
                              width="208"
                              valign="top"
                            >
                              <p>Ad networks and advertising partners</p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <p>
                        If you would like to opt out of our online disclosure
                        such as through cookie and pixel technology of your
                        personal information for purposes that could be
                        considered a “sale” or “sharing,” please click{" "}
                        <a href="#" onClick={openCmpDrawer}>
                          here
                        </a>
                        . You can also submit a request to opt out of our
                        offline disclosures of information that are subject to
                        applicable opt out rights by clicking{" "}
                        <a href="https://privacyportal.onetrust.com/webform/d6c0258c-bfbe-4326-8414-aaa9dea289be/56367eaf-45d3-4338-b098-59b38b4a8dbe?website=upward.net">
                          here
                        </a>{" "}
                        or by emailing us at{" "}
                        <a href="mailto:datarequests@Upward.net">
                          datarequests@Upward.net
                        </a>{" "}
                        with the subject line “Offline Opt Out”. Please note
                        that we do not knowingly collect or sell the personal
                        information of minors under 16 years of age.
                      </p>
                      <p>
                        <u>Sensitive Personal Information </u>
                      </p>
                      <p>
                        The CPPA provides consumers with the "right to limit"
                        the use of their "sensitive personal information" (as
                        defined in the CCPA) to certain business purposes. As
                        explained in our Privacy Policy, we may ask you for
                        optional demographic information such as citizenship,
                        race, disability status, and ethnicity in connection
                        with certain employment opportunities. Your provision of
                        this information is entirely voluntary, and will be used
                        only for purposes in connection with providing the
                        Services to you. This information may be considered
                        "sensitive personal information" under the CCPA.
                        However, please note that we do not use sensitive
                        personal information for purposes for which the "right
                        to limit" applies.
                      </p>
                      <p>
                        Please note that if you have a legally-recognized
                        browser-based opt out preference signal turned on via
                        your device browser, we recognize such preference in
                        accordance with applicable law.
                      </p>
                      <p>
                        <u>“Shine the Light” Disclosure</u>
                      </p>
                      <p>
                        The California “Shine the Light” law gives residents of
                        California the right under certain circumstances to
                        request information about whether we disclose certain
                        categories of personal information (as defined in the
                        Shine the Light law) with third parties for their direct
                        marketing purposes. To make such a request, please email
                        us at{" "}
                        <a href="mailto:datarequests@Upward.net">
                          datarequests@Upward.net
                        </a>
                        .
                      </p>
                      <p>
                        <u>Retention</u>
                      </p>
                      <p>
                        Upward will keep your personal information for as long
                        as necessary to fulfill the purposes described above,
                        unless a longer retention is required or permitted by
                        law. Upward may delete or de-identify your information
                        sooner if we receive a verifiable deletion request that
                        complies with law, subject to exemptions under
                        applicable law. The retention period that applies to
                        your information may vary depending on the Services you
                        use and how you interact with them. Upward may retain
                        cached or archived copies of your information.
                      </p>
                      <p id="section-11">
                        11. <strong>CHANGES TO OUR PRIVACY POLICY</strong>
                      </p>
                      <p>
                        This Privacy Policy is effective as of the date stated
                        at the top of this Privacy Policy and will remain in
                        effect except with respect to any of its provisions that
                        are changed in the future, in which case the changes
                        will become effective on the date they are posted on the
                        website or we otherwise notify you of the changes. We
                        reserve the right to change this Privacy Policy at any
                        time. You should check this policy periodically as its
                        terms may change from time to time. Your continued use
                        of the website after we post any such modifications will
                        constitute your acknowledgment of the modified policy
                        and your agreement to abide and be bound by the modified
                        policy. We will also revise the "last updated" date
                        found at the beginning of this Privacy Policy when we
                        post changes to it.
                      </p>
                      <p id="section-12">
                        12. <strong>CONTACT INFORMATION</strong>
                      </p>
                      <p>
                        If you have any questions or comments about this notice,
                        the ways in which Upward.net collects and uses your
                        information described herein, your choices and rights
                        regarding such use, or wish to exercise your rights
                        under California law, please contact us by using the
                        communication means described below.
                      </p>
                      <p>
                        Email:{" "}
                        <a href="mailto:support@Upward.net">
                          support@Upward.net
                        </a>
                      </p>
                      <p>Mailing Address: Upward.net</p>
                      <p>Attention: Legal Department</p>
                      <p>8000 Jarvis Ave, Newark, CA 94560</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      </ModalBody>
    </React.Fragment>
  );
};

export default PrivacyPolicy;
