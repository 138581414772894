import axios from "axios";
import {from} from "rxjs";
import {map} from 'rxjs/operators';
import '../../constants';

export const getLocationSuggestions = (location) => (from(
      axios.get(global.API_URL + global.JOB_DIR + `/autocomplete2.jsp?type=location&query=${location}`)
   ).pipe(
      map((res) => {
            const {status, data} = res;
            if (status !== 204 && data) {
               const {results} = data;
               return results ?
                  results.map(result => ({
                     ...result,
                     label: result.value || ""
                  }))
                  : [];
            } else {
               return [];
            }
         }
      ))
);

