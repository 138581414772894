import React, { useEffect } from "react";
import { Provider } from 'react-redux';
import Routes from "./routes/Routes";
import configureStore from "./redux/store";

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const materialTheme = createMuiTheme({
  typography: {
    fontFamily: [
      "apercu-extralight"
    ]
  },
  palette: {
    error: {
      light: "#F6999A",
      main: "#EB5757",
      dark: "#EB5757"
    }
  }
});

const store = configureStore();

const App = () => {
  return (
    <>
      <ThemeProvider theme={materialTheme}>
        <Provider store={store}>
          <Routes />
        </Provider>
      </ThemeProvider>
    </>
  );
}

export default App;
