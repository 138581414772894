import * as types from './constants';

const defaultState = {
    fname: "",
    lname: "",
    email: "",
    home_phone: "",
    address1: "",
    city: "",
    state: "",
    zip: "",
    statusCode: "",
    isLoading: true,
    browserNotificationEnabled: "n",
    smsStatus: null,
    loadError: "",
    hasResumeFile: false,
    resumeUpdateDate: "",
    resumeFilePath: "",
    isLoggedIn: false
}


export default function (state = defaultState, action) {
    switch (action.type) {
        case types.JOB_SEEKER_FETCH_PROFILE_BEGIN:
            return {
                ...state,
                loadError: "",
                isLoading: true
            }
        case types.JOB_SEEKER_UPDATE_PROFILE:
            return {
                ...state,
                ...action.payload,
                isLoading: false,
                isLoggedIn: true
            }
        case types.JOB_SEEKER_FETCH_PROFILE_ERROR:
            return {
                ...state,
                isLoading: false,
                loadError: action.payload,
                isLoggedIn: false
            }
        default:
            return state;
    }
}
