import * as types from './constants';
import { debounceTime, switchMap, delay, mergeMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';


import { getJobSeekerProfile, getJobSeekerResume } from "../../services/ProfileService";
import { getSiteValues } from "../../services/SiteDataService";


export const fetchJobSeekerProfile = () => {
    console.log("fetching job seeker profile")
    return ({
        type: types.JOB_SEEKER_FETCH_PROFILE_DETAILS
    });
};

export const fetchJobSeekerResume = () => {
    return ({
        type: types.JOB_SEEKER_FETCH_RESUME_DETAILS
    });
};

export const fetchSiteDetails = () => {
    return ({
        type: types.JOB_SEEKER_FETCH_SITE_DETAILS
    });
};


export const fetchJobSeekerProfileFromServerEpic = (action$) => {
    return action$.pipe(
        ofType(types.JOB_SEEKER_FETCH_PROFILE_DETAILS),
        debounceTime(400),
        switchMap((action) => {
            return merge(
                of({ type: types.JOB_SEEKER_FETCH_PROFILE_BEGIN }),
                getJobSeekerProfile().pipe(
                    delay(1000),
                    mergeMap((data) => {
                        if (data && data.email) {
                            return of({
                                type: types.JOB_SEEKER_UPDATE_PROFILE,
                                payload: data
                            })
                        } else {
                            return of({
                                type: types.JOB_SEEKER_FETCH_PROFILE_ERROR,
                                payload: "Error fetching user profile."
                            })
                        }
                    }),
                    catchError((err) => {
                        return {
                            type: types.JOB_SEEKER_FETCH_PROFILE_ERROR,
                            payload: "Error fetching user profile."
                        }
                    })
                )
            )
        })
    )
}



export const fetchJobSeekerResumeFromServerEpic = (action$) => {
    return action$.pipe(
        ofType(types.JOB_SEEKER_FETCH_RESUME_DETAILS),
        debounceTime(400),
        switchMap((action) => {
            return merge(
                of({ type: types.JOB_SEEKER_FETCH_RESUME_DETAILS_BEGIN }),
                getJobSeekerResume().pipe(
                    delay(1000),
                    mergeMap((data) => {
                        if (data && data.summary) {
                            return of({
                                type: types.JOB_SEEKER_UPDATE_RESUME_DETAILS,
                                payload: data
                            })
                        } else {
                            return of({
                                type: types.JOB_SEEKER_FETCH_RESUME_DETAILS_ERROR,
                                payload: "Error fetching user profile."
                            })
                        }
                    }),
                    catchError((err) => {
                        return {
                            type: types.JOB_SEEKER_FETCH_RESUME_DETAILS_ERROR,
                            payload: "Error fetching user profile."
                        }
                    })
                )
            )
        })
    )
}

export const fetchSiteValuesFromServerEpic = (action$) => {
    return action$.pipe(
        ofType(types.JOB_SEEKER_FETCH_SITE_DETAILS),
        debounceTime(100),
        switchMap((action) => {
            return merge(
                of({ type: types.JOB_SEEKER_FETCH_SITE_DETAILS_BEGIN }),
                getSiteValues().pipe(
                    mergeMap((data) => {
                        if (data && data.siteId) {
                            return of({
                                type: types.JOB_SEEKER_UPDATE_SITE_DETAILS,
                                payload: data
                            })
                        } else {
                            return of({
                                type: types.JOB_SEEKER_FETCH_SITE_DETAILS_ERROR,
                                payload: "Error fetching site values."
                            })
                        }
                    }),
                    catchError((err) => {
                        return {
                            type: types.JOB_SEEKER_FETCH_SITE_DETAILS_ERROR,
                            payload: "Error fetching site values."
                        }
                    })
                )
            )
        })
    )
}
