import * as types from './constants';

const defaultState = {
    summary: "",
    education: [],
    work_experience: [],
    isLoading: false,
    loadError: ""
}


export default function (state = defaultState, action) {
    switch (action.type) {
        case types.JOB_SEEKER_FETCH_RESUME_DETAILS_BEGIN:
            return {
                ...state,
                loadError: "",
                isLoading: true
            }
        case types.JOB_SEEKER_UPDATE_RESUME_DETAILS:
            return {
                ...state,
                summary: action.payload.summary,
                education: [...action.payload.education],
                work_experience: [...action.payload.work_experience],
                isLoading: false
            }
        case types.JOB_SEEKER_FETCH_RESUME_DETAILS_ERROR:
            return {
                ...state,
                isLoading: false,
                loadError: action.payload
            }
        default:
            return state;
    }
}
