import React, { Fragment } from 'react';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';



export const MaterialTextField = ({
    label,
    input,
    meta: { touched, invalid, error },
    showHelper,
    ...custom
}) => (
    <Fragment>
        <TextField
        label={label}
        error={touched && invalid}
        {...( showHelper ? {"helperText": touched && error } : {} ) }
        {...input}
        {...custom}
        />
    </Fragment>
    )

export const MaterialCheckbox = ({ input, label }) => (
    <div>
        <FormControlLabel
            control={
                <Checkbox
                    checked={input.value ? true : false}
                    onChange={input.onChange}
                />
            }
            label={label}
        />
    </div>
)

export const radioButton = ({ input, ...rest }) => (
    <FormControl>
        <RadioGroup {...input} {...rest}>
            <FormControlLabel value="female" control={<Radio />} label="Female" />
            <FormControlLabel value="male" control={<Radio />} label="Male" />
            <FormControlLabel value="other" control={<Radio />} label="Other" />
        </RadioGroup>
    </FormControl>
)

export const MaterialFromHelper = ({ touched, error }) => {
    if (!(touched && error)) {
        return
    } else {
        return <FormHelperText>{touched && error}</FormHelperText>
    }
}

export const MaterialSelectField = ({
    input,
    label,
    meta: { touched, error },
    children,
    name,
    id,
    showHelper,
    style,
    className,
    ...custom
}) => (
        <FormControl error={touched && !!error} className={className ? className : ""}>
            <InputLabel>{label}</InputLabel>
            <Select
                native
                {...input}
                {...custom}
                inputProps={{
                    name: name,
                    id: id
                }}
            >
                {children}
            </Select>
        </FormControl>
    )


export const GenerateMaterialSelectOptions = ({
    options
}) => (
    <Fragment>
        <option value=""></option>
        {options.map( ( item, index ) => (
            <option key={index} value={item.value} >{item.label}</option>
        ) )}
    </Fragment>
)