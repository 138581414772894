//import React from "react";
import axios from "axios";
import { from, of } from "rxjs";
import { map, catchError, first } from 'rxjs/operators';
import queryString from "query-string";
import '../../../constants';

const currentDomain = window.location.protocol + "//"+ window.location.host;

export const requestDomain = process.env['REACT_APP_ENV'] === 'dev' ? process.env['REACT_APP_JOB_RESULTS_APP_BASE_URL'] : currentDomain.replace(".net", ".careers");

export const netDomain = currentDomain.replace(".careers", ".net");

export const logInUser = (params) => {
  if( !params || !params.username || !params.password ){
    return of( { "response" : "failure", "message" : "Enter login details." } );
  }
  params.domainSetting = currentDomain;
  params.withCredentials = true;
  return from (
    axios.post(global.API_URL + global.JOB_DIR + '/employee/login_json.jsp', queryString.stringify(params), {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
    })
  ).pipe(
    first(),
    map( (response) => {
      if (response.data && response.data.loginStatus === 'success') {
        return { "response" : "success", "message" : "" }
      } else {
        return ({ "response": "failure", "message": "Invalid login credentials." });
      }
    }),
    catchError((err) => { console.log(err); return of({ "response": "failure", "message": "Try again." } ) } )
  )
}

export const checkIfLoggedIn = (event) => {
  return from (
    axios.get(global.API_URL + global.JOB_DIR + '/employee/login_check.jsp', {
      'withCredentials': true,
      params: { 'domainSetting': currentDomain}
  })
  ).pipe(
    first(),
    map( (response) => {
      if (response.data && response.data.checkStatus === 'success') {
        return { "response" : "success" }
      } else {
        return { "response": "failure" };
      }
    }),
    catchError((err) => { console.log(err); return of({ "response": "failure", "message": "Try again." } ) } )
  )
}

export const resetUserPassword = (params) => {
  if( !params || !params.email ){
    return of( { "response" : "failure", "message" : "Enter login details." } );
  }

  return from (
    axios.post(global.API_URL + global.JOB_DIR + '/employee/systemEmail.jsp', queryString.stringify(params), {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
   })
).pipe(
    first(),
    map( (response) => {
      if (response.data && response.data === 1) {
        return { "response" : "success", "message" : "You should receive a password reset email in the next 15 minutes." }
      } else {
        return ({ "response": "failure", "message": "Invalid Email." });
      }
    }),
    catchError((err) => { console.log(err); return of({ "response": "failure", "message": "Try again." } ) } )
  )
}
