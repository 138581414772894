import React, {useEffect} from "react";
import {
   cityInput$,
   stateInput$,
   zipObservable$
} from "ui/util/rxjs/observables";
import UpwardReduxInput from "./UpwardReduxInput";
import {getCityStateFromZip} from "services/autofill";

// Auto fills city and state if there are 5 digits entered
const ReduxZipInputAutofill = (props) => {
   useEffect(() => {
      const subscription = zipObservable$.subscribe(zip => {
         props.input.onChange(zip);
      });
      return () => {
         if (subscription) {
            subscription.unsubscribe();
         }
      }
   }, []);

   useEffect(() => {
      const value = props.input.value;
      if (value.length === 5) {
         getCityStateFromZip(value).subscribe(({found, city, state}) => {
            if (found) {
               cityInput$.next(city);
               stateInput$.next(state);
            }
         })
      }
   }, [props.input.value]);

   return <UpwardReduxInput {...props}/>
};

export default ReduxZipInputAutofill;