import React, { useContext, useState } from "react";
import axios from "axios";
import { from, of } from "rxjs";
import { map, first, catchError } from "rxjs/operators";
import { Field, reduxForm } from "redux-form";
import { Col, Container, Form, FormGroup } from "reactstrap";
import FormValidators from "ui/FormValidators";

import { ThemeContext } from "../employer/context/ThemeContext";

import { 
    MaterialTextField,
    GenerateMaterialSelectOptions, 
    MaterialSelectField,
    UpwardLoadingButton
} from "ui";

const isUpward = window.location.href.indexOf('upward') > -1;

const enquiryOptions = [
    { "value": "general-inquiry", "label": "General Inquiry" },
    { "value": "employer", "label": "Employer" },
    { "value": "partner-inquiry", "label": "Partner Inquiry" }
];

const submitToBackend = ( data ) => {
    return from(
        axios.get(global.API_URL + "/search/postLead.fsn?CID=873", {
            params : data
        } )
    ).pipe(
        first(),
        map((response) => {
            if (response && response.data && response.data.indexOf("SUCCESS") > -1 ) {
                return { "response": "success", "message": "Your request submitted successfully." };
            } else {
                return { "response": "failure", "message": "Error submitting your request." };
            }
        }),
        catchError((err) => {
            console.log(err);
            return of( { "response": "failure", "message": "Error submitting your request." } );
        })
    )
}

const submitToBackend2 = ( data ) => {
    return from(
        axios.post(global.API_URL + global.JOB_DIR + "/contact.jsp", {
            data
        } )
    ).pipe(
        first(),
        map((response) => {
            return { "response": "success", "message": "Your request submitted successfully." };
        }),
        catchError((err) => {
            console.log(err);
            return of( { "response": "failure", "message": "Error submitting your request." } );
        })
    )
}

const ContactForm = (props) => {
    const context = useContext( ThemeContext );
    const [ isLoading, setIsLoading ] = useState( false );
    const [ responseMessage, setResponseMessage ] = useState( "" );
    const [ responseType, setResponseType ] = useState( false );
    const { handleSubmit } = props;

    const handleContactSubmit = (values) => {
        setIsLoading( true );
        if(isUpward){
            submitToBackend( values ).subscribe(
                (resp) => {
                    setResponseMessage( resp.message );
                    setResponseType( resp.response === "success" );
                    setIsLoading( false );
                }
            )
        } else {
            submitToBackend2( values ).subscribe(
                (resp) => {
                    setResponseMessage( "Your request submitted successfully." );
                    setResponseType( resp.response === "success" );
                    setIsLoading( false );
                }
            )
            setTimeout(function() { props.onClose(); }, 3000);
        }
    }

    return (
        <section className={"upward_contact_form "}>
            <Container>
                <Col xs="12" className="text-center">
                    <Form noValidate onSubmit={handleSubmit( handleContactSubmit )}>
                        { responseMessage.length > 0 && 
                            <div className={"text-dark-"+( responseType ? "green" : "red" )} >{responseMessage}</div>
                        }
                        <FormGroup>
                            <Field name="fname" label="Your Name" type="text"
                            fullWidth
                            component={MaterialTextField}
                            className="form-control-material"
                            validate={[FormValidators.required]} />
                        </FormGroup>
                        <FormGroup>
                            <Field name="email" label="Email" type="email"
                            fullWidth
                            validate={[FormValidators.required,FormValidators.email]}
                            component={MaterialTextField}
                            />
                        </FormGroup>
                        {!isUpward && <FormGroup>
                            <Field name="subject" label="Subject" type="text"
                            fullWidth
                            component={MaterialTextField}
                            className="form-control-material"
                            validate={[FormValidators.required]}
                            />
                        </FormGroup>}
                        {isUpward && <FormGroup>
                            <Field name="subject" label="Inquiry" type="text" 
                            className="w-100"
                            validate={[FormValidators.required]}
                            component={MaterialSelectField}
                            >
                                <GenerateMaterialSelectOptions options={enquiryOptions} />
                            </Field>
                        </FormGroup>}
                        <FormGroup>
                            <Field name={isUpward ? "message" : "comment"} label="Message" rows="6"
                            fullWidth
                            multiline
                            validate={[FormValidators.required]}
                            component={MaterialTextField}
                            />
                        </FormGroup>
                        <UpwardLoadingButton type="submit" isLoading={isLoading} size="lg" className={"btn-rounded btn-dark-" + context}><b>Send</b></UpwardLoadingButton>
                    </Form>
                </Col>
            </Container>
        </section>
    )
}

export default reduxForm({
    form : "upwardContactForm"
})(ContactForm);