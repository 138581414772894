import React, { Component } from "react";
import { Row, Col, Container } from "reactstrap";
import { requestDomain } from "../../services/SeekerServices";

import { ThemeContext } from "../../../employer/context/ThemeContext";

class WorkWithCompanies extends Component{


    render(){

        return(
            <section className={"upward_get_alerted upward_get_alerted_"+ this.context }>
                <Container className="text-center">
                    <Row>
                        <Col xs="12">
                            <h3>Get alerted when the right employers post a job</h3>
                            <p className="mb-5">We are your radar. As soon as the right job opens up, you’ll know. Get your name to the top of the list by responding right away!</p>
                            <a href={`${requestDomain}/results/?pageNum=1&reg=y`} className="btn btn-lg btn-rounded btn-light"><b>Get job alerts</b></a>
                        </Col>
                    </Row>
                </Container>
            </section>
        )
    }

}

WorkWithCompanies.contextType = ThemeContext;

export default WorkWithCompanies;
