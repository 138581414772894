/*
  Company Auto Suggest
*/
import renderAutoSuggestWithOptions from './AutoSuggestHOC';

import { getKeywordSuggestions } from './AutoSuggestService';

const JobKeywordAutoSuggest = renderAutoSuggestWithOptions(
   getKeywordSuggestions
);

JobKeywordAutoSuggest.defaultProps = {
   selected: {
      id: null,
      value: ''
   },
   forceSelection: false,
   placeholder: "Job Title, Keywords or Company"
}

export default JobKeywordAutoSuggest;
