/*


    Time formatter for the Candidate list and Job list


*/
import React, { Component, Fragment } from "react";

class CandidateJobTimeFormatter extends Component {

    getDisplayString = ( timeStamp )=>{
        const t = new Date(timeStamp);
        const now = new Date();
        let difference = now.getTime() - t.getTime();
        let displayString = "";
        var daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
        difference -= daysDifference * 1000 * 60 * 60 * 24;

        var hoursDifference = Math.floor(difference / 1000 / 60 / 60);
        difference -= hoursDifference * 1000 * 60 * 60;

        var minutesDifference = Math.floor(difference / 1000 / 60);
        difference -= minutesDifference * 1000 * 60;

        if (daysDifference >= 1) {
            displayString = <Fragment>yesterday</Fragment>;
        } else if (hoursDifference > 3) {
            displayString = <Fragment>today</Fragment >;
        } else if (hoursDifference >= 1) {
            displayString = <Fragment>an hour ago</Fragment >;
        } else if (minutesDifference > 15) {
            displayString = <Fragment><i className="fal fa-chevron-left"></i>  1 hour ago</Fragment>;
        } else {
            displayString = <Fragment>{minutesDifference} mins ago</Fragment>;
        }
        return displayString;
    }

    render() {
        return (
            <Fragment>
                { this.getDisplayString( this.props.timeStamp ) }
            </Fragment>
        )
    }
}


export default CandidateJobTimeFormatter;