export const JOB_SEEKER_FETCH_PROFILE_DETAILS = "[Job Seeker Profile] JOB_SEEKER_FETCH_PROFILE_DETAILS";
export const JOB_SEEKER_FETCH_PROFILE_BEGIN = "[Job Seeker Profile] JOB_SEEKER_FETCH_PROFILE_BEGIN";
export const JOB_SEEKER_FETCH_PROFILE_ERROR = "[Job Seeker Profile] JOB_SEEKER_FETCH_PROFILE_ERROR";
export const JOB_SEEKER_UPDATE_PROFILE = "[Job Seeker Profile] JOB_SEEKER_UPDATE_PROFILE"; // update profile with details from server


export const JOB_SEEKER_FETCH_SITE_DETAILS = "[Job Seeker Profile] JOB_SEEKER_FETCH_SITE_DETAILS";
export const JOB_SEEKER_FETCH_SITE_DETAILS_BEGIN = "[Job Seeker Profile] JOB_SEEKER_FETCH_SITE_DETAILS_BEGIN";
export const JOB_SEEKER_FETCH_SITE_DETAILS_ERROR = "[Job Seeker Profile] JOB_SEEKER_FETCH_SITE_DETAILS_ERROR";
export const JOB_SEEKER_UPDATE_SITE_DETAILS = "[Job Seeker Profile] JOB_SEEKER_UPDATE_SITE_DETAILS";


export const JOB_SEEKER_FETCH_RESUME_DETAILS = "[Job Seeker Profile] JOB_SEEKER_FETCH_RESUME_DETAILS";
export const JOB_SEEKER_FETCH_RESUME_DETAILS_BEGIN = "[Job Seeker Profile] JOB_SEEKER_FETCH_RESUME_DETAILS_BEGIN";
export const JOB_SEEKER_UPDATE_RESUME_DETAILS = "[Job Seeker Profile] JOB_SEEKER_UPDATE_RESUME_DETAILS";
export const JOB_SEEKER_FETCH_RESUME_DETAILS_ERROR = "[Job Seeker Profile] JOB_SEEKER_FETCH_RESUME_DETAILS_ERROR";
