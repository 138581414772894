/*


  Implementation by Taran Sahota
  Dated : 9/19/2019


  The loading animation to be used inside the field.

  Takes in No params.
  always a small size towards the right


  

*/

import React from "react";

import {Spinner} from 'reactstrap';

class FieldLoader extends React.Component{
  render () {
    
    return (
        <span className="field_loading_holder">
            <Spinner color="gray" size="sm" />
        </span>
    )

  }
}


export default FieldLoader;