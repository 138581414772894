/*
  The base element for auto suggest
  takes in suggestions from the parent component to render
  user in ORG/COMPANY/JobGroup/JOB autosuggests
*/
import React from 'react';
import Autosuggest from 'react-autosuggest';
import AutoSuggestInputComponent from './AutoSuggestInput';

const getSuggestionValue = (suggestion) => (suggestion.text);

const renderSuggestion = (suggestion) => (
   <div>
      {suggestion.value}
   </div>
);

class BaseAutoSuggestComponent extends React.Component {
   constructor(props) {
      super(props);

      this.state = {
         value: ''
      };
      this.lastRequestId = null;
   }

   onChange = (event, { newValue }) => {
      this.setState({
         value: newValue
      });
      console.log(newValue)
      this.props.onChange(newValue);
   };

   onSuggestionSelected = (event, { suggestion }) => {
      if (this.props.onSuggestionSelected) {
         this.props.onSuggestionSelected(suggestion);
      }
   }

   onSuggestionsFetchRequested = ({ value }) => {
      this.props.onSuggestionsFetchRequested({ value });
   };

   onSuggestionsClearRequested = () => {
      this.props.onSuggestionsClearRequested();
   };

   renderInputComponent = (inputProps) => {
      return (
         <AutoSuggestInputComponent inputProps={inputProps} />
      );
   }

   render() {
      const { suggestions, inputProps, id } = this.props;
      // const { value } = this.state;
      // // Autosuggest will pass through all these props to the input.
      // const inputProps = {
      //     placeholder: 'Search for Hiring Company',
      //     value,
      //     onChange: this.onChange,
      //     isLoading
      // };

      return (
         <Autosuggest
            id={id}
            suggestions={suggestions}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            getSuggestionValue={this.props.getSuggestionValue ? this.props.getSuggestionValue : getSuggestionValue}
            onSuggestionSelected={this.onSuggestionSelected}
            renderSuggestion={this.props.renderSuggestion ? this.props.renderSuggestion : renderSuggestion}
            renderInputComponent={this.renderInputComponent}
            inputProps={inputProps}
         />
      );
   }
}

export default BaseAutoSuggestComponent;
