import * as types from './constants';
import { debounceTime, switchMap, delay, mergeMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';


import { getJobSeekerAlerts, addJobSeekerAlert, deleteJobSeekerAlert, updateJobSeekerAlert } from "../../services/AlertService";

export const fetchJobSeekerAlerts = () => {
    return ({
        type: types.JOB_SEEKER_FETCH_ALERTS
    });
};

export const addNewJobAlert = (alert) => {
    return ({
        type: types.JOB_SEEKER_ADD_NEW_ALERT_BEGIN,
        payload: alert
    });
}

export const deleteJobAlert = (alert) => {
    return ({
        type: types.JOB_SEEKER_DELETE_ALERT_BEGIN,
        payload: alert
    });
}

export const updateJobAlert = (alert) => {
    return ({
        type: types.JOB_SEEKER_UPDATE_ALERT_BEGIN,
        payload: alert
    })
}


export const fetchJobSeekerAlertsFromServerEpic = (action$) => {
    return action$.pipe(
        ofType(types.JOB_SEEKER_FETCH_ALERTS),
        debounceTime(400),
        switchMap((action) => {
            return merge(
                of({ type: types.JOB_SEEKER_FETCH_ALERTS_BEGIN }),
                getJobSeekerAlerts().pipe(
                    delay(1000),
                    mergeMap((data) => {
                        if (data && data.radius && data.location) {
                            return of({
                                type: types.JOB_SEEKER_UPDATE_ALERTS,
                                payload: data
                            })
                        } else {
                            return of({
                                type: types.JOB_SEEKER_FETCH_ALERTS_ERROR,
                                payload: "Error fetching job alerts."
                            })
                        }
                    }),
                    catchError((err) => {
                        return {
                            type: types.JOB_SEEKER_FETCH_ALERTS_ERROR,
                            payload: "Error fetching job alerts."
                        }
                    })
                )
            )
        })
    )
}


export const addNewJobSeekerAlertEpic = (action$) => {
    return action$.pipe(
        ofType(types.JOB_SEEKER_ADD_NEW_ALERT_BEGIN),
        debounceTime(700),
        switchMap((action) => {
            return merge(
                addJobSeekerAlert(action.payload).pipe(
                    delay(200),
                    mergeMap((data) => {
                        if (data && data.response === "success") {
                            return of({
                                type: types.JOB_SEEKER_ADD_NEW_ALERT,
                                payload: action.payload.job_alert
                            })
                        } else {
                            return of({
                                type: types.JOB_SEEKER_ADD_NEW_ALERT_FAILURE,
                                payload: "Error adding new job alert."
                            })
                        }
                    }),
                    catchError((err) => {
                        return {
                            type: types.JOB_SEEKER_ADD_NEW_ALERT_FAILURE,
                            payload: "Error adding new job alert."
                        }
                    })
                )
            )
        })
    )
}


export const updateJobSeekerAlertEpic = (action$) => {
    return action$.pipe(
        ofType(types.JOB_SEEKER_UPDATE_ALERT_BEGIN),
        debounceTime(700),
        switchMap((action) => {
            return merge(
                updateJobSeekerAlert(action.payload).pipe(
                    delay(200),
                    mergeMap((data) => {
                        if (data && data.response === "success") {
                            return of({
                                type: types.JOB_SEEKER_UPDATE_ALERT,
                                payload: action.payload
                            })
                        } else {
                            return of({
                                type: types.JOB_SEEKER_UPDATE_ALERT_FAILURE,
                                payload: "Error updating job alert."
                            })
                        }
                    }),
                    catchError((err) => {
                        return {
                            type: types.JOB_SEEKER_UPDATE_ALERT_FAILURE,
                            payload: "Error updating job alert."
                        }
                    })
                )
            )
        })
    )
}


export const deleteJobSeekerAlertEpic = (action$) => {
    return action$.pipe(
        ofType(types.JOB_SEEKER_DELETE_ALERT_BEGIN),
        switchMap((action) => {
            return merge(
                deleteJobSeekerAlert(action.payload).pipe(
                    mergeMap((data) => {
                        if (data && data.response === "success") {
                            return of({
                                type: types.JOB_SEEKER_DELETE_ALERT,
                                payload: action.payload.keyword
                            })
                        } else {
                            return of({
                                type: types.JOB_SEEKER_DELETE_ALERT_FAILURE,
                                payload: "Error deleting job alert."
                            })
                        }
                    }),
                    catchError((err) => {
                        return {
                            type: types.JOB_SEEKER_DELETE_ALERT_FAILURE,
                            payload: "Error deleting job alert."
                        }
                    })
                )
            )
        })
    )
}