/*

  Implementation by Taran Sahota
  Dated : 9/19/2019

  
  The base Upward loading animation
  the one with three dots with blue and orange.

  takes in size as parameter - full resizeable 

  size : small and large.
  if no size specified it defaults to medium 

  No need to specify medium




*/
import React from "react";

class UpwardLoader extends React.Component{

  render () {
    
    return (
        <div className="loading in">
          <div className={ 'spinner ' + (this.props.size || '') }>
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
        </div>
    )

  }
}


export default UpwardLoader;