/*


  Modified by Taran Sahota
  Dated : 9/19/2019

  Added protected Routes.
  Imports routes from Employer Routes
  to render them into the sidebar
  and generate routes from them


*/
import React, {lazy} from "react"
import async from "../components/Async";

import HomePage from "corp-site/homepage/HomePage";

//lazy load page components
const EmployerHome = lazy(() => import("corp-site/employer/EmployerHome"));
const EnterpriseHomepage = lazy(() => import("corp-site/employer/Enterprise"));
const PartnerHomepage = lazy(() => import("corp-site/partner/Partner"));
const MissionStatement = lazy(() => import("corp-site/mission/Mission"));

// vertical home pages
const RetailHomePage = lazy(() => import("corp-site/employer/verticals/retail"));
const AccountingHomePage = lazy(() => import("corp-site/employer/verticals/accounting_finance"));
const ConstructionHomePage = lazy(() => import("corp-site/employer/verticals/construction"));
const CustomerSupportHomePage = lazy(() => import("corp-site/employer/verticals/customer_support"));
//const GigHomePage = lazy(() => import("corp-site/employer/verticals/gig"));
const HealthCareHomePage = lazy(() => import("corp-site/employer/verticals/healthcare"));
const HospitalityHomePage = lazy(() => import("corp-site/employer/verticals/hospitality"));
const ManufacturingHomePage = lazy(() => import("corp-site/employer/verticals/manufacturing"));
const RestaurantHomePage = lazy(() => import("corp-site/employer/verticals/restaurant"));
const SalesHomePage = lazy(() => import("corp-site/employer/verticals/sales"));
const SecurityHomePage = lazy(() => import("corp-site/employer/verticals/security"));
const TransportationHomePage = lazy(() => import("corp-site/employer/verticals/transportation"));
const WarehouseHomePage = lazy(() => import("corp-site/employer/verticals/warehouse"));

const ProfileLayout = lazy(() => import("corp-site/job-seeker/profileLayout"));

const homePageRoutes = [];

const homePageRoute = {
  path: "/",
  name: "Home Page",
  component: HomePage,
};

const missionRoute = {
  path: "/mission",
  name: "Mission Statement",
  component: MissionStatement,
};

const partnerRoute = {
  path: "/partners",
  name: "Partner Home Page",
  component: PartnerHomepage,
};

const employerHomeRoute = {
  path: "/employers",
  name: "Employer Home Page",
  component: EmployerHome,
};

const employerEnterpriseRoute = {
  path: "/enterprise",
  name: "Employer Enterprise",
  component: EnterpriseHomepage,
};

const retailRoute = {
  path: "/retail",
  name: "Retail ",
  component: RetailHomePage
}

const accountingRoute = {
  path: "/accounting",
  name: "Accounting & Finance",
  component: AccountingHomePage
}

const constructionRoute = {
  path: "/construction",
  name: "Construction",
  component: ConstructionHomePage
}

const customerSupportRoute = {
  path: "/customer_support",
  name: "Customer Support",
  component: CustomerSupportHomePage
}

// const gigRoute = {
//   path : "/gig",
//   name : "Gig",
//   component : GigHomePage
// }

const healthCareRoute = {
  path: "/healthcare",
  name: "Healthcare",
  component: HealthCareHomePage
}

const hospitalityRoute = {
  path: "/hospitality",
  name: "Hospitality",
  component: HospitalityHomePage
}

const manufacturingRoute = {
  path: "/manufacturing",
  name: "Manufacturing",
  component: ManufacturingHomePage
}

const restaurantRoute = {
  path: "/restaurant_food_service",
  name: "Restaurant & Food Service",
  component: RestaurantHomePage
}

const salesRoute = {
  path: "/sales_marketing",
  name: "Sales",
  component: SalesHomePage
}

const securityRoute = {
  path: "/security",
  name: "Security",
  component: SecurityHomePage
}

const transportationRoute = {
  path: "/transportation",
  name: "/jobsTransportation",
  component: TransportationHomePage
}



const warehouseRoute = {
  path: "/warehouse",
  name: "Warehouse",
  component: WarehouseHomePage
}


const jobSeekerRoutes = {
  path: "/employee",
  name: "Job Seeker Profile",
  hasSubRoutes: true,
  component: ProfileLayout,
};


homePageRoutes.push(homePageRoute);
homePageRoutes.push(missionRoute);
homePageRoutes.push(partnerRoute);
homePageRoutes.push(employerHomeRoute);
homePageRoutes.push(employerEnterpriseRoute);
homePageRoutes.push(retailRoute);
homePageRoutes.push(accountingRoute);
homePageRoutes.push(constructionRoute);
homePageRoutes.push(customerSupportRoute);
// homePageRoutes.push( gigRoute );
homePageRoutes.push(healthCareRoute);
homePageRoutes.push(hospitalityRoute);
homePageRoutes.push(manufacturingRoute);
homePageRoutes.push(restaurantRoute);
homePageRoutes.push(salesRoute);
homePageRoutes.push(securityRoute);
homePageRoutes.push(transportationRoute);
homePageRoutes.push(warehouseRoute);


// job seeker routes
homePageRoutes.push(jobSeekerRoutes);

// All routes
export default homePageRoutes;
