/*

    Dumb Component - just to display the Single Job Item.

*/
import React from "react";

import Star from "corp-site/employer/Abstracts/Star";

const RecentCandidateItem = ({ candidate, linkComponent : LinkComponent }) => {
    return (
        <div key={candidate.userId} className="recent_candidate">
            <div className="recent_icon"><Star /></div>
            <header> APPLICANT <span className="recent_time">{candidate.formattedTime}</span></header>
            <section>
                <h4 className="text-truncate">{candidate.fname + " " + candidate.lname}. from {candidate.location}</h4>
                <h5 className="text-truncate">applied for a job as </h5>
                <h6>{candidate.keyword}</h6>
            </section>
            { LinkComponent && 
                <div className="text-right recent_link"><LinkComponent /></div>
            }
        </div>
    )

}


export default RecentCandidateItem;