

import React from "react";
import { ModalHeader, ModalBody } from 'reactstrap';



const CalPrivacy = ( props ) => {
    
    return (
        <React.Fragment>
            <ModalHeader style={{paddingBottom:0}}>
                <h3 className="center">California Privacy Notice</h3>
            </ModalHeader>
            <ModalBody>
                <React.Fragment>
                <div className="pageWrapper">
                <div className="mainContent" id="mainContentHolder">
                    <div className="row">
                        <div className="col-md-12 col-lg-12">
                            <h3 style={{fontSize:"22px"}}><b>Last Updated January 2020</b></h3>
    
                            <p>Your privacy is important to us and maintaining your trust is our priority. {props.domain} (&quot;{props.domain},&quot; &quot;we,&quot; &quot;our,&quot; or &quot;us&quot;), respects your privacy and is committed to protecting it through our compliance with this California Privacy Notice. This California Privacy Notice supplements the information contained in {props.domain}'s privacy policy and applies solely to visitors, users, and others who reside in the State of California (consumers&quot; or &quot;you&quot;) and use our Services. We adopt this notice to comply with the California Consumer Privacy Act of 2018 (&quot;CCPA&quot;) and any terms defined in the CCPA have the same meaning when used in this notice. Capitalized terms used but not otherwise defined herein or in the CCPA shall have the meanings given to such terms in the {props.domain} <a href="/?privacy-policy">Privacy Policy</a>.
                            </p>
    
                            <h3 style={{fontSize:"22px"}}><b>1. Information we collect</b></h3>
    
                            <p>A. Personal information</p>
    
                            <p>Our Services collect information that identifies, relates to, describes, references, is capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular consumer or device (&quot;personal information&quot;). In particular {props.domain}'s Services have collected the following categories of personal information from its consumers within the last twelve (12) months:</p>
    
                            <table className="table table-bordered" style={{"background": "#f0f0f0"}}>
    
                                <tr>
                                    <th className="text-left">Category</th>
                                    <th>Examples of specific pieces of personal information we collect</th>
                                </tr>
                                <tr>
                                    <th className="text-left">A.&nbsp;&nbsp;&nbsp;&nbsp;Identifiers</th>
                                    <td>First name, maiden name, last name, alias, physical address, email address, telephone number, social media profile, social media address, username, unique personal identifier, online identifier, Internet Protocol (IP) address, device IDs, account name, and other similar identifiers.</td>
                                </tr>
                                <tr>
                                    <th className="text-left">B.&nbsp;&nbsp;&nbsp;&nbsp;Personal information categories listed in the California Customer Records statute (Cal. Civ. Code &sect; 1798.80(e)).</th>
                                    <td>A name, address, telephone number, education, employment, employment history, and, if you are a client, your financial information (including a bank account number, credit card number, debit card number, or any other financial information). Some personal information included in this category may overlap with other categories.</td>
                                </tr>
                                <tr>
                                    <th className="text-left">C.&nbsp;&nbsp;&nbsp;&nbsp;Protected classification characteristics under California or federal law</th>
                                    <td><b>{props.domain} does not request or require you to provide protected classification characteristics.</b>
                                        <br/>However, please carefully consider the information contained within your resume/CV and application materials to ensure that you are not providing any protected classification characteristics that are not necessary for your job application or job search.
                                        <br/>
                                        <br/>Protected classification characteristics include your age (40 years or older), race, color, ancestry, national origin, citizenship, religion or creed, marital status, medical condition, physical or mental disability, sex (including gender, gender identity, gender expression, pregnancy or childbirth and related medical conditions), sexual orientation, genetic information (including familial genetic information), and veteran or military status.
                                        <br/>
                                        <br/>Employers or hiring managers may request a veteran or military status in accordance with applicable law, and you may choose whether to submit that information with your job application on our Website.
                                    </td>
                                </tr>
                                <tr>
                                    <th className="text-left">D.&nbsp;&nbsp;&nbsp;&nbsp;Commercial information</th>
                                    <td>Records of products or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies, such as details about payments to and from you, and details regarding the services that you have subscribed to or purchased.</td>
                                </tr>
                                <tr>
                                    <th className="text-left">E.&nbsp;&nbsp;&nbsp;&nbsp;Internet or other similar network activity</th>
                                    <td>Login data, search history, access dates and times, browsing history, browser type and version, device information, cookie data, browser plug-in types and versions, operating system and platform and other technology on the devices used to access the Website, and other similar activity concerning an interaction with our Services.</td>
                                </tr>
                                <tr>
                                    <th className="text-left">F.&nbsp;&nbsp;&nbsp;&nbsp;Geolocation data</th>
                                    <td>Time zone setting and approximate location.</td>
                                </tr>
                                <tr>
                                    <th className="text-left">G.&nbsp;&nbsp;&nbsp;&nbsp;Professional or employment-related information</th>
                                    <td>Job searches, candidate searches, job applications, and any information provided in a resume/CV or job seeker profile (including, but not limited to, executive summary, objective, current or prior work experience and education).</td>
                                </tr>
                                <tr>
                                    <th className="text-left">H.&nbsp;&nbsp;&nbsp;&nbsp;Inferences drawn from other personal information to create a profile</th>
                                    <td>Inferences used to reflect a consumer's preferences or behavior on our Website.</td>
                                </tr>
                            </table>
    
                            <p>Note:
                                {props.domain} has not collected any of the following categories of personal information from consumers within the last twelve (12) months:</p>
                            <ul>
                                <li>Biometric information (such as genetic, physiological, behavioral, and biological characteristics, or activity patterns used to extract a template or other identifier or identifying information, such as, fingerprints, faceprints, and voiceprints, iris or retina scans, keystroke, gait, or other physical patterns, and sleep, health, or exercise data)</li>
                                <li>Sensory data (such as audio, electronic, visual, thermal, olfactory, or similar information)</li>
                                <li>Non-public education information, per the Family Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99)) (such as education records directly related to a student maintained by an educational institution or party acting on its behalf, such as grades, transcripts, class lists, student schedules, student identification codes, student financial information, or student disciplinary records.</li>
                            </ul>
    
                            <p>B. Non-Personal Information</p>
    
                            <p>
                                {props.domain} collects, uses and shares de-identified and/or aggregated consumer information, which is not considered personal information. Deidentified or aggregated data may be derived from your personal information, but is not considered personal information under CCPA, since it does not directly or indirectly reveal your identity. For example, we may aggregate your Internet or other similar network activity to calculate the percentage of users accessing a specific website feature.</p>
    
                            <p>C. Sources of Personal Information</p>
    
                            <p>We obtain the categories of personal information listed above from the following categories of sources:</p>
                            <ul>
                                <li><b>Directly from you.</b> For example, when you fill in forms, create an account, submit your resume/CV, or correspond with us by post, phone, e-mail or otherwise.</li>
                                <li><b>Indirectly from you.</b> For example, through automated technologies or your interactions with us, including from observing your actions when you use our Website.</li>
                                <li><b>Third Parties.</b> We may receive personal information about you from various third parties such as analytics providers or advertising networks.</li>
                                <li><b>In our capacity as a service provider</b> (see Section 6 below).</li>
                            </ul>
    
                            <h3 style={{fontSize:"22px"}}><b>2. Use of personal information</b></h3>
    
                            <p>We describe in detail in our privacy policy the context in which your personal information may be collected, along with how and why we use your personal information. Pursuant to the CCPA, we may use the personal information we collect for one or more of the following business or commercial purposes:</p>
                            <ul>
                                <li>To fulfill or meet the reason for which the personal information is provided. For example, if you provide your personal information to create an account, we will use that information to create your account.</li>
                                <li>To create, maintain, manage, customize, and/or secure your account with us.</li>
                                <li>To communicate with you about your account or our Services, and to manage our relationship with you.</li>
                                <li>To provide you with support and to respond to your inquiries, including investigating and address your concerns and monitor and improve our responses.</li>
                                <li>To send you email and/or SMS job alerts, if you registered to receive job alerts.</li>
                                <li>To send you marketing communications (where you have asked us to) which may be tailored to your &quot;profile&quot; (i.e., based on the personal information we know about you and your preferences).</li>
                                <li>To send you newsletters where you have indicated an interest or shown an interest based on your preferences as shown by your activity on the Website and/or your profile.</li>
                                <li>To maintain certain of your information on a suppression list, if you've indicated to us that you don't want to be communicated in a particular way.</li>
                                <li>To manage any surveys, promotions or competitions you entered.</li>
                                <li>To personalize your Website experience and to deliver content and product and service offerings relevant to your interests, including targeted offers and ads through our Website, third-party sites, and via email or text message (with your consent, where required by law).</li>
                                <li>To enable our Website to function properly (ensure proper display of content), create and remember your account login details, and for interface personalization, such as language, or any user-interface customization.</li>
                                <li>To provide, support, personalize, and develop our Services.</li>
                                <li>To process your requests, purchases, transactions, and payments, and prevent transactional fraud.</li>
                                <li>To carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing or collections, or manage payment disputes or issues with the services.</li>
                                <li>To help maintain the safety, security, and integrity of our Services, databases and other technology assets, and business.</li>
                                <li>To detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, and prosecute those responsible for that activity.</li>
                                <li>For testing, research, analysis, and product development, including to collect statistics and develop and improve our Website or Services.</li>
                                <li>For debugging to identify and repair errors that impair existing intended functionality of our Website or Services.</li>
                                <li>To respond to law enforcement requests and as required by applicable law, court order, or governmental regulations.</li>
                                <li>As described to you when collecting your personal information or as otherwise set forth in the CCPA.</li>
                                <li>In connection with or during negotiations of any proposed or actual financing, merger, purchase, sale, sale or transfer of some or all of our assets or stock, joint venture, or any other type of financing, acquisition or business combination of our company, or all or any portion of our assets, or transfer of all or a portion of our business to another company, including without limitation in the event of a divestiture, restructuring, reorganization, dissolution or other event, whether as a going concern or as part of bankruptcy, liquidation or similar proceeding, in which personal information held by {props.domain} is among the assets transferred.</li>
                            </ul>
                            <p>
                                {props.domain} will not collect additional categories of personal information or use the personal information we collected for materially different, unrelated, or incompatible purposes without providing you notice.</p>
    
                            <h3 style={{fontSize:"22px"}}><b>3. Sharing personal information</b></h3>
    
                            <p><u>A. Disclosures of Personal Information at Your Direction</u></p>
    
                            <p>We disclose your personal information when you intentionally interact with third parties and when you direct us or intentionally use {props.domain} to share your personal information in connection with the services that we provide to you. For example, if you are a job seeker and apply to a job posting on our Website, we will share your personal information with that prospective employer and/or its agent or representative (including but not limited to, external Applicant Tracking Systems or any other external systems used by the direct employer to manage the hiring of employees or other categories of workers); or if you are a job seeker and you are opted-in to our Resume Database, we will share your personal information with prospective employers and/or their agents or representatives.</p>
    
                            <p><u>B. Disclosures of Personal Information for a Business Purpose</u></p>
    
                            <p>We describe in detail in our Global Privacy Policy the context in which your personal information may be disclosed to or shared with a third party. Pursuant to the CCPA, {props.domain} has disclosed the following categories of personal information for a business purpose in the preceding twelve (12) months:</p>
                            <p>Category A: Identifiers
                                <br/> Category B: California Customer Records personal information categories
                                <br/> Category C: Protected classification characteristics under California or federal law
                                <br/> Category D: Commercial information
                                <br/> Category E: Internet or other similar network activity
                                <br/> Category F: Geolocation data
                                <br/> Category G: Professional or employment-related information
                                <br/> Category H: Inferences drawn from other personal information</p>
    
                            <p>We may disclose your personal information for a business purpose to the categories of third parties identified in Section 6 of our Global Privacy Policy, depending on how you use or interact with our Website or Services. This may include disclosure of personal information to the following:</p>
    
                            <ul>
                                <li>Companies within our corporate family that comprise the
                                    {props.domain} Group.</li>
                                <li>Service providers that we use to support our business, provide services to us, and/or help provide our services to you.</li>
                                <li>Professional advisers, such as lawyers, bankers, auditors and insurers who provide consultancy, banking, legal, insurance, financial, auditing, and accounting services to us so we can operate our business</li>
                                <li>Taxing authorities, such as the Internal Revenue Service and other authorities acting as processors based in the United States, the United Kingdom, and other applicable countries, who require reporting of processing activities in certain circumstances.</li>
                                <li>Fraud prevention and identity verification services where we believe that disclosure is necessary or appropriate to protect the rights, property, or safety of
                                    {props.domain}, our customers or others. This includes exchanging information with third-parties for the purposes of fraud detection and prevention and credit risk reduction.</li>
                                <li>Law enforcement agencies, courts, supervisory authorities, regulatory bodies and certain other third-parties, to the extent that we are permitted or required to do so by law, or in order to comply with our legal and regulatory obligations, or in the interests of national security, or to respond to verified requests relating to a criminal investigation or alleged or suspected illegal activity.</li>
                                <li>To enforce our Terms of Use and other agreements with you, to respond to, and defend ourselves against, claims asserted against us, to protect the rights, property, or safety of
                                    {props.domain}, our services, customers or others, or to investigate, prevent, or take action regarding suspected or actual illegal activities or for debt collection.</li>
                                <li>In connection with or during negotiations of any proposed or actual financing, merger, purchase, sale, sale or transfer of some or all of our assets or stock, joint venture, or any other type of financing, acquisition or business combination of our company, or all or any portion of our assets, or transfer of all or a portion of our business to another company.</li>
                            </ul>
    
                            <h3 style={{fontSize:"22px"}}><b>C. Sale of Personal Information</b></h3>
    
                            <p>Your privacy is our priority, and we do not monetize your personal information by selling it to third parties.
                                <br/> After you visit our Website and/or use our Services, you may receive advertisements about
                                {props.domain}'s services when you visit other websites (i.e., social media sites, search engines or other websites that are unrelated to
                                    {props.domain}). This happens because we use third-party vendors to provide you with information about our services based upon your interactions with our website. These third-party vendors may work with additional providers in their relationship network that help provide the advertising services the third-party vendors provide to us. Note, we use these third parties only to show you ads about {props.domain} services (i.e., we do not use third parties to advertise any other company's products or services to you).</p>
    
                            <p>Our third-party vendors and, if applicable, providers in their relationship network, may use the information they collect for purposes that are unrelated to showing you ads about our services. When this information is used for a purpose other than showing ads about our services or other services we have requested, it may constitute a &quot;sale&quot; of personal information under the CCPA.</p>
    
                            <p>Pursuant to the CCPA, in the preceding twelve (12) months, we have sold the following categories of personal information for the purpose of showing you ads about {props.domain} services:</p>
    
                            <p>Category A: Identifiers
                                <br/> Category E: Internet or other similar network activity
                                <br/> Category F: Geolocation data</p>
    
                            <p>In this limited context, we may sell your personal information to the following categories of third parties:</p>
                            <ul>
                                <li>Third-party advertising partners or advertising networks</li>
                            </ul>
                            <p>The CCPA prohibits third parties who purchase the personal information we hold from reselling it unless you have received explicit notice and an opportunity to opt-out of further sales.</p>
    
                            <h3 style={{fontSize:"22px"}}><b>4. Your rights and choices</b></h3>
    
                            <p>The CCPA provides consumers (California residents) with specific rights regarding their personal information. This section describes your CCPA rights and explains how you can exercise them.</p>
    
                            <p><u>A. Access to Specific Information and Data Portability Requests</u></p>
                            <p>You may request that
                                {props.domain} disclose certain information to you about our collection and use of your personal information over the past 12 months. Once we receive and confirm your verifiable consumer request (see Exercising Access, Data Portability, and Deletion Requests below), we will disclose to you:</p>
                            <ul>
                                <li>The categories of personal information we collected about you.</li>
                                <li>The categories of sources for the personal information we collected about you.</li>
                                <li>Our business or commercial purpose for collecting or selling that personal information.</li>
                                <li>The categories of third parties with whom we share that personal information.</li>
                                <li>The specific pieces of personal information we collected about you (also called a data portability request).</li>
                                <li>If we sold or disclosed your personal information for a business purpose, two separate lists disclosing: (i) sales, identifying the personal information categories that each category of recipient purchased; and (ii) disclosures for a business purpose, identifying the personal information categories that each category of recipient obtained.</li>
                            </ul>
    
                            <p><u>B. Deletion Requests</u></p>
                            <p>You may request that
                                {props.domain} delete any of your personal information we collected from you and retained, subject to certain exceptions. Once we receive and confirm your verifiable consumer request (see Exercising Access, Data Portability, and Deletion Requests), we will delete (and direct our service providers to delete) your personal information from our records, unless an exception applies.</p>
                            <p>We may deny your deletion request (in whole or in part) in accordance with any purpose that is permitted by the CCPA. For example, we may deny your deletion request if retaining the information (or a portion thereof) is necessary for us or our service provider(s) to:</p>
    
                            <ul>
                                <li>Complete the transaction for which we collected the personal information, provide a good or service that you requested, take actions reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform our contract with you;</li>
                                <li>Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for such activities;</li>
                                <li>Debug products to identify and repair errors that impair existing intended functionality;</li>
                                <li>Exercise free speech, ensure the right of another consumer to exercise their free speech rights, or exercise another right provided for by law;</li>
                                <li>Comply with the California Electronic Communications Privacy Act (Cal. Penal Code &sect; 1546 et. seq.);</li>
                                <li>Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when the information's deletion may likely render impossible or seriously impair the research's achievement, if you previously provided informed consent;</li>
                                <li>Enable solely internal uses that are reasonably aligned with consumer expectations based on your relationship with us;</li>
                                <li>Comply with a legal obligation; and/or</li>
                                <li>Make other internal and lawful uses of that information that are compatible with the context in which you provided it.</li>
                            </ul>
    
                            <p><u>C. Exercising Access, Data Portability, and Deletion Requests</u></p>
    
                            <p>If you would like to exercise any of your rights provided by the CCPA, such as the right to access or delete your personal information, or to opt out of the sale or future sale of your personal information you or your authorized agent or representative may contact us via the following methods:</p>
    
                            <p>
                                <b>Email:</b> info@{props.domain}
                                <br/>
                                <b>Phone:</b> 1.866.422.2048</p>
    
                            <p>Postal Mail:</p>
    
                            <p>
                                {props.domain}
                                    <br/> ATTN: CCPA
                                    <br/> 548 Market St.
                                    <br/> Suite 30363
                                    <br/> San Francisco, CA 94104</p>
    
                            <p>As with the right to opt out, the other rights provided by the CCPA are subject to certain exemptions and exceptions, as specified in the applicable federal statutes and/or associated regulations issued by California's Office of the Attorney General. Those statues and/or regulations may also stipulate the maximum time allowed for acknowledging and responding to a request. There is no charge for making privacy-related requests.</p>
    
                            <p>Only you, or a person registered with the California Secretary of State that you authorize to act on your behalf, may make a verifiable consumer request related to your personal information.
                                <br/> You may only make a verifiable consumer request for access or data portability twice within a 12-month period. The verifiable consumer request must:</p>
    
                            <ul>
                                <li>Provide sufficient information that allows us to reasonably verify you are the person about whom we collected personal information or an authorized representative; and</li>
                                <li>Describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it.</li>
                            </ul>
    
                            <p>We cannot respond to your request or provide you with personal information if we cannot verify your identity or authority to make the request and confirm the personal information relates to you. Making a verifiable consumer request does not require you to create an account with us. However, we do consider requests made through your password protected account sufficiently verified when the request relates to personal information associated with that specific account.</p>
    
                            <p>We will only use personal information provided in a verifiable consumer request to verify the requestor's identity or authority to make the request.</p>
    
                            <p><u>D. Response Timing and Format</u></p>
    
                            <p>We endeavor to respond to a verifiable consumer request within forty-five (45) days of its receipt. If we require more time, we will inform you of the reason and extension period in writing. Any disclosures we provide will only cover the 12-month period preceding the verifiable consumer request's receipt. The response we provide will also explain the reasons we cannot comply with a request, if applicable.</p>
    
                            <p>For data portability requests, we will select a format to provide your personal information that is readily useable and should allow you to transmit the information from one entity to another entity without hindrance, for example, through an Excel or JSON file format.</p>
    
                            <p>We do not charge a fee to process or respond to your verifiable consumer request unless it is excessive, repetitive, or manifestly unfounded. If we determine that the request warrants a fee, we will tell you why we made that decision and provide you with a cost estimate before completing your request.</p>
    
                            <p><u>E. Opting Out from the Sale of Personal Information</u></p>
    
                            <p>California privacy laws require us to provide California residents with the option to opt out of current or future sales of your information. This information includes, but is not limited to name, postal or email address, and other personally identifying information. You need not be physically present in California to exercise this right so long as you have a current California residence.</p>
    
                            <p>This right is subject to certain exemptions. For example, the law does not apply to information that has been aggregated and/or de-identified such that it could not reasonably be used to identify you. It also does not apply to information that we share with third-party service providers in order for them to perform certain business functions for us.</p>
    
                            <h3 style={{fontSize:"22px"}}><b>5. Non-discrimination</b></h3>
    
                            <p>We will not discriminate against you for exercising any of your CCPA rights. Unless permitted by the CCPA, we will not:</p>
    
                            <ul>
                                <li>Deny you goods or services.</li>
                                <li>Charge you different prices or rates for goods or services, including through granting discounts or other benefits, or imposing penalties.</li>
                                <li>Provide you a different level or quality of goods or services.</li>
                                <li>Suggest that you may receive a different price or rate for goods or services or a different level or quality of goods or services.</li>
                            </ul>
    
                            <h3 style={{fontSize:"22px"}}><b>6. {props.domain} as a service provider</b></h3>
    
                            <p>In certain instances, our clients hire {props.domain} to provide services on behalf of the client. In such case, we process personal information under the direction of that client as a service provider, and we have no direct relationship with the individuals (consumers) whose personal information we process.</p>
    
                            <p>
                                Where {props.domain} acts as a service provider, it will collect, retain, use, disclose, and/or otherwise process personal information (i) to fulfill {props.domain}'s obligations to perform the services pursuant to a written agreement with that client; (ii) pursuant to a valid business purpose, as permitted under the CCPA; and/or (iii) as otherwise directed by the client in writing. Where {props.domain} processes personal data in the capacity of a service provider, {props.domain} does not sell that personal information or otherwise retain, use, or disclose personal information for any commercial purpose, except as otherwise directed by the client in writing.</p>
    
                            <p>In addition to the purposes set forth above, {props.domain} may collect, retain, use, disclose, and otherwise process personal information in the context of a service provider to:</p>
    
                            <ul>
                                <li>Comply with applicable laws;</li>
                                <li>Comply with a civil, criminal, or regulatory inquiry, investigation, subpoena, or summons by federal, state, or local authorities;</li>
                                <li>Cooperate with law enforcement agencies concerning conduct or activity that a client, {props.domain}, or third party reasonably and in good faith believes may violate federal, state, or local law;</li>
                                <li>Exercise or defend legal claims; and/or</li>
                                <li>Collect, use, retain, share, or disclose information that has been aggregated or de-identified, in accordance with the CCPA.</li>
                            </ul>
    
                            <p>Where {props.domain} processes your personal data in the capacity of a service provider, and you seek access, or want to correct, amend, or delete your personal data, or have another request, we will provide you with the client's contact information, so you can contact them directly. In rare instances, however, circumstances may necessitate {props.domain} assisting you with your question or request.</p>
    
                            <h3 style={{fontSize:"22px"}}><b>7. Changes to this California Privacy Notice</b></h3>
    
                            <p>We reserve the right to change this California Privacy Notice at any time. If we propose to make any material changes, we will notify you by posting a notice on the Website or by issuing an email to the email address listed by registered users prior to the changes becoming effective, and will and update the notice's effective date. You acknowledge that it is your responsibility to maintain a valid email address as a registered user, and to review the Website and this Privacy Policy periodically in order to be aware of any modifications. If you object to any changes to the revised Privacy Policy, you can stop using our services and close your account with us. However, if you continue to use our services (including the Website) after we publish a new or updated California Privacy Notice, you acknowledge that such continued use means that the treatment of your personal information will be subject to the new or updated California Privacy Notice.</p>
    
                            <h3 style={{fontSize:"22px"}}><b>8. Contact Information</b></h3>
    
                            <p>If you have any questions or comments about this notice, the ways in which {props.domain} collects and uses your information described herein, your choices and rights regarding such use, or wish to exercise your rights under California law, please contact us by using the communication means described below</p>
    
                            <h3 style={{fontSize:"22px"}}><b>Email:</b> ccpa@{props.domain}<br/>
                                <b>Mailing Address:</b> {props.domain},  {props.address}</h3>
    
                            </div>
                        </div>
                </div>
            </div>
                </React.Fragment>
            </ModalBody>
        </React.Fragment>
    )

}


export default CalPrivacy;
