import React, {useCallback, useEffect} from "react";
import ReduxInputAutosuggest from "./ReduxInputAutosuggest";
import {
   cityObservable$,
   stateInput$,
   zipInput$
} from "ui/util/rxjs/observables";
import {getCitySuggestions} from "services/suggestions/city";

const ReduxCityInputAutosuggest = (props) => {
   useEffect(() => {
      const subscription = cityObservable$.subscribe(city => {
         props.input.onChange(city);
      });
      return () => {
         if (subscription) {
            subscription.unsubscribe();
         }
      }
   }, []);

   const handleSelect =  useCallback(({value, state, zip})=> {
      props.input.onChange(value);
      stateInput$.next(state);
      zipInput$.next(zip);
   }, [props.input.onChange]);

   return <ReduxInputAutosuggest
      {...props}
      getSuggestions={getCitySuggestions}
      handleSelect={handleSelect}
   />
};

export default ReduxCityInputAutosuggest;