import * as types from './constants';

const defaultState = {
    job: {
        taxonomy: "",
        title: "",
        description: "",
        company: "",
        location: "",
        jobId: "",
        solrJtid: "",
        url: "",
    },
    user: {
        email: "",
        phone: "",
        fname: "",
        lname: "",
        keyword: "",
        location: "",
        CID: "",
        OID: "",
        SID: "",
        CRID: "",
        siteId: "",
        keymapId: ""
    },
    editLocation: false,
    showAlertOverlay: false,
    showBookmarkOverlay: false,
    displayLocation: "",
    keywords: [],
}

export default function seekerRegistrationReducer(state = defaultState, action) {
    switch (action.type) {
        case types.SEEKER_REGISTER_UPDATE_JOB_DETAILS:
            return {
                ...state,
                user: {
                    ...state.user
                },
                job: {
                    ...action.payload
                }
            }
        case types.SEEKER_REGISTER_UPDATE_USER_DETAILS:
            return {
                ...state,
                job: {
                    ...state.job
                },
                user: {
                    ...state.user,
                    ...action.payload
                }
            }
        case types.SEEKER_REGISTER_UPDATE_USER_EMAIL:
            return {
                ...state,
                user: {
                    ...state.user
                },
                job: {
                    ...state.job,
                    email: action.payload
                }
            }
        case types.SEEKER_REGISTER_UPDATE_USER_NAME:
            return {
                ...state,
                user: {
                    ...state.user,
                    fname: action.payload.fname,
                    lname: action.payload.lname
                },
                job: {
                    ...state.job
                }
            }
        case types.SEEKER_REGISTER_UPDATE_USER_LOCATION:
            return {
                ...state,
                user: {
                    ...state.user,
                    location: action.payload
                },
                job: {
                    ...state.job
                }
            }
        case types.SEEKER_REGISTER_UPDATE_USER_KEYWORD:
            return {
                ...state,
                user: {
                    ...state.user,
                    keyword: action.payload
                },
                job: {
                    ...state.job
                }
            }
        case types.SEEKER_REGISTER_UPDATE_USER_SEARCH:
            return {
                ...state,
                user: {
                    ...state.user,
                    keyword: action.payload.keyword,
                    location: action.payload.location
                },
                job: {
                    ...state.job
                }
            }
        case types.SEEKER_REGISTER_UPDATE_USER_PHONE:
            return {
                ...state,
                user: {
                    ...state.user,
                    phone: action.payload
                },
                job: {
                    ...state.job
                }
            }
        case types.SEEKER_REGISTER_EDIT_USER_LOCATION_FIELD:
            return {
                ...state,
                editLocation: action.payload,
                job: {
                    ...state.job
                },
                user: {
                    ...state.user
                }
            }
        case types.SEEKER_REGISTER_SHOW_ALERT_OVERLAY:
            return {
                ...state,
                job: {
                    ...action.payload
                },
                user: {
                    ...state.user,
                    location: action.payload.location ? action.payload.location : state.user.location
                },
                showAlertOverlay: true,
                showBookmarkOverlay: false
            }
        case types.SEEKER_REGISTER_HIDE_ALERT_OVERLAY:
            return {
                ...state,
                job: {
                    ...state.job,
                },
                user: {
                    ...state.user
                },
                showAlertOverlay: false,
                showBookmarkOverlay: false
            }
        case types.SEEKER_REGISTER_SHOW_BOOKMARK_OVERLAY:
            return {
                ...state,
                job: {
                    ...action.payload
                },
                user: {
                    ...state.user,
                    location: action.payload.location ? action.payload.location : state.user.location
                },
                showAlertOverlay: false,
                showBookmarkOverlay: true
            }
        case types.SEEKER_REGISTER_HIDE_BOOKMARK_OVERLAY:
            return {
                ...state,
                job: {
                    ...state.job,
                },
                user: {
                    ...state.user
                },
                showAlertOverlay: false,
                showBookmarkOverlay: false
            }
        case types.SEEKER_REGISTER_UPDATE_DISPLAY_LOCATION:
            return {
                ...state,
                job: {
                  ...state.job
                },
                user: {
                    ...state.user
                },
                displayLocation: action.payload
            }
        case types.SEEKER_REGISTER_UPDATE_KEYWORDS:
            return {
                ...state,
                job: {
                  ...state.job
                },
                user: {
                    ...state.user
                },
                keywords: action.payload
            }
        default:
            return state;
    }
}
