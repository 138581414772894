/*


  Implementation by Taran Sahota & Bryan Perez
  Dated : 9/19/2019


    PROPS
        isLoading
        disabled

    isLoading >> disabled

    IF isLoading === true
       then button is also disabled; not true for the other way around.
       That is, the button is not isLoading if disabled === true

    Shows loading animation inside the button 
    whenever the submit is happening
    takes in isLoading prop from the parent
    so that it knows that some stuff is happening


*/

import React from "react";
import PropTypes from 'prop-types';
import {Button} from "reactstrap";
import UpwardButtonLoader from "../loaders/UpwardButtonLoader";


class UpwardLoadingButton extends React.Component {

    render() {
        const {isLoading, ...rest} = this.props;
        const buttonProps = Object.assign({}, this.props);
        delete buttonProps.isLoading;
        return (
            <Button
                className="loading-button btn-lg"
                disabled={this.props.isLoading || this.props.disabled}
                {...rest}
                style={{"position":"relative","overflow":"hidden"}}
            >
                {this.props.isLoading ? <UpwardButtonLoader/> : ""}
                {this.props.children}
            </Button>
        )
    }
}

UpwardLoadingButton.propTypes = {
    isLoading: PropTypes.bool,
    disabled: PropTypes.bool
};

export default UpwardLoadingButton;