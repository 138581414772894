import React, { Component } from "react";
import { Col, Container } from "reactstrap";

import { ThemeContext } from "../../../employer/context/ThemeContext";

class Subscribe extends Component{


    render(){
        return(
            <section className={"upward_subscribe upward_subscribe_"+ this.context }>
                <Container>
                    <Col xs="12" className="text-center">
                        {/* <h3>Subscribe to our newsletter</h3>
                        <Form inline>
                            <FormGroup>
                                <Input type={type} name={name} id={id} placeholder={placeholder} />
                            </FormGroup>
                            {' '}
                            <button className="btn">Subscribe</button>
                        </Form> */}
                    </Col>
                </Container>
            </section>
        )
    }

}

Subscribe.contextType = ThemeContext;

Subscribe.defaultProps = {
    type : "email",
    name : "email",
    id : "email",
    placeholder : "Email address"

}

export default Subscribe;