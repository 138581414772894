/*

  Implementation by Taran Sahota
  Dated : 9/19/2019

  The button loader animation.
  Change the loading animation here 
  to change globally for all the Loader Buttons


  its five verticle bars increasing and descreasing in size.


*/

import React from "react";

class UpwardButtonLoader extends React.Component{

  render () {
    return (
        <div className="button-spinner">
            <div className="rect1"></div>
            <div className="rect2"></div>
            <div className="rect3"></div>
            <div className="rect4"></div>
            <div className="rect5"></div>
        </div>
    )

  }
}


export default UpwardButtonLoader;