import React from 'react';
import { ReactComponent as Abstract } from 'assets/img/vectors/Upward_Loader.svg';

const UpwardAdvancedLoader = (props) => {
   const height = props.height || 120;
   return (
      <>
         {
            (props && props.size) || height ? (
               <div style={{ height: height + 'px' }}>
                  <div className="d-flex justify-content-center align-items-center h-100">
                     <div
                        style={{ width: props.size + 'px', height: props.size + 'px' }}
                        className="position-relative"
                     >
                        <Abstract />
                     </div>
                  </div>
               </div>
            ) : (
               <Abstract />
            )
         }
      </>
   );
};

export default UpwardAdvancedLoader;
