import React, { useContext } from "react";
import { Row, Col, Container } from "reactstrap";
import { CSSTransition } from 'react-transition-group';
import useIsInViewport from "ui/hooks/inViewport";
import MatchedImg from "assets/img/homepage/homepage/homepage_2.jpg";
import { requestDomain } from "../../services/SeekerServices";

import { ThemeContext } from "../../../employer/context/ThemeContext";

const GetMatched = () => {

    const context = useContext(ThemeContext);
    const[inView1, getMatchedImg1] = useIsInViewport();


        return(
            <section className={"upward_get_matched upward_get_matched_"+ context }>
                <Container>
                    <Row className="align-items-center">
                        <Col xs="12" lg={{"size":5,"offset":1}}>
                            <div className="">
                            <CSSTransition in={inView1} timeout={200} classNames="more_details_img"><img ref={getMatchedImg1} src={MatchedImg} className="w-100 more_details_img" alt="Get Matched"></img></CSSTransition>
                            </div>
                        </Col>
                        <Col xs="12" lg={{"size":5,"offset":1}}>
                            <div className="text-left">
                                <h3>Employers want <br /> to find you. <br /> Get matched today.</h3>
                                <p>Yes, really! Employers are looking for people with your skills right now.</p>
                                <p className="mb-5">Employers will invite you to apply to jobs that they are hand picking for you. You decide if you want to accept the invitation to apply.</p>
                                <a href={`${requestDomain}/results/?pageNum=1&reg=y`} className={"btn btn-lg btn-rounded btn-light"}><b>Find your next job</b></a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        )

}

export default GetMatched;
