import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Modal } from 'reactstrap';
import queryString from "query-string";
import axios from "axios";
import '../../constants';

import PrivacyPolicy from './PrivacyPolicy';
import CalDoNotSell from './CalDoNotSell';
import TC from './TC';
import DMCA from './DMCA';
import Unsub from './Unsub';
import CalPrivacy from './CalPrivacy';
import NonUpwardContact from "../../../src/components/NonUpwardContact";

const SiteLanguage = ({ openCmpDrawer, className, history, location }) => {

    const [ showPrivacy , setShowPrivacy ] = useState( false );
    const [ showCalPersonal , setShowCalPersonal ] = useState( false );
    const [ showTC , setShowTC ] = useState( false );
    const [ showDMCA , setShowDMCA ] = useState( false );
    const [ showCalPriv , setShowCalPriv ] = useState( false );
    const [ showUnsub , setShowUnsub ] = useState( false );
    const [ domainVal , setDomainVal ] = useState( "" );
    const [ domainAddr , setDomainAddr ] = useState( "" );
    const [ siteName , setSiteName ] = useState("");
    const [ siteId , setSiteId ] = useState( "" );
    const [ showContact, setShowContact ] = useState(false);
    
    const qs = queryString.parse(location.search);

    useEffect(()=>{
        if(window.location.href.indexOf("?privacy-policy") > -1){
            setShowPrivacy(true);
        }else if(window.location.href.indexOf("?personal-information") > -1){
            setShowCalPersonal(true);
        }else if(window.location.href.indexOf("?terms-and-conditions") > -1){
            setShowTC(true);
        }else if(window.location.href.indexOf("?dmca") > -1){
            setShowDMCA(true);
        }else if(window.location.href.indexOf("?california-privacy-notice") > -1){
            setShowCalPriv(true);
        }else if(window.location.href.indexOf("?contact") > -1){
            setShowContact(true);
        }else{
            setShowUnsub(true);
        }
    },[qs]);  

    useEffect(() => {
        axios.get(global.API_URL + global.JOB_DIR + "/api/siteValues.jsp").then((response) => {
            setDomainVal(response.data.siteFull);
            setDomainAddr(response.data.siteAddress);
            setSiteId(response.data.siteId);
            setSiteName(response.data.siteName);
        }).catch(function (error) {
            // handle error
            console.log(error);
        })
    },[]);  

    const onCloseModal = () => {
        history.push(window.location.pathname);
    }

    return (
        <Modal size="lg" isOpen={true} className={(className ? className : "") + " rounded_modal signin-modal"} toggle={onCloseModal} backdrop="static" keyboard={true} >
            <button type="button" onClick={onCloseModal} className="close modalClose" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            {showPrivacy && <PrivacyPolicy domain={domainVal} address={domainAddr} openCmpDrawer={openCmpDrawer}/>}
            {showCalPersonal && <CalDoNotSell domain={domainVal} address={domainAddr} siteId={siteId}/>}
            {showTC && <TC domain={domainVal} address={domainAddr}/>}
            {showDMCA && <DMCA domain={domainVal} address={domainAddr}/>}
            {showUnsub && <Unsub domain={domainVal} address={domainAddr} siteId={siteId} qs={qs}/>}
            {showCalPriv && <CalPrivacy domain={domainVal} address={domainAddr}/>}
            {showContact && <NonUpwardContact onClose={onCloseModal} siteName={siteName} address={domainAddr}/>}
        </Modal>
    )

}


export default withRouter(SiteLanguage);
