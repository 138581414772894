export const SEEKER_REGISTER_UPDATE_JOB_DETAILS = '[Job Seeker Register] SEEKER_REGISTER_UPDATE_JOB_DETAILS';
export const SEEKER_REGISTER_UPDATE_USER_DETAILS = '[Job Seeker Register] SEEKER_REGISTER_UPDATE_USER_DETAILS';
export const SEEKER_REGISTER_UPDATE_USER_EMAIL = '[Job Seeker Register] SEEKER_REGISTER_UPDATE_USER_EMAIL';
export const SEEKER_REGISTER_UPDATE_USER_NAME = '[Job Seeker Register] SEEKER_REGISTER_UPDATE_USER_NAME';
export const SEEKER_REGISTER_UPDATE_USER_PHONE = '[Job Seeker Register] SEEKER_REGISTER_UPDATE_USER_PHONE';
export const SEEKER_REGISTER_UPDATE_USER_SEARCH = '[Job Seeker Register] SEEKER_REGISTER_UPDATE_USER_SEARCH';
export const SEEKER_REGISTER_UPDATE_USER_LOCATION = '[Job Seeker Register] SEEKER_REGISTER_UPDATE_USER_LOCATION';
export const SEEKER_REGISTER_UPDATE_USER_KEYWORD = '[Job Seeker Register] SEEKER_REGISTER_UPDATE_USER_KEYWORD';
export const SEEKER_REGISTER_EDIT_USER_LOCATION_FIELD = '[Job Seeker Register] SEEKER_REGISTER_EDIT_USER_LOCATION_FIELD';
export const SEEKER_REGISTER_UPDATE_DISPLAY_LOCATION = '[Job Seeker Register] SEEKER_REGISTER_UPDATE_DISPLAY_LOCATION';
export const SEEKER_REGISTER_UPDATE_KEYWORDS = '[Job Seeker Register] SEEKER_REGISTER_UPDATE_KEYWORDS'

export const SEEKER_REGISTER_SHOW_ALERT_OVERLAY = '[Job Seeker Register] SEEKER_REGISTER_SHOW_ALERT_OVERLAY';
export const SEEKER_REGISTER_HIDE_ALERT_OVERLAY = '[Job Seeker Register] SEEKER_REGISTER_HIDE_ALERT_OVERLAY';



export const SEEKER_REGISTER_SHOW_BOOKMARK_OVERLAY = '[Job Seeker Register] SEEKER_REGISTER_SHOW_BOOKMARK_OVERLAY';
export const SEEKER_REGISTER_HIDE_BOOKMARK_OVERLAY = '[Job Seeker Register] SEEKER_REGISTER_HIDE_BOOKMARK_OVERLAY';
