import React, { useState, useEffect } from "react";
import {
    Row, 
    Col,
    ModalHeader, 
    ModalBody,
    Button,
    Card,
    CardBody,
    Form,
    FormGroup,
    Input
} from "reactstrap";
import axios from "axios";
import '../../constants';

// Email validation and filtering function
const filterEmail = (email) => {
    if (!email) return '';
    return email.replace(/[^a-zA-Z0-9.@_+-]/g, '').slice(0, 255); // Only valid email characters
};

const Unsub = (props) => {
    const [emailVal, setEmailValue] = useState('');
    const [phoneVal, setPhoneValue] = useState('');
    const [showEmailSuccess, setShowEmailSuccess] = useState(false);
    const [showPhoneSuccess, setShowPhoneSuccess] = useState(false);
    const [showEmailError, setShowEmailError] = useState(false);
    const [showPhoneError, setShowPhoneError] = useState(false);

    // Define sanitizedDomain with a default value if props.sanitizedDomain is not provided
    const sanitizedDomain = props.sanitizedDomain || "example.com";

    useEffect(() => {
        if (props.qs && props.qs.email) {
            setEmailValue(filterEmail(props.qs.email));
        }
    }, [props.qs]);

    const handleEmailChange = (e) => {
        const filteredEmail = filterEmail(e.target.value);
        setEmailValue(filteredEmail);
    };

    const emailValidation = () => {
        const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;
        if (emailVal !== "" && !regex.test(emailVal)) {
            setShowEmailError(true);
            return false;
        }
        setShowEmailError(false);
        return true;
    };

    // Define phoneValidation function
    const phoneValidation = () => {
        const regex = /^\+?[\d\s()-]{7,15}$/; // Basic regex for phone validation
        if (phoneVal !== "" && !regex.test(phoneVal)) {
            setShowPhoneError(true);
            return false;
        }
        setShowPhoneError(false);
        return true;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const query = {
            siteId: encodeURIComponent(props.siteId),
            email: encodeURIComponent(filterEmail(emailVal)),
            phone: encodeURIComponent(phoneVal)
        };

        const queryString = Object.keys(query)
            .map(key => `${key}=${query[key]}`)
            .join('&');

        axios.all([
            axios.get(`${global.API_URL}${global.JOB_DIR}/catchUnsub.jsp?${queryString}`),
            axios.get(`${global.API_URL}/search/unsubscribe.fsn?${queryString}`)
        ])
        .then(() => {
            if (emailVal !== "" && emailValidation()) setShowEmailSuccess(true);
            if (phoneVal !== "" && phoneValidation()) setShowPhoneSuccess(true);
        })
        .catch(error => {
            console.error('Error in API request:', error);
        });
    };

    return (
        <React.Fragment>
            <ModalHeader style={{ paddingBottom: 0 }}>
                <h3 className="center">Unsubscribe</h3>
            </ModalHeader>
            <ModalBody>
                <div className="clearfix mainSection">
                    <div className="container">
                        <div className="clearfix contentWrapper">
                            <div className="mainContent" id="mainContentHolder">
                                <div className="row">
                                    <div className="col-md-12 col-lg-12">
                                        <p>
                                            By entering your email address, you are opting to unsubscribe from
                                            {sanitizedDomain}'s email lists. Adding your name to this list only
                                            removes you from emails {sanitizedDomain} deploys. Your e-mail will
                                            be compiled into a screening or suppression list along with all other
                                            collected e-mail opt-outs. By entering your e-mail, you will be
                                            unsubscribed from {sanitizedDomain} services and you authorize
                                            {sanitizedDomain} to provide this aggregate list to those third parties for
                                            the purpose of ensuring your e-mail is not utilized for e-mailing offers
                                            deployed by {sanitizedDomain}.
                                        </p>
                                        <p>
                                            By entering your phone number, you are requesting to be added to {sanitizedDomain}'s 
                                            internal Do Not Call list. Adding your name to this list only
                                            removes you from all calls which {sanitizedDomain} deploys. The {sanitizedDomain}
                                            Do Not Call list has no relationship or affiliation with the Federal Do
                                            Not Call list. By adding yourself to this list, you will also be included
                                            on a {sanitizedDomain} suppression list that is distributed to third-party
                                            affiliates for them to add your phone number to their own internal Do Not
                                            Call list ONLY.
                                        </p>
                                        <p>
                                            It may take up to ten (10) business days to successfully remove your name
                                            from our lists.
                                        </p>
                                        <p>
                                            Please note: {sanitizedDomain} is not responsible for any third parties who
                                            do not promote our products or services.
                                        </p>
                                        <Card>
                                            <CardBody>
                                                <Form onSubmit={handleSubmit}>
                                                    {showEmailSuccess && (
                                                        <Row>
                                                            <Col>
                                                                <p style={{ color: "rgb(0, 128, 0)" }}>
                                                                    Your email has been added to the suppression list.
                                                                </p>
                                                            </Col>
                                                        </Row>
                                                    )}
                                                    {showEmailError && (
                                                        <Row>
                                                            <Col>
                                                                <p style={{ color: "#FF0000" }}>Please enter a valid email address</p>
                                                            </Col>
                                                        </Row>
                                                    )}
                                                    <Row>
                                                        <Col xs={{ size: 8, offset: 2 }}>
                                                            <FormGroup>
                                                                <Input 
                                                                    onChange={e => setEmailValue(e.target.value)} 
                                                                    value={emailVal} 
                                                                    type="email" 
                                                                    name="email" 
                                                                    maxLength="255" 
                                                                    id="email" 
                                                                    placeholder="Email" 
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={{ size: 8, offset: 2 }}>
                                                            <br /><span><strong>-- And / Or --</strong></span><br /><br />
                                                        </Col>
                                                    </Row>
                                                    {showPhoneSuccess && (
                                                        <Row>
                                                            <Col>
                                                                <p style={{ color: "rgb(0, 128, 0)" }}>
                                                                    Your phone number has been added to the suppression list.
                                                                </p>
                                                            </Col>
                                                        </Row>
                                                    )}
                                                    {showPhoneError && (
                                                        <Row>
                                                            <Col>
                                                                <p style={{ color: "#FF0000" }}>Please enter a valid phone number</p>
                                                            </Col>
                                                        </Row>
                                                    )}
                                                    <Row>
                                                        <Col xs={{ size: 8, offset: 2 }}>
                                                            <FormGroup>
                                                                <Input 
                                                                    onChange={e => setPhoneValue(e.target.value)} 
                                                                    value={phoneVal}
                                                                    type="text" 
                                                                    name="phone" 
                                                                    maxLength="14" 
                                                                    id="phone" 
                                                                    placeholder="Phone Number" 
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={{ size: 8, offset: 2 }}>
                                                            <div className="form-actions">
                                                                <Button type="submit" id="consumerPortalSubmit" className="btn btn-lg btn-rounded btn-dark-blue">
                                                                    Submit
                                                                </Button>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </CardBody>
                                        </Card>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalBody>
        </React.Fragment>
    );
};

export default Unsub;
